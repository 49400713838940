import React from 'react';

import {
  Brightness7 as LightIcon,
  Brightness3 as DarkIcon,
} from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

import useStyles from './styles';
import useHooks from './hooks';

export default () => {
  const classes = useStyles();
  const {
    isDark,
    setIsDark,
  } = useHooks();

  return (
    <IconButton
      onClick={() => setIsDark(!isDark)}
      color="inherit"
      size="medium"
    >
      {
        isDark
          ? <DarkIcon className={classes.icon} />
          : <LightIcon className={classes.icon} />
      }
    </IconButton>
  );
};
