import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

import { currencyToFloat } from 'utils/masks';

import useRequest from 'utils/http';

import { useForm, FormContext } from 'react-hook-form';

import Yup from 'utils/yup';

import { useSnackbar } from 'notistack';

import {
  ListItem, ListItemText,
  Dialog, DialogTitle, DialogContent,
  DialogActions, Button,
} from '@material-ui/core';

import CurrencyTextField from 'components/common/form/currencyTextField';

const validationSchema = Yup.object().shape({
  saleValue: Yup.string()
    .label('Valor de Venda')
    .required()
    .test('', 'Valor de venda é um campo obrigatório para veículos em estoque ou vendidos', function validation(value) {
      const formattedValue = currencyToFloat(value);
      const { situation } = this.parent;
      return formattedValue > 0 || situation === 'inPreparation' || situation === 'returned';
    }),
});

export default (props) => {
  const {
    id, value, defaultValues,
  } = props;

  const request = useRequest();
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm({
    validationSchema,
    defaultValues,
    validateCriteriaMode: 'firstErrorDetected',
    mode: 'onBlur',
  });

  const { handleSubmit, register } = methods;

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSubmit = async (formData) => {
    try {
      setIsLoading(true);

      const formattedData = { ...formData };

      formattedData.saleValue = currencyToFloat(formattedData.saleValue);

      await request({
        method: 'patch',
        url: `vehicles/${id}`,
        data: formattedData,
      });

      enqueueSnackbar('Valor de venda salvo com sucesso', { variant: 'success' });
      history.push(`/operacional/veiculos/${id}`);
    } catch (e) {
      handleClose();
      setIsLoading(false);
      enqueueSnackbar('Erro ao salvar valor de venda', { variant: 'warning' });
    }
  };

  return (
    <>
      <ListItem
        button
        divider
        onClick={handleOpen}
      >
        <ListItemText>
          Valor de Venda
        </ListItemText>
        <ListItemText
          style={{
            textDecoration: 'line-through',
            textAlign: 'right',
          }}
        >
          {value}
        </ListItemText>
      </ListItem>
      <Dialog
        open={open}
        disableBackdropClick={isLoading}
        onClose={handleClose}
        fullWidth
        scroll="body"
        maxWidth="xs"
      >
        <FormContext {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>
              Alterar Valor de Venda
            </DialogTitle>
            <DialogContent>
              <CurrencyTextField
                autoFocus
                label="Valor de Venda"
                name="saleValue"
                disabled={isLoading}
              />
              <input type="hidden" name="situation" ref={register} />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                disabled={isLoading}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={isLoading}
              >
                Salvar
              </Button>
            </DialogActions>
          </form>
        </FormContext>
      </Dialog>
    </>
  );
};
