import React, {
  Fragment, useState, useEffect, memo,
} from 'react';
import dot from 'dot-object';
import { scrollTo as goScrollTo, Element } from 'components/common/scroll';
import {
  currencyToFloat, translateType, currency,
} from 'utils/masks';
import { getNow } from 'utils/date';
import { useForm, FormContext, useFieldArray } from 'react-hook-form';
import {
  makeStyles, Grid, Button, Divider,
  Hidden, Typography,
} from '@material-ui/core';
import {
  Money as MoneyIcon,
  AccountBalance as AccountBalanceIcon,
  EventNote as EventNoteIcon,
  DriveEta as DriveEtaIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import CurrencyTextField from 'components/common/form/currencyTextField';
import validationSchema from 'components/common/form/payments/validationSchema';
import ExchangeVehicle from './exchangeVehicle';
import FieldByType from './fieldByType';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  stepContainer: {
    padding: 0,
  },
  stepGrid: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  removeButton: {
    color: theme.palette.error.main,
  },
}));

const Payments = (props) => {
  const {
    vehicleId = 0, saleValue = 0, customer,
    onSubmit, children, defaultValues = {},
    isLoading, getTotal, containerId,
  } = props;

  const classes = useStyles();

  const [total, setTotal] = useState(0);
  const [scrollTo, setScrollTo] = useState(null);
  const [exchangeVehicleIndex, setExchangeVehicleIndex] = useState(-1);

  const methods = useForm({
    defaultValues,
    validationSchema,
    validateCriteriaMode: 'firstErrorDetected',
    mode: 'onBlur',
  });

  const {
    control, register, handleSubmit,
    reset, watch, setValue,
  } = methods;

  const values = dot.object({ ...watch() });

  const { fields } = useFieldArray({
    control,
    name: 'payments',
  });

  const append = (payment) => {
    const newValues = { ...values };
    if (!newValues.payments) {
      newValues.payments = [payment];
    } else {
      newValues.payments.push(payment);
    }
    reset(newValues);
    setScrollTo(newValues.payments.length);
  };

  const remove = (index) => {
    const newValues = { ...values };
    newValues.payments = newValues.payments.filter((payment, i) => (
      i !== index
    ));
    reset(newValues);
    setScrollTo(newValues.payments.length);
  };

  const setExchangeVehicle = (exchangeVehicle) => {
    setValue(
      `payments[${exchangeVehicle.index}].exchangeVehicle.id`,
      exchangeVehicle.id,
      true,
    );
    setValue(
      `payments[${exchangeVehicle.index}].exchangeVehicle.model`,
      exchangeVehicle.model,
      true,
    );
  };

  const updateTotal = () => {
    const { payments = [] } = { ...values };
    const newTotal = payments.reduce((accumulator, payment) => (
      accumulator + currencyToFloat(payment.value)
    ), 0);
    if (typeof getTotal === 'function' && newTotal !== total) {
      getTotal(newTotal);
    }
    setTotal(newTotal);
  };

  const handleScrollTo = () => {
    if (scrollTo !== null) {
      goScrollTo({
        className: `payments[${scrollTo}]`,
        timeout: 500,
        containerId,
      });
    }
  };

  useEffect(updateTotal, [values]);
  useEffect(handleScrollTo, [scrollTo]);

  const discount = total - saleValue;

  return (
    <>
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Element className="payments[0]">
                <Grid container spacing={2} justify="flex-end">
                  <Grid item md="auto" xs={12}>
                    <Button
                      fullWidth
                      onClick={() => append({
                        key: 0,
                        type: 'entry',
                        date: getNow('YYYY-MM-DD'),
                        value: 0,
                      })}
                      startIcon={<MoneyIcon />}
                      disabled={isLoading}
                    >
                      Entrada
                    </Button>
                  </Grid>
                  <Grid item md="auto" xs={12}>
                    <Button
                      fullWidth
                      onClick={() => append({
                        key: 0,
                        type: 'financing',
                        bank: '',
                        value: 0,
                      })}
                      startIcon={<AccountBalanceIcon />}
                      disabled={isLoading}
                    >
                      Financiamento
                    </Button>
                  </Grid>
                  <Grid item md="auto" xs={12}>
                    <Button
                      fullWidth
                      onClick={() => append({
                        key: 0,
                        type: 'accountReceivable',
                        date: getNow('YYYY-MM-DD'),
                        value: 0,
                      })}
                      startIcon={<EventNoteIcon />}
                      disabled={isLoading}
                    >
                      Conta a Receber
                    </Button>
                  </Grid>
                  <Grid item md="auto" xs={12}>
                    <Button
                      fullWidth
                      onClick={() => append({
                        key: 0,
                        type: 'exchangeVehicle',
                        exchangeVehicle: {
                          id: '',
                          model: '',
                        },
                        value: 0,
                      })}
                      startIcon={<DriveEtaIcon />}
                      disabled={isLoading}
                    >
                      Veículo de Troca
                    </Button>
                  </Grid>
                </Grid>
              </Element>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                justify="space-between"
                alignItems="center"
              >
                {fields.map((field, index) => (
                  <Fragment key={field.id}>
                    <Grid item xs={12}>
                      <Element className={`payments[${index + 1}]`}>
                        <Divider />
                      </Element>
                    </Grid>
                    <Grid item md="auto" xs={12}>
                      <Typography
                        variant="button"
                        align="center"
                        display="block"
                      >
                        {translateType(field.type)}
                      </Typography>
                    </Grid>
                    <Hidden smDown>
                      <Grid item md="auto" xs={12}>
                        <Button
                          onClick={() => remove(index)}
                          fullWidth
                          startIcon={<DeleteIcon />}
                          className={classes.removeButton}
                          disabled={isLoading}
                        >
                          Remover
                        </Button>
                      </Grid>
                    </Hidden>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                          <FieldByType
                            field={field}
                            index={index}
                            isLoading={isLoading}
                            values={values}
                            setExchangeVehicleIndex={setExchangeVehicleIndex}
                            register={register}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <CurrencyTextField
                            label="Valor"
                            defaultValue={field.value}
                            name={`payments[${index}].value`}
                            disabled={isLoading}
                          />
                          <input
                            ref={register}
                            type="hidden"
                            defaultValue={field.type}
                            name={`payments[${index}].type`}
                          />
                          <input
                            ref={register}
                            type="hidden"
                            defaultValue={field.key}
                            name={`payments[${index}].key`}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Hidden mdUp>
                      <Grid item md="auto" xs={12}>
                        <Button
                          onClick={() => remove(index)}
                          fullWidth
                          startIcon={<DeleteIcon />}
                          className={classes.removeButton}
                          disabled={isLoading}
                        >
                          Remover
                        </Button>
                      </Grid>
                    </Hidden>
                  </Fragment>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="button"
                align="right"
                display="block"
              >
                {`Total: ${currency(total || 0)}`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="button"
                align="right"
                display="block"
              >
                {`Valor de Venda: ${currency(saleValue || 0)}`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="button"
                align="right"
                display="block"
              >
                {`Desconto: ${currency(discount)}`}
              </Typography>
            </Grid>
            {children}
          </Grid>
        </form>
      </FormContext>
      <ExchangeVehicle
        index={exchangeVehicleIndex}
        setIndex={setExchangeVehicleIndex}
        setExchangeVehicle={setExchangeVehicle}
        values={values}
        customer={customer}
        vehicleId={vehicleId}
      />
    </>
  );
};

export default memo(Payments);
