import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  fabProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: -3,
    left: -3,
    zIndex: 1,
  },
  listItem: {
    paddingRight: 48 * 3,
  },
  listItemImage: {
    paddingRight: theme.spacing(2),
  },
}));
