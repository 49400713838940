import React from 'react';

import { useForm, FormContext } from 'react-hook-form';

import { privateFileUrl } from 'utils/http';

import {
  Grid, Typography, Fab,
  List, ListItem, ListItemText,
  ListItemSecondaryAction, IconButton,
  CircularProgress, Button,
  Dialog, DialogTitle, DialogContent, DialogActions,
} from '@material-ui/core';

import {
  Add as AddIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';

import TextField from 'components/common/form/textField';

import If from 'components/common/if';

import useHooks from './hooks';
import useStyles from './styles';
import validationSchema from './validationSchema';

export default (props) => {
  const {
    files,
    fileRef,
    openNameDialog,
    closeNameDialog,
    openRemoveDialog,
    closeRemoveDialog,
    addFile,
    removeFile,
    changeFile,
    sendFile,
    confirmRemoveFile,
    isLoading,
  } = useHooks(props);

  const methods = useForm({
    validationSchema,
    validateCriteriaMode: 'firstErrorDetected',
    mode: 'onBlur',
  });

  const { handleSubmit } = methods;

  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2} justify="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h5" component="h3">
            Anexos
          </Typography>
        </Grid>
        <Grid item>
          <div className={classes.wrapper}>
            <Fab
              size="medium"
              color="primary"
              onClick={addFile}
              disabled={isLoading}
            >
              <AddIcon />
            </Fab>
            <If test={isLoading}>
              <CircularProgress className={classes.fabProgress} size={52} />
            </If>
          </div>
        </Grid>
        <Grid item xs={12}>
          <If test={!isLoading && files.length}>
            <List>
              {files.map((file) => {
                const { id, name, url } = file;

                return (
                  <ListItem
                    key={id}
                    button
                    component="a"
                    href={privateFileUrl(url)}
                    target="_blank"
                    divider
                    disabled={isLoading}
                  >
                    <ListItemText primary={name} />
                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={() => confirmRemoveFile(id)}
                        disabled={isLoading}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </If>
          <If test={!isLoading && !files.length}>
            <Typography
              variant="body1"
              component="span"
            >
              Nenhum anexo encontrado.
            </Typography>
          </If>
          <input
            id="uploadImage"
            accept="image/png,image/jpg,image/jpeg,application/pdf"
            style={{ display: 'none' }}
            type="file"
            ref={fileRef}
            onChange={changeFile}
          />
        </Grid>
      </Grid>
      <Dialog
        open={openNameDialog}
        disableBackdropClick={isLoading}
        onClose={closeNameDialog}
        fullWidth
        scroll="body"
        maxWidth="xs"
      >
        <FormContext {...methods}>
          <form onSubmit={handleSubmit(sendFile)}>
            <DialogTitle>
              Defina o nome do anexo
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                label="Nome do Anexo"
                name="name"
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={closeNameDialog}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                color="primary"
              >
                Salvar
              </Button>
            </DialogActions>
          </form>
        </FormContext>
      </Dialog>
      <Dialog
        open={openRemoveDialog}
        disableBackdropClick={isLoading}
        onClose={closeRemoveDialog}
        fullWidth
        scroll="body"
        maxWidth="xs"
      >
        <DialogTitle>
          Deseja remover o anexo?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={closeRemoveDialog}
          >
            Cancelar
          </Button>
          <Button
            onClick={removeFile}
            color="primary"
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
