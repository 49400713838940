import moment from 'moment';

import { onlyNumbers, onlyAlphanumeric } from 'utils/masks';

import { cpf as cpfValidation, cnpj as cnpjValidation } from 'utils/validations';

import * as Yup from 'yup';

function cpfCnpj(args = {}) {
  const defaultMessage = 'CPF/CNPJ inválido';
  const { message } = args;
  return this.test('cpfCnpj', message || defaultMessage, function validation(value = '') {
    const { createError } = this;
    const formattedValue = onlyNumbers(value);

    const isValid = (formattedValue.length > 11)
      ? cnpjValidation(formattedValue) : cpfValidation(formattedValue);

    return value.length === 0
      || isValid
      || createError();
  });
}

function telephone(args = {}) {
  const defaultMessage = 'Número de telefone inválido';
  const { message } = args;
  return this.test('cpfCnpj', message || defaultMessage, function validation(value = '') {
    const { createError } = this;
    const formattedValue = onlyNumbers(value);
    return value.length === 0
      || formattedValue.length === 10
      || formattedValue.length === 11
      || createError();
  });
}

function date(args = {}) {
  const defaultMessage = 'Data inválida';
  const { message } = args;
  return this.test('cpfCnpj', message || defaultMessage, function validation(value = '') {
    const { createError } = this;
    const validFormat = /([0-9]{2}\/[0-9]{2}\/[0-9]{4}|[0-9]{4}-[0-9]{2}-[0-9]{2})/.test(value);
    if (value.length === 0) {
      return true;
    }

    if (!validFormat) {
      return createError();
    }

    return moment(value, 'YYYY-MM-DD').isValid()
      || moment(value, 'DD/MM/YYYY').isValid()
      || createError();
  });
}

function plate(args = {}) {
  const defaultMessage = 'Placa inválida';
  const { message } = args;
  return this.test('plate', message || defaultMessage, function validation(value = '') {
    const { createError } = this;
    const formattedValue = onlyAlphanumeric(value);
    return value.length === 0
      || formattedValue.length === 7
      || createError();
  });
}

function chassi(args = {}) {
  const defaultMessage = 'Chassi inválido';
  const { message } = args;
  return this.test('chassi', message || defaultMessage, function validation(value = '') {
    const { createError } = this;
    const formattedValue = onlyAlphanumeric(value);
    return value.length === 0
      || formattedValue.length === 17
      || createError();
  });
}

function renavam(args = {}) {
  const defaultMessage = 'Renavam inválido';
  const { message } = args;
  return this.test('renavam', message || defaultMessage, function validation(value = '') {
    const { createError } = this;
    const formattedValue = onlyNumbers(value);
    return value.length === 0
      || (formattedValue.length >= 9 && formattedValue.length <= 11)
      || createError();
  });
}

function min(minLegth) {
  return this.test('min', '', function validation(value = '') {
    const { createError, schema, path } = this;
    const { _label: label } = schema;
    return value.length === 0
      || value.length >= minLegth
      || createError({
        path,
        message: `${label} deve conter no mínimo ${minLegth} caracteres`,
      });
  });
}

function max(maxLegth) {
  return this.test('max', '', function validation(value = '') {
    const { createError, schema, path } = this;
    const { _label: label } = schema;
    return value.length === 0
      || value.length <= maxLegth
      || createError({
        path,
        message: `${label} deve conter no máximo ${maxLegth} caracteres`,
      });
  });
}
Yup.addMethod(Yup.string, 'cpfCnpj', cpfCnpj);
Yup.addMethod(Yup.string, 'telephone', telephone);
Yup.addMethod(Yup.string, 'date', date);
Yup.addMethod(Yup.string, 'plate', plate);
Yup.addMethod(Yup.string, 'renavam', renavam);
Yup.addMethod(Yup.string, 'chassi', chassi);
Yup.addMethod(Yup.string, 'min', min);
Yup.addMethod(Yup.string, 'max', max);

export default Yup;
