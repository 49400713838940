import theme from 'main/theme';

import {
  Settings as SettingsIcon,
  DriveEta as DriveEtaIcon,
  KeyboardReturn as KeyboardReturnIcon,
  Receipt as ReceiptIcon,
  KeyboardTab as KeyboardTabIcon,
} from '@material-ui/icons';

export default (situation) => {
  let label;
  let style;
  let Icon;

  switch (situation) {
    case 'inPreparation':
      label = 'Em preparação';
      Icon = SettingsIcon;
      style = {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      };
      break;
    case 'inStock':
      label = 'Em estoque';
      Icon = DriveEtaIcon;
      style = {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,
      };
      break;
    case 'pendingWarranty':
      label = 'Garantia pendente';
      Icon = ReceiptIcon;
      style = {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.info.contrastText,
      };
      break;
    case 'returned':
      label = 'Retorno';
      Icon = KeyboardReturnIcon;
      style = {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
      };
      break;
    default:
      label = 'Vendido';
      Icon = KeyboardTabIcon;
      style = null;
      break;
  }

  return {
    label,
    Icon,
    style,
  };
};
