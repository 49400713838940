import { useState, useEffect } from 'react';

import useRequest from 'utils/http';

import { useForm } from 'react-hook-form';

import { useHistory, useRouteMatch } from 'react-router-dom';

import { useSnackbar } from 'notistack';

export default () => {
  const history = useHistory();
  const match = useRouteMatch();

  const formMethods = useForm();

  const { watch, setValue } = formMethods;

  const request = useRequest();

  const { enqueueSnackbar } = useSnackbar();

  const [vehicle, setVehicle] = useState({});
  const [defaultImages, setDefaultImages] = useState([]);
  const [images, setImages] = useState([]);
  const [openPreview, setOpenPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = match.params;

  const vehiclePreview = {
    ...vehicle,
    images,
    description: watch('description'),
  };

  const data = {
    vehicle,
    isLoading,
    defaultImages,
    images,
    vehiclePreview,
    openPreview,
  };

  const methods = {
    formMethods,
    setImages,
    setDefaultImages,
    setOpenPreview,
    onSubmit: async ({ description }) => {
      setIsLoading(true);
      try {
        await request({
          method: 'patch',
          url: `vehicles/${id}`,
          data: { description },
        });

        enqueueSnackbar('Descrição salva com sucesso', { variant: 'success' });
      } catch (e) {
        enqueueSnackbar('Erro ao salvar descrição', { variant: 'warning' });
      }

      try {
        const toEdit = [];
        const toRemove = [];
        const imagesMap = new Map();

        [...defaultImages, ...images]
          .reduce((acc, image) => {
            if (!imagesMap.has(image.id)) {
              imagesMap.set(image.id, image);
              acc.push(image);
            }
            return acc;
          }, [])
          .forEach((image) => {
            const foundImage = images.find((imagesItem) => image.id === imagesItem.id);

            if (foundImage) {
              toEdit.push(foundImage);
            } else {
              toRemove.push(image.id);
            }
          });

        await request({
          method: 'patch',
          url: 'vehicles/images',
          data: { toEdit, toRemove },
        });

        setDefaultImages(toEdit);

        enqueueSnackbar('Imagens salvas com sucesso', { variant: 'success' });
      } catch (e) {
        enqueueSnackbar('Erro ao salvar imagens', { variant: 'warning' });
      }

      setIsLoading(false);
    },
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);

        const { data: responseData } = await request({
          mehod: 'get',
          url: `vehicles/${id}`,
        });

        if (
          responseData.situation !== 'inPreparation'
          && responseData.situation !== 'inStock'
        ) {
          throw Error('INVALID SITUATION');
        }

        setIsLoading(false);
        setVehicle(responseData);
        setValue('description', responseData.description);
      } catch (e) {
        enqueueSnackbar('Veículo não encontrado', { variant: 'warning' });
        history.push('/marketing/veiculos');
      }
    };

    getData();
  }, [history, enqueueSnackbar, request, id, setValue]);

  return { data, methods };
};
