import React from 'react';

import { currency } from 'utils/masks';

import fuels from 'utils/selectOptions/fuel';

import {
  Grid, Button,
  Dialog, DialogTitle, DialogContent,
  List, ListItem, ListItemText, Typography,
} from '@material-ui/core';

import { Link } from 'react-router-dom';

import ImageStepper from 'components/common/imageStepper';

import useHooks from './hooks';

export default (props) => {
  const { data, methods } = useHooks(props);

  return (
    <Dialog
      open={data.open}
      onClose={methods.handleClose}
      fullWidth
      maxWidth="sm"
      scroll="body"
    >
      <DialogTitle>
        {data.vehicle.model}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ImageStepper
                  images={data.vehicle.images}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  align="right"
                >
                  {currency(data.vehicle.saleValue)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={`/comercial/ponto-de-venda/${data.vehicle.id}`}
                  fullWidth
                >
                  Vender Veículo
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  component={Link}
                  to={`/operacional/veiculos/${data.vehicle.id}`}
                  fullWidth
                >
                  Mais Informações
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <List dense>
              <ListItem divider>
                <ListItemText
                  secondary={data.vehicle.brand}
                >
                  Marca
                </ListItemText>
              </ListItem>
              <ListItem divider>
                <ListItemText
                  secondary={data.vehicle.plate}
                >
                  Placa
                </ListItemText>
              </ListItem>
              <ListItem divider>
                <ListItemText
                  secondary={data.vehicle.manufactureYear}
                >
                  Ano de Fabricação
                </ListItemText>
              </ListItem>
              <ListItem divider>
                <ListItemText
                  secondary={data.vehicle.releaseYear}
                >
                  Ano do Modelo
                </ListItemText>
              </ListItem>
              <ListItem divider>
                <ListItemText
                  secondary={fuels[data.vehicle.fuel]}
                >
                  Combustível
                </ListItemText>
              </ListItem>
              <ListItem divider>
                <ListItemText
                  secondary={data.vehicle.color}
                >
                  Cor
                </ListItemText>
              </ListItem>
              <ListItem divider>
                <ListItemText
                  secondary={data.vehicle.optionals}
                >
                  Opcionais
                </ListItemText>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{ whiteSpace: 'pre-line' }}
            >
              {data.vehicle.description}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
