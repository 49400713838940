import React from 'react';

import { convertDate } from 'utils/date';

import { useHistory } from 'react-router-dom';

import {
  ListItem, Typography, Grid,
} from '@material-ui/core';

import Table from 'components/common/table';

import TextField from 'components/common/form/textField';
import Checkbox from 'components/common/form/checkBox';

import VehicleSituationChip from 'components/common/vehicleSituation/chip';
import PossessionDaysChip from 'components/common/possessionDaysChip';

import SituationsCards from './situationsCards';

const defaultValues = {
  inPreparation: true,
  inStock: true,
  pendingWarranty: true,
  sold: false,
  returned: false,
};

const beforeSubmit = (formData) => {
  const situation = [];

  if (formData.inPreparation) {
    situation.push('inPreparation');
  }

  if (formData.inStock) {
    situation.push('inStock');
  }

  if (formData.pendingWarranty) {
    situation.push('pendingWarranty');
  }

  if (formData.sold) {
    situation.push('sold');
  }

  if (formData.returned) {
    situation.push('returned');
  }

  const formattedData = {
    model: formData.model,
    plate: formData.plate,
    releaseYear: formData.releaseYear,
    situation,
  };

  return formattedData;
};

export default () => {
  const history = useHistory();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <SituationsCards />
      </Grid>
      <Grid item xs={12}>
        <Table
          columns={[
            {
              title: 'Situação',
              field: 'situation',
              render: ({ situation }) => <VehicleSituationChip situation={situation} />,
            },
            {
              title: 'Data de Entrada',
              field: 'entryDate',
              defaultSort: 'desc',
              type: 'datetime',
              render: ({ entryDate }) => convertDate(entryDate, 'YYYY-MM-DD', 'DD/MM/YYYY', '-'),
            },
            { title: 'Placa', field: 'plate' },
            { title: 'Modelo', field: 'model' },
            { title: 'Ano do Modelo', field: 'releaseYear', type: 'numeric' },
            {
              title: 'Em posse',
              field: 'possessionDays',
              sorting: false,
              render: (rowData) => <PossessionDaysChip months={rowData.possessionDays} />,
            },
            {
              title: 'Data da Venda',
              field: 'dateOfSale',
              type: 'datetime',
              render: ({ dateOfSale }) => convertDate(dateOfSale, 'YYYY-MM-DD', 'DD/MM/YYYY', '-'),
            },
          ]}
          url="vehicles"
          onRowClick={(event, rowData) => history.push(`/operacional/veiculos/${rowData.id}`)}
          inputName="model"
          inputPlaceholder="Modelo"
          defaultValues={defaultValues}
          beforeSubmit={beforeSubmit}
        >
          <ListItem>
            <TextField
              name="plate"
              label="Placa"
            />
          </ListItem>
          <ListItem>
            <TextField
              name="releaseYear"
              label="Ano do Modelo"
              mask="9999"
            />
          </ListItem>
          <ListItem>
            <Typography variant="subtitle1" component="div">
              Situação
            </Typography>
          </ListItem>
          <ListItem>
            <Checkbox
              name="inPreparation"
              label="Em preparação"
            />
          </ListItem>
          <ListItem>
            <Checkbox
              name="inStock"
              label="Em estoque"
            />
          </ListItem>
          <ListItem>
            <Checkbox
              name="pendingWarranty"
              label="Garantia Pendente"
            />
          </ListItem>
          <ListItem>
            <Checkbox
              name="sold"
              label="Vendido"
            />
          </ListItem>
          <ListItem>
            <Checkbox
              name="returned"
              label="Retorno"
            />
          </ListItem>
        </Table>
      </Grid>
    </Grid>
  );
};
