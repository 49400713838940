// import Sms from 'components/pages/marketing/sms';
import Vehicle from 'components/pages/marketing/vehicles';
import EditVehicle from 'components/pages/marketing/vehicles/edit';

export default [
  {
    title: 'Marketing',
    path: '/marketing',
    type: 'private',
    redirect: '/marketing/veiculos',
    exact: true,
  },
  // {
  //   title: 'SMS',
  //   path: '/marketing/sms',
  //   type: 'private',
  //   component: Sms,
  //   exact: true,
  // },
  {
    title: 'Veículos',
    path: '/marketing/veiculos',
    type: 'private',
    component: Vehicle,
    exact: true,
  },
  {
    title: 'Editar Veículo',
    path: '/marketing/veiculos/:id',
    type: 'private',
    component: EditVehicle,
    exact: true,
  },
];
