import React from 'react';

import { Grid } from '@material-ui/core';

import Checkbox from 'components/common/form/checkBox';

import If from 'components/common/if';

export default (props) => {
  const { isLoading, sale, purchase } = props;

  const all = !sale && !purchase;

  return (
    <>
      <If test={purchase || all}>
        <Grid item md={4} xs={12}>
          <Checkbox
            name="checklist.requiredDut"
            label="DUT Obrigatório"
            disabled={isLoading}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Checkbox
            name="checklist.transferDut"
            label="DUT Transferência"
            disabled={isLoading}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Checkbox
            name="checklist.ipva"
            label="IPVA"
            disabled={isLoading}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Checkbox
            name="checklist.licensing"
            label="Licenciamento"
            disabled={isLoading}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Checkbox
            name="checklist.copyKey"
            label="Cópia de Chave"
            disabled={isLoading}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Checkbox
            name="checklist.manual"
            label="Manual"
            disabled={isLoading}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Checkbox
            name="checklist.entryNf"
            label="NF Entrada"
            disabled={isLoading}
          />
        </Grid>
      </If>
      <If test={sale || all}>
        <Grid item md={4} xs={12}>
          <Checkbox
            name="checklist.fireExtinguisher"
            label="Extintor de Incêndio"
            disabled={isLoading}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Checkbox
            name="checklist.monkey"
            label="Macaco"
            disabled={isLoading}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Checkbox
            name="checklist.tireIron"
            label="Chave de Roda"
            disabled={isLoading}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Checkbox
            name="checklist.triangle"
            label="Triângulo"
            disabled={isLoading}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Checkbox
            name="checklist.tires"
            label="Pneus de Rodagem (Bom Regular)"
            disabled={isLoading}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Checkbox
            name="checklist.reserveTire"
            label="Pneu Reserva"
            disabled={isLoading}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Checkbox
            name="checklist.airConditioning"
            label="Funcionamento do Ar-condicionado"
            disabled={isLoading}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Checkbox
            name="checklist.electricalCommands"
            label="Funcionamento dos Comandos Elétricos (Vidros, Travas, Faróis, Lanternas, Alarmes, Etc)."
            disabled={isLoading}
          />
        </Grid>
      </If>
    </>
  );
};
