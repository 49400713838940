import React, { useState } from 'react';

import { useDispatch } from 'react-redux';

import { useForm, FormContext } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import { login } from 'main/actions/user';

// import { Link as RouterLink } from 'react-router-dom';

import {
  makeStyles, Button, Grid,
  LinearProgress,
  // Link,
} from '@material-ui/core';

import TextField from 'components/common/form/textField';

import If from 'components/common/if';

import HomeScreen from 'components/common/homeScreen';

import validationSchema from './validationSchema';


const useStyles = makeStyles(() => ({
  gridButton: {
    height: '40px',
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm({
    defaultValues: {},
    validationSchema,
    validateCriteriaMode: 'firstErrorDetected',
    mode: 'onBlur',
  });

  const { handleSubmit } = methods;

  const onSubmit = async (formData) => {
    setIsLoading(true);
    try {
      const { user, password } = formData;
      await dispatch(login(user, password));
      // setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar('Usuário ou senha inválido', { variant: 'warning' });
    }
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <HomeScreen title="Login">
          <Grid item xs={12}>
            <TextField
              label="Usuário ou Email"
              name="user"
              disabled={isLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Senha"
              name="password"
              type="password"
              disabled={isLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              alignItems="center"
              className={classes.gridButton}
            >
              <If test={!isLoading}>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={isLoading}
                  >
                    Acessar
                  </Button>
                </Grid>
              </If>
              <If test={isLoading}>
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              </If>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Link to="/recuperar-senha" component={RouterLink} variant="body2">
              Recuperar Senha
            </Link>
          </Grid> */}
        </HomeScreen>
      </form>
    </FormContext>
  );
};
