import React, { useState } from 'react';

import {
  makeStyles, Paper, InputBase,
  Divider, IconButton, Fab,
} from '@material-ui/core';

import {
  FilterList as FilterListIcon,
  Search as SearchIcon,
  Add as AddIcon,
} from '@material-ui/icons';

import { Link } from 'react-router-dom';

import { useForm, FormContext } from 'react-hook-form';

import If from 'components/common/if';


import SearchDrawer from './drawer';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  actions: {
    marginLeft: theme.spacing(2),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  fab: {
    position: 'absolute',
    marginTop: -52,
    right: theme.spacing(6),
  },
  addButton: {
    paddingTop: 11,
    paddingBottom: 11,
  },
}));

export default (props) => {
  const {
    onSubmit: onSubmitProps, children,
    defaultValues, validationSchema,
    inputName = 'search', addTo,
    inputPlaceholder = 'Buscar',
  } = props;

  const methods = useForm({
    validationSchema,
    defaultValues,
    validateCriteriaMode: 'firstErrorDetected',
    mode: 'onBlur',
  });

  const {
    handleSubmit, register, reset,
  } = methods;

  const classes = useStyles();

  const [data, setData] = useState(defaultValues);
  const [hasSearchDrawerOpen, setHasSearchDrawerOpen] = useState(false);

  const toggleSearchDrawer = () => {
    setHasSearchDrawerOpen(!hasSearchDrawerOpen);
  };

  const onSubmit = (formData) => {
    setData({ ...data, ...formData });
    onSubmitProps({ ...data, ...formData });
  };

  return (
    <>
      <FormContext {...methods}>
        <If test={addTo}>
          <Fab
            color="primary"
            className={classes.fab}
            size="medium"
            to={addTo}
            component={Link}
          >
            <AddIcon />
          </Fab>
        </If>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Paper component="div" className={classes.root}>
            <InputBase
              name={inputName}
              placeholder={inputPlaceholder}
              className={classes.input}
              inputRef={register}
            />
            <IconButton
              className={classes.iconButton}
              type="submit"
            >
              <SearchIcon />
            </IconButton>
            <Divider className={classes.divider} orientation="vertical" />
            <IconButton
              className={classes.iconButton}
              onClick={toggleSearchDrawer}
            >
              <FilterListIcon />
            </IconButton>
          </Paper>
        </form>
      </FormContext>
      <SearchDrawer
        open={hasSearchDrawerOpen}
        onClose={toggleSearchDrawer}
        data={data}
        defaultValues={defaultValues}
        onSubmit={onSubmitProps}
        setData={setData}
        reset={reset}
      >
        {children}
      </SearchDrawer>
    </>
  );
};
