import axios from 'axios';

import CONFIG from 'config/config';

const { localStorage } = window;

export function getToken() {
  return localStorage.getItem('token');
}

function setToken(token) {
  localStorage.setItem('token', token);
}

function removeToken() {
  localStorage.removeItem('token');
}

export function login(user, password) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${CONFIG.API_URL}login`,
        { user, password },
      );

      const {
        id,
        name,
        username,
        isAdmin,
        image,
      } = response.data.user;
      const { token } = response.data;

      const newUser = {
        id,
        name,
        username,
        isAdmin,
        image,
        token,
      };

      setToken(token);
      dispatch({ type: 'LOGIN', payload: newUser });
    } catch (e) {
      removeToken();
      throw e;
    }
  };
}

export function setUser(user) {
  return { type: 'SET_USER', payload: user };
}

export function logout() {
  localStorage.removeItem('token');
  return { type: 'LOGOUT' };
}

export function changeNotifications() {
  return async (dispatch) => {
    try {
      const { data } = await axios({
        method: 'get',
        url: `${CONFIG.API_URL}notifications`,
        params: {
          limit: 20,
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });

      dispatch({ type: 'CHANGE_NOTIFICATIONS', payload: data.data });
    } catch (e) {
      dispatch({ type: null });
    }
  };
}

export function removeNotification(id) {
  return async (dispatch) => {
    try {
      await axios({
        method: 'delete',
        url: `${CONFIG.API_URL}notifications/${id}`,
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
    } finally {
      dispatch({ type: 'DELETE_NOTIFICATIONS', payload: id });
    }
  };
}
