import React, { useEffect } from 'react';

import { useForm, FormContext } from 'react-hook-form';

import { Grid, Button } from '@material-ui/core';

import CustomerForm from 'components/common/form/customer';

import validationSchema from 'components/common/form/customer/validationSchema/buyer';

export default (props) => {
  const {
    setActiveStep, data, setData,
    isLoading, setIsLoading,
    classes, resumeData, setResumeData,
  } = props;

  const methods = useForm({
    validationSchema,
    defaultValues: data,
    validateCriteriaMode: 'firstErrorDetected',
    mode: 'onBlur',
  });

  const { handleSubmit, watch } = methods;

  const onSubmit = (formData) => {
    setData({ ...data, customer: formData.customer });
    setActiveStep(1);
  };

  const buyer = watch('customer.name');

  const updateBuyer = () => {
    setResumeData({ ...resumeData, buyer });
  };

  useEffect(updateBuyer, [buyer]);

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <CustomerForm
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            buyer
          />
          <Grid item xs={12}>
            <Button
              className={classes.button}
              disabled
            >
              Voltar
            </Button>
            <Button
              disabled={isLoading}
              variant="contained"
              color="primary"
              className={classes.button}
              type="submit"
            >
              Avançar
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormContext>
  );
};
