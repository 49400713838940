import {
  Home as HomeIcon,
  DriveEta as DriveEtaIcon,
  AssignmentInd as AssignmentIndIcon,
  Store as StoreIcon,
  Flag as FlagIcon,
} from '@material-ui/icons';

export default [
  {
    text: 'Início',
    icon: HomeIcon,
    exact: true,
    link: '/',
  },
  {
    text: 'Administrativo',
    icon: AssignmentIndIcon,
    link: '/administrativo',
    admin: true,
    items: [
      { text: 'Usuários', link: '/administrativo/usuarios' },
    ],
  },
  {
    text: 'Comercial',
    icon: DriveEtaIcon,
    link: '/comercial',
    items: [
      { text: 'Ponto de Venda', link: '/comercial/ponto-de-venda' },
    ],
  },
  {
    text: 'Marketing',
    icon: FlagIcon,
    link: '/marketing',
    items: [
      // { text: 'SMS', link: '/marketing/sms' },
      { text: 'Veículos', link: '/marketing/veiculos' },
    ],
  },
  {
    text: 'Operacional',
    icon: StoreIcon,
    link: '/operacional',
    items: [
      { text: 'Clientes', link: '/operacional/clientes' },
      { text: 'Compra', link: '/operacional/compra' },
      { text: 'Veículos', link: '/operacional/veiculos' },
    ],
  },
];
