import React from 'react';

import CONFIG from 'config/config';

import {
  makeStyles, Grid, Typography,
  Hidden,
} from '@material-ui/core';

import Logo from 'assets/img/logo.png';

const useStyles = makeStyles((theme) => ({
  gridMainContainer: {
    height: '100vh',
  },
  gridLogoContainer: {
    height: '100vh',
    backgroundColor: theme.palette.secondary.main,
  },
  gridLogoItem: {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
  },
  logo: {
    width: '65%',
    maxWidth: 300,
  },
  gridLeftContainer: {
    height: '100%',
    padding: theme.spacing(4),
  },
  gridLeftChildrenContainer: {
    maxWidth: 400,
  },
  gridButton: {
    height: '40px',
  },
}));

export default (props) => {
  const { children, title } = props;

  const classes = useStyles();

  return (
    <Grid
      container
      alignItems="stretch"
      justify="space-between"
      className={classes.gridMainContainer}
    >
      <Hidden smUp>
        <Grid item xs={12} className={classes.gridLogoItem}>
          <img src={Logo} alt="Logomarca Via Nova" className={classes.logo} />
        </Grid>
      </Hidden>
      <Grid item sm={6} xs={12}>
        <Grid
          container
          alignItems="center"
          justify="center"
          className={classes.gridLeftContainer}
        >
          <Grid item>
            <Grid
              container
              spacing={2}
              className={classes.gridLeftChildrenContainer}
            >
              <Grid item xs={12}>
                <Typography align="center" variant="h5" component="h1">
                  {title}
                </Typography>
              </Grid>
              {children}
              <Grid item xs={12}>
                <Typography align="center" variant="body2">
                  {`V${CONFIG.VERSION}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Hidden xsDown>
        <Grid item sm={6} xs={12}>
          <Grid
            container
            alignItems="center"
            justify="center"
            className={classes.gridLogoContainer}
          >
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <img src={Logo} alt="Logomarca Via Nova" className={classes.logo} />
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </Grid>
  );
};
