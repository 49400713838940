import Pos from 'components/pages/commercial/pos';
import PosAdd from 'components/pages/commercial/pos/add';

export default [
  {
    title: 'Comercial',
    path: '/comercial',
    type: 'private',
    redirect: '/comercial/ponto-de-venda',
    exact: true,
  },
  {
    title: 'Ponto de Venda',
    path: '/comercial/ponto-de-venda',
    type: 'private',
    component: Pos,
    exact: true,
  },
  {
    title: 'Adicionar Venda',
    path: '/comercial/ponto-de-venda/:vehicle',
    type: 'private',
    component: PosAdd,
    exact: true,
  },
];
