import React from 'react';

import {
  makeStyles, Drawer, Button,
  List, ListItem,
} from '@material-ui/core';

import { useForm, FormContext } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: theme.drawerWidth,
  },
}));

let values = {};

const SearchDrawer = (props) => {
  const {
    data, setData,
    children, validationSchema,
    onSubmit: onSubmitProps, onClose,
    reset: resetParent,
    defaultValues = {},
  } = props;

  const methods = useForm({
    validationSchema,
    defaultValues: data,
    validateCriteriaMode: 'firstErrorDetected',
    mode: 'onBlur',
  });

  const {
    handleSubmit, getValues, setValue,
  } = methods;

  const onSubmit = (formData) => {
    values = formData;
    setData({ ...data, ...values });
    onSubmitProps({ ...data, ...values });
    onClose();
  };

  const onChange = () => {
    values = getValues();
  };

  const onClear = () => {
    values = defaultValues;
    setData(defaultValues);
    Object.keys(getValues()).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(defaultValues, key)) {
        const value = defaultValues[key];
        setValue(key, value);
      } else {
        setValue(key, '');
      }
    });
    resetParent();
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} onChange={onChange}>
        <List>
          {children}
          <ListItem>
            <Button
              type="submit"
              color="primary"
              fullWidth
              variant="contained"
            >
              Buscar
            </Button>
          </ListItem>
          <ListItem>
            <Button
              type="button"
              fullWidth
              onClick={onClear}
            >
              Limpar
            </Button>
          </ListItem>
        </List>
      </form>
    </FormContext>
  );
};

export default (props) => {
  const {
    open, onClose: onCloseProps,
    data, setData,
  } = props;

  const classes = useStyles();

  const onClose = () => {
    setData({ ...data, ...values });
    onCloseProps();
  };

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawerPaper }}
      open={open}
      onClose={onClose}
    >
      <SearchDrawer {...props} />
    </Drawer>
  );
};
