import React, { useState, useEffect, memo } from 'react';

import { useHistory } from 'react-router-dom';

import useRequest from 'utils/http';

import { convertDate } from 'utils/date';

import {
  Grid, Typography,
  List, ListItem, ListItemText,
  LinearProgress,
} from '@material-ui/core';

import If from 'components/common/if';

const RenderList = (props) => {
  const {
    vehicles, isLoading,
    handleClick, type,
  } = props;

  return (
    <Grid item xs={12}>
      <List disablePadding>
        <If test={isLoading}>
          <LinearProgress />
        </If>
        <If test={!isLoading && !vehicles.length}>
          <Typography
            variant="body1"
            component="span"
          >
            Nenhum registro encontrado.
          </Typography>
        </If>
        <If test={!isLoading && vehicles.length}>
          {vehicles.map((vehicle) => {
            const {
              id, entryDate, dateOfSale,
              model, releaseYear,
            } = vehicle;

            const date = (type === 'purchases')
              ? dateOfSale
              : entryDate;

            const formattedDate = convertDate(date, 'YYYY-MM-DD', 'DD/MM/YYYY', 'Garantia Pendente');

            return (
              <ListItem
                divider
                button
                key={id}
                onClick={() => handleClick(id)}
              >
                <ListItemText>
                  {formattedDate}
                </ListItemText>
                <ListItemText
                  style={{ textAlign: 'right' }}
                  secondary={releaseYear}
                >
                  {`${model}`}
                </ListItemText>
              </ListItem>
            );
          })}
        </If>
      </List>
    </Grid>
  );
};

const Historic = (props) => {
  const { id } = props;

  const request = useRequest();
  const history = useHistory();

  const [isLoadingPurchases, setIsLoadingPurchases] = useState(true);
  const [purchases, setPurchases] = useState([]);

  const [isLoadingSales, setIsLoadingSales] = useState(true);
  const [sales, setSales] = useState([]);

  const handleClick = (vehicleId) => history.push(`/operacional/veiculos/${vehicleId}`);

  useEffect(() => {
    const getCustomerOperations = async (type, changeFunction, changeLoading) => {
      changeLoading(true);
      const { data } = await request({
        method: 'get',
        url: 'vehicles',
        params: {
          [type]: id,
          sort: 'entryDate',
          order: 'desc',
        },
      });
      changeFunction(data.data);
      changeLoading(false);
    };

    getCustomerOperations(
      'buyer',
      setPurchases,
      setIsLoadingPurchases,
    );

    getCustomerOperations(
      'salesman',
      setSales,
      setIsLoadingSales,
    );
  }, [id, request]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" component="h3">
          Compras
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <RenderList
          isLoading={isLoadingPurchases}
          handleClick={handleClick}
          vehicles={purchases}
          type="purchases"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" component="h3">
          Vendas
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <RenderList
          isLoading={isLoadingSales}
          handleClick={handleClick}
          vehicles={sales}
          type="sales"
        />
      </Grid>
    </Grid>
  );
};

export default memo(Historic);
