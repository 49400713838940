import React, { useState, useEffect } from 'react';

import { Howl } from 'howler';
import AlertSong from 'assets/songs/notification.ogg';

import { Snackbar, Button } from '@material-ui/core';

import * as serviceWorker from '../serviceWorker';

const audio = new Howl({ src: [AlertSong] });

export default () => {
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null);

  const onUpdate = (registration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
    audio.play();
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate });
  }, []);

  const reloadPage = () => {
    if (waitingWorker && waitingWorker.postMessage) {
      waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    }
    setShowReload(false);
    window.location.reload(true);
  };

  return (
    <Snackbar
      open={showReload}
      message="Nova versão disponível"
      onClick={reloadPage}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      action={(
        <Button
          color="inherit"
          size="small"
          onClick={reloadPage}
        >
          Atualizar
        </Button>
      )}
    />
  );
};
