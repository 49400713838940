import Purchase from 'components/pages/operational/purchase';

import Vehicle from 'components/pages/operational/vehicles';
import EditVehicle from 'components/pages/operational/vehicles/edit';

import Customers from 'components/pages/operational/customers';
import AddCustomers from 'components/pages/operational/customers/add';
import EditCustomers from 'components/pages/operational/customers/edit';

export default [
  {
    title: 'Operacional',
    path: '/operacional',
    type: 'private',
    redirect: '/operacional/compra',
    exact: true,
  },
  {
    title: 'Compra',
    path: '/operacional/compra',
    type: 'private',
    component: Purchase,
    exact: true,
  },
  {
    title: 'Veículos',
    path: '/operacional/veiculos',
    type: 'private',
    component: Vehicle,
    exact: true,
  },
  {
    title: 'Editar Veículo',
    path: '/operacional/veiculos/:id',
    type: 'private',
    component: EditVehicle,
    exact: true,
  },
  {
    title: 'Clientes',
    path: '/operacional/clientes',
    type: 'private',
    component: Customers,
    exact: true,
  },
  {
    title: 'Adicionar Cliente',
    path: '/operacional/clientes/adicionar',
    type: 'private',
    component: AddCustomers,
    exact: true,
  },
  {
    title: 'Editar Cliente',
    path: '/operacional/clientes/:id',
    type: 'private',
    component: EditCustomers,
    exact: true,
  },
];
