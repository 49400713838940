import React from 'react';

import { useHistory } from 'react-router-dom';

import PurchaseForm from 'components/common/form/purchase';

export default () => {
  const history = useHistory();

  const afterSave = (responseData) => {
    history.push(`/operacional/veiculos/${responseData.id}`);
  };

  return (
    <PurchaseForm
      afterSave={afterSave}
    />
  );
};
