import React from 'react';

import PurchaseForm from 'components/common/form/purchase';

export default (props) => {
  const {
    index, setIndex, setExchangeVehicle,
    toggleContent, customer, setAllowClose,
  } = props;

  const defaultValues = { customer };

  const beforeSave = () => {
    setAllowClose(false);
  };

  const afterSave = (responseData) => {
    setExchangeVehicle({
      id: responseData.id,
      model: responseData.model,
      index,
    });
    setAllowClose(true);
    toggleContent();
    setIndex(-1);
  };

  return (
    <PurchaseForm
      afterSave={afterSave}
      beforeSave={beforeSave}
      defaultValues={defaultValues}
      containerId="echangeVehicleContainer"
    />
  );
};
