import React from 'react';

import {
  Dialog, DialogTitle, DialogContent,
  DialogActions, Button,
} from '@material-ui/core';

import DatePicker from 'components/common/form/datePicker';

export default (props) => {
  const {
    isLoading, open, handleClose, onConfirm,
  } = props;

  return (
    <Dialog
      open={open}
      disableBackdropClick={isLoading}
      onClose={handleClose}
      fullWidth
      scroll="body"
      maxWidth="xs"
    >
      <DialogTitle>
        Defina uma data do retorno
      </DialogTitle>
      <DialogContent>
        <DatePicker
          autoFocus
          label="Data do Retorno"
          name="returnedDate"
          disabled={isLoading}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          disabled={isLoading}
        >
          Cancelar
        </Button>
        <Button
          type="submit"
          color="primary"
          disabled={isLoading}
          onClick={onConfirm}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
