import React, { createRef } from 'react';

import useRequest from 'utils/http';

import { useSnackbar } from 'notistack';

import { scrollTo, Element } from 'components/common/scroll';

import MaterialTable from 'material-table';
import { Grid } from '@material-ui/core';

import SearchForm from 'components/common/form/search';

import Icons from './icons';
import localization from './localization';

const tableRef = createRef();

export default (props) => {
  const {
    url, children,
    inputName, inputPlaceholder,
    beforeSubmit, addTo, containerId,
    defaultValues = {},
  } = props;

  const request = useRequest();
  const { enqueueSnackbar } = useSnackbar();

  const getData = async (tableState) => {
    try {
      scrollTo({
        className: 'table',
        containerId,
        offset: 20,
      });

      const {
        orderBy = {},
        orderDirection,
        pageSize,
        page,
      } = tableState;

      let { query } = tableState;

      if (query === undefined && typeof beforeSubmit === 'function') {
        query = beforeSubmit(defaultValues);
      }

      if (query === undefined) {
        query = defaultValues;
      }

      const params = {
        sort: orderBy.field,
        order: orderDirection,
        skip: pageSize * page,
        limit: pageSize,
        ...query,
      };

      if (tableState.search) {
        params.search = tableState.search;
      }

      if (!params.sort || !params.order) {
        const defaultColumn = props.columns.find((column) => (
          column.defaultSort
        ));

        params.sort = defaultColumn.field;
        params.order = defaultColumn.defaultSort;

        if (!defaultColumn) {
          delete params.sort;
          delete params.order;
        }
      }

      const { data } = await request({
        method: 'get', url, params,
      });

      return {
        totalCount: data.total,
        data: data.data,
        page: Math.round(params.skip / pageSize),
      };
    } catch (e) {
      enqueueSnackbar('Erro ao buscar informações', { variant: 'error' });
      return { data: [], totalCount: 1, page: 0 };
    }
  };

  const onSubmit = (query) => {
    let formattedQuery = { ...query };

    if (typeof beforeSubmit === 'function') {
      formattedQuery = beforeSubmit(formattedQuery);
    }

    const filteredQuery = {};
    const fields = Object.keys(formattedQuery);

    fields.forEach((field) => {
      const fieldValue = (formattedQuery[field] && typeof formattedQuery[field] !== 'object')
        ? formattedQuery[field].toString()
        : formattedQuery[field];

      if (fieldValue) {
        filteredQuery[field] = fieldValue;
      }
    });

    const params = { page: 0, query: filteredQuery };

    if (tableRef.current) {
      tableRef.current.onQueryChange(params);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Element className="table">
          <SearchForm
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            inputName={inputName}
            inputPlaceholder={inputPlaceholder}
            addTo={addTo}
          >
            {children}
          </SearchForm>
        </Element>
      </Grid>
      <Grid item xs={12}>
        <MaterialTable
          icons={Icons}
          data={getData}
          tableRef={tableRef}
          options={{
            toolbar: false,
            pageSize: 10,
            pageSizeOptions: [10, 20, 50],
          }}
          localization={localization}
          {...props}
        />
      </Grid>
    </Grid>
  );
};
