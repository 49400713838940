export default (props) => {
  const {
    vehicle,
    open,
    handleClose,
  } = props;

  const data = {
    vehicle,
    open,
  };

  const methods = {
    handleClose: () => {
      if (typeof handleClose === 'function') {
        handleClose();
      }
    },
  };

  return { data, methods };
};
