import React from 'react';

import { privateFileUrl } from 'utils/http';

import {
  AppBar as MaterialAppBar,
  Typography, IconButton, Toolbar,
  Menu, MenuItem, Divider, Box,
  Badge,
} from '@material-ui/core';

import {
  Menu as MenuIcon,
  NotificationsNone as NotificationsNoneIcon,
} from '@material-ui/icons';

import { Link } from 'react-router-dom';

import Avatar from 'components/common/avatar';
import ThemeButton from 'components/common/themeButton';

import useHooks from './hooks';
import useStyles from './styles';

export default () => {
  const {
    title,
    user,
    openDrawer,
    menuEl,
    menuOpen,
    notifications,
    openDrawerNotifications,
    handleOpenMenu,
    handleCloseMenu,
    logout,
  } = useHooks();

  const classes = useStyles();

  return (
    <MaterialAppBar position="fixed" className={classes.appBar} color="secondary">
      <Toolbar>
        <IconButton
          color="inherit"
          edge="start"
          onClick={openDrawer}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        <ThemeButton />
        <IconButton
          color="inherit"
          edge="start"
          onClick={openDrawerNotifications}
        >
          <Badge badgeContent={notifications.length} color="primary">
            <NotificationsNoneIcon className={classes.notificationIcon} />
          </Badge>
        </IconButton>
        <div>
          <IconButton
            onClick={handleOpenMenu}
            color="inherit"
            size="medium"
          >
            <Avatar
              src={privateFileUrl(user.image)}
              badge={false}
            />
          </IconButton>
          <Menu
            anchorEl={menuEl}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={menuOpen}
            onClose={handleCloseMenu}
          >
            <Box className={classes.userName}>
              <Typography variant="subtitle1">
                {user.name}
              </Typography>
              <Typography variant="body2">
                @
                {user.username}
              </Typography>
            </Box>
            <Divider />
            <MenuItem
              component={Link}
              to="/perfil"
              onClick={handleCloseMenu}
            >
              Perfil
            </MenuItem>
            <MenuItem
              onClick={logout}
            >
              Sair
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </MaterialAppBar>
  );
};
