import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { getToken, setUser, logout } from 'main/actions/user';

import useRequest from 'utils/http';

import { Route, useHistory } from 'react-router-dom';

import Page from 'components/common/skeleton/page';

import If from 'components/common/if';

export default (props) => {
  const user = useSelector((state) => state.user);
  const redirectAfterLogin = useSelector((state) => state.page.redirectAfterLogin);
  const request = useRequest();
  const dispatch = useDispatch();
  const history = useHistory();

  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    const getUser = async () => {
      try {
        const token = getToken();
        if (token) {
          const { data } = await request({
            url: 'check',
          });

          await dispatch(setUser({
            id: data.id,
            username: data.username,
            name: data.name,
            email: data.email,
            image: data.image,
            isAdmin: data.isAdmin,
          }));
        }
      } catch (e) {
        dispatch(logout());
      } finally {
        setFirstRender(false);
      }
    };

    getUser();
  }, [request, dispatch]);

  const verifyUser = () => {
    if (user.id) {
      history.push(redirectAfterLogin);
    }
  };

  useEffect(verifyUser, [user]);

  return (
    <Route
      {...props}
      component={() => (
        <If test={!firstRender}>
          <Page title={props.title}>
            <props.component />
          </Page>
        </If>
      )}
    />
  );
};
