import React, { useState, useRef, useEffect } from 'react';

import moment from 'moment';

import { get } from 'lodash';

import { KeyboardDatePicker } from '@material-ui/pickers';

import { useFormContext } from 'react-hook-form';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  disabled: theme.disabledField,
}));

export default (props) => {
  const {
    name, label, disabled, required,
    dontStartToday, clearable = true,
  } = props;

  const pickerRef = useRef(null);
  const [pickerValue, setPickerValue] = useState(moment());

  const classes = useStyles();

  const {
    register, watch, setValue, errors,
    triggerValidation, formState,
    minDate: minDateProps,
    maxDate: maxDateProps,
  } = useFormContext();

  const minDate = (minDateProps)
    ? new Date(minDateProps)
    : undefined;

  const maxDate = (maxDateProps)
    ? new Date(maxDateProps)
    : undefined;

  const error = get(errors, name);

  const hasError = Boolean(error);
  const errorMessage = (hasError) ? error.message : '';

  const onChange = (value) => {
    setPickerValue(value);
    if (value) {
      setValue(name, value.format('YYYY-MM-DD'));
    } else {
      setValue(name, '');
    }
    triggerValidation(name);
  };

  const onBlur = () => {
    triggerValidation(name);
  };

  const twoWayUpdateValue = () => {
    if (pickerValue && pickerValue.format('YYYY-MM-DD') !== watch(name)) {
      setPickerValue(moment(watch(name), 'YYYY-MM-DD'));
    }

    if (pickerValue === null && watch(name)) {
      setPickerValue(moment(watch(name), 'YYYY-MM-DD'));
    }
  };

  useEffect(twoWayUpdateValue, [watch(name)]);

  const startPicker = () => {
    if (!watch(name) && dontStartToday) {
      setPickerValue(null);
    }

    if (!watch(name) && !dontStartToday) {
      setValue(name, pickerValue.format('YYYY-MM-DD'));
    }
  };

  useEffect(startPicker, []);

  const focusOnError = () => {
    if (hasError) {
      const names = name.split('.');
      delete names[names.length - 1];
      const findName = names.join('.').slice(0, -1);
      const filteredError = (findName) ? get(errors, findName || '') : errors;
      const errorsKeys = Object.keys(filteredError);
      if (
        formState.isSubmitting
        && (`${findName}.${errorsKeys[0]}` === name
          || errorsKeys[0] === name)
      ) {
        pickerRef.current.focus();
      }
    }
  };

  useEffect(focusOnError, [formState.isSubmitting]);

  const inputProps = {
    classes: {
      disabled: classes.disabled,
    },
    type: 'tel',
  };

  const labelFormatted = (required)
    ? `${label} *`
    : label;

  return (
    <>
      <KeyboardDatePicker
        label={labelFormatted}
        inputRef={pickerRef}
        value={pickerValue}
        onChange={onChange}
        format="DD/MM/YYYY"
        helperText={errorMessage}
        error={hasError}
        inputVariant="outlined"
        onBlur={onBlur}
        fullWidth
        disabled={disabled}
        InputProps={inputProps}
        okLabel="Ok"
        cancelLabel="Cancelar"
        clearLabel="Limpar"
        clearable={clearable}
        minDate={minDate}
        maxDate={maxDate}
      />
      <input
        type="hidden"
        name={name}
        ref={register}
        defaultValue={watch(name) || ''}
      />
    </>
  );
};
