import React, { memo } from 'react';

import { get } from 'lodash';

import { formatMask } from 'utils/masks';

import { useFormContext } from 'react-hook-form';

import MaskedInput from 'react-text-mask';

import {
  makeStyles, TextField as MaterialTextField,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  disabled: theme.disabledField,
}));

const InputMask = (props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      guide={false}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
    />
  );
};

const TextField = (props) => {
  const {
    name, label, type, InputProps,
    mask, disabled, required,
    maxLength, autoFocus,
  } = props;

  const classes = useStyles();

  const {
    errors, register,
    watch,
  } = useFormContext();

  const error = get(errors, name);

  const hasError = Boolean(error);
  const errorMessage = (hasError) ? error.message : '';

  const inputPropsFormatted = {
    classes: { disabled: classes.disabled },
    ...InputProps,
  };

  if (mask) {
    inputPropsFormatted.inputComponent = InputMask;
  }

  const labelFormatted = (required)
    ? `${label} *`
    : label;

  return (
    <MaterialTextField
      name={name}
      inputRef={register}
      type={type}
      variant="outlined"
      fullWidth
      label={labelFormatted}
      error={hasError}
      helperText={errorMessage}
      disabled={disabled}
      value={watch(name) || ''}
      InputProps={inputPropsFormatted}
      inputProps={{
        mask: formatMask(mask),
        maxLength,
      }}
      autoFocus={autoFocus}
    />
  );
};

export default memo(TextField);
