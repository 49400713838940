import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { changeRedirectAfterLogin } from 'main/actions/page';

import { Route, useHistory } from 'react-router-dom';

import { useSnackbar } from 'notistack';

import { AppBar, Drawer, Content } from 'components/common/skeleton';

import Page from 'components/common/skeleton/page';

import Breadcrumbs from 'components/common/skeleton/breadcrumbs';

import If from 'components/common/if';

export default (props) => {
  const { admin } = props;
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const verifyUser = () => {
    if (!user.id) {
      dispatch(changeRedirectAfterLogin(history.location.pathname));
      history.replace('/login');
    } else if (admin && !user.isAdmin) {
      enqueueSnackbar('Rota exclusiva para administradores', { variant: 'error' });
      history.push('/');
    }
  };

  useEffect(verifyUser, [user]);

  const allow = user.id && !(admin && !user.isAdmin);

  return (
    <>
      <AppBar />
      <Drawer />
      <Route
        {...props}
        component={() => (
          <If test={allow}>
            <Content>
              <Breadcrumbs />
              <Page title={props.title}>
                <props.component />
              </Page>
            </Content>
          </If>
        )}
      />
    </>
  );
};
