import Yup from 'utils/yup';

import { currencyToFloat } from 'utils/masks';

export default Yup.object().shape({
  payments: Yup.array().of(
    Yup.object().shape({
      value: Yup.string()
        .label('Valor')
        .required()
        .test('', 'Valor é um campo obrigatório', (value) => {
          const formattedValue = currencyToFloat(value);
          return formattedValue > 0;
        }),
      date: Yup.lazy((value) => {
        if (value !== undefined) {
          return Yup.string()
            .label('Data')
            .required()
            .date();
        }
        return Yup.mixed().notRequired();
      }),
      bank: Yup.lazy((value) => {
        if (value !== undefined) {
          return Yup.string()
            .label('Banco')
            .required();
        }
        return Yup.mixed().notRequired();
      }),
      exchangeVehicle: Yup.object().shape({
        id: Yup.lazy((value) => {
          if (value !== undefined) {
            return Yup.string()
              .required();
          }
          return Yup.mixed().notRequired();
        }),
        model: Yup.lazy((value) => {
          if (value !== undefined) {
            return Yup.string()
              .label('Veículo de Troca')
              .required();
          }
          return Yup.mixed().notRequired();
        }),
      }),
    }),
  ),
});
