import React from 'react';

import dot from 'dot-object';

import { useForm, FormContext } from 'react-hook-form';

import { Grid, Button } from '@material-ui/core';

import AdditionalInformationForm from 'components/common/form/additionalInformation';

import validationSchema from 'components/common/form/additionalInformation/validationSchema/sale';

export default (props) => {
  const {
    setActiveStep,
    data, setData, classes,
  } = props;

  const methods = useForm({
    defaultValues: data,
    validationSchema,
    validateCriteriaMode: 'firstErrorDetected',
    mode: 'onBlur',
  });

  const {
    handleSubmit, getValues,
  } = methods;

  const onSubmit = (formData) => {
    setData({ ...data, additionalInformation: formData.additionalInformation });
    setActiveStep(2);
  };

  const handleBackActiveStep = () => {
    const values = { ...getValues() };
    setData({ ...data, ...dot.object(values) });
    setActiveStep(0);
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <AdditionalInformationForm warranty />
          <Grid item xs={12}>
            <Button
              onClick={handleBackActiveStep}
              className={classes.button}
            >
              Voltar
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              type="submit"
            >
              Avançar
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormContext>
  );
};
