import { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { animateScroll } from 'react-scroll';

import {
  changeTitle, changeDrawer, changeDrawerItems,
} from 'main/actions/page';


class Page extends Component {
  componentDidMount() {
    const {
      title,
      changeTitle: actionChangeTitle,
      changeDrawer: actionChangeDrawer,
      changeDrawerItems: actionChangeDrawerItems,
    } = this.props;

    animateScroll.scrollToTop({
      duration: 600,
    });

    document.title = `${title} - Via Nova`;
    actionChangeTitle(title);
    actionChangeDrawer(false);
    actionChangeDrawerItems([]);
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  changeTitle, changeDrawer, changeDrawerItems,
}, dispatch);

export default connect(null, mapDispatchToProps)(Page);
