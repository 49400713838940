import Yup from 'utils/yup';

const base = Yup.object().shape({
  username: Yup.string()
    .label('Usuário')
    .min(4)
    .required(),
  name: Yup.string()
    .label('Nome')
    .min(4)
    .required(),
  email: Yup.string()
    .label('Email')
    .required()
    .email(),
});

const add = Yup.object().shape({
  password: Yup.string()
    .label('Senha')
    .required(),
  passwordConfirmation: Yup.string()
    .label('Confirmação de Senha')
    .required()
    .oneOf([Yup.ref('password'), null], 'Senhas diferentes'),
}).concat(base);

const edit = Yup.object().shape({
  passwordConfirmation: Yup.string()
    .test('', 'Senhas diferentes', function validation(value) {
      return !this.parent.password || value === this.parent.password;
    }),
}).concat(base);

export default { add, edit };
