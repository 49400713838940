import React, { useRef, useState, useEffect } from 'react';

import CONFIG from 'config/config';

import useRequest, { privateFileUrl } from 'utils/http';

import { useFormContext } from 'react-hook-form';

import Image from 'material-ui-image';

import { useSnackbar } from 'notistack';

import {
  Grid, Card, CardActionArea, CardMedia, Button,
  Dialog, DialogTitle, DialogContent, DialogActions,
} from '@material-ui/core';

import ButtonLink from 'components/common/buttonLink';

export default (props) => {
  const {
    src: defaultSrc, title = 'Alterar Imagem',
    afterRemove, beforeUpload, afterUpload,
    name, disabled, avatar, privateUrl,
  } = props;

  const request = useRequest();
  const { register, setValue, watch } = useFormContext();
  const { enqueueSnackbar } = useSnackbar();

  const [src, setSrc] = useState(defaultSrc || '404');
  const [openDialog, setOpenDialog] = useState(false);
  const [titleDialog, setTitleDialog] = useState('');
  const [contentDialog, setContentDialog] = useState('');
  const [actionDialog, setActionDialog] = useState(null);

  const setDefaultValue = () => {
    if (watch(name)) {
      if (privateUrl) {
        setSrc(privateFileUrl(watch(name)));
      } else {
        setSrc(watch(name));
      }
    }
  };

  useEffect(setDefaultValue, []);

  const fileRef = useRef(null);

  const selectImage = () => {
    if (fileRef && !disabled) {
      fileRef.current.click();
    }
  };

  const onCloseDialog = () => {
    fileRef.current.value = '';
    setOpenDialog(false);
  };

  const sendImage = async () => {
    try {
      const [file] = fileRef.current.files;

      const formData = new FormData();
      formData.append('file', file);

      setSrc('');

      onCloseDialog();

      if (typeof beforeUpload === 'function') {
        beforeUpload(file);
      }

      const url = (privateUrl)
        ? `${CONFIG.BUCKET_SERVICE_URL}private`
        : CONFIG.BUCKET_SERVICE_URL;

      const { data: responseData } = await request({
        url,
        method: 'post',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setValue(name, responseData.url);

      if (privateUrl) {
        setSrc(privateFileUrl(responseData.url));
      } else {
        setSrc(responseData.url);
      }

      if (typeof afterUpload === 'function') {
        afterUpload(responseData.url);
      }
    } catch (e) {
      if (typeof afterUpload === 'function') {
        afterUpload(false);
      }
      setSrc(defaultSrc || '404');
      enqueueSnackbar('Não foi possível salvar a imagem', { variant: 'error' });
    }
  };

  const removeImage = () => {
    setSrc('404');
    setValue(name, '');

    onCloseDialog();

    if (typeof afterRemove === 'function') {
      afterRemove();
    }
  };

  const confirmAddImage = async () => {
    const [file] = fileRef.current.files;

    if (!file) {
      return;
    }

    setContentDialog('');

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (e) => {
      setContentDialog((
        <Card>
          <Image src={e.target.result} />
        </Card>
      ));
    };

    setOpenDialog(true);
    setTitleDialog('Deseja adicionar a imagem?');
    setActionDialog(() => sendImage);
  };

  const confirmRemoveImage = () => {
    setOpenDialog(true);
    setTitleDialog('Deseja remover a imagem?');
    setContentDialog('');
    setActionDialog(() => removeImage);
  };

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12}>
        <Card style={(avatar) ? { borderRadius: '50%' } : {}}>
          <CardActionArea
            component="button"
            onClick={selectImage}
            disabled={disabled}
          >
            <CardMedia title={title}>
              <Image src={src} />
            </CardMedia>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item>
        <ButtonLink
          onClick={confirmRemoveImage}
          color="error"
          disabled={disabled || !watch(name)}
        >
          Remover Imagem
        </ButtonLink>
        <input
          type="hidden"
          ref={register}
          name={name}
          defaultValue={watch(name) || ''}
        />
        <input
          id="uploadImage"
          accept="image/png,image/jpg,image/jpeg"
          style={{ display: 'none' }}
          type="file"
          ref={fileRef}
          onChange={confirmAddImage}
        />
      </Grid>
      <Dialog
        open={openDialog}
        onClose={onCloseDialog}
        fullWidth
        maxWidth="xs"
        scroll="body"
      >
        <DialogTitle>
          {titleDialog}
        </DialogTitle>
        <DialogContent>
          {contentDialog}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onCloseDialog}
          >
            Cancelar
          </Button>
          <Button
            onClick={actionDialog}
            color="primary"
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
