import React from 'react';

import { withStyles, Avatar, Badge } from '@material-ui/core';

import If from 'components/common/if';

export default (props) => {
  const {
    src = '404',
    color = 'disabledLink',
    badge = true,
  } = props;

  const StyledBadge = withStyles((theme) => ({
    badge: {
      backgroundColor: theme.palette[color].main,
      color: theme.palette[color].main,
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }))(Badge);

  return (
    <>
      <If test={badge}>
        <StyledBadge
          variant="dot"
          overlap="circle"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Avatar src={src} />
        </StyledBadge>
      </If>
      <If test={!badge}>
        <Avatar src={src} />
      </If>
    </>
  );
};
