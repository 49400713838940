import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

import dot from 'dot-object';

import useRequest from 'utils/http';

import { currencyToFloat } from 'utils/masks';

import { useFormContext } from 'react-hook-form';

import { useSnackbar } from 'notistack';

import {
  makeStyles, Grid, IconButton, Button,
  Dialog, DialogContent, AppBar,
  Toolbar, Typography, ListItem, ListItemText,
} from '@material-ui/core';

import {
  Close as CloseIcon,
} from '@material-ui/icons';

import PaymentsForm from 'components/common/form/payments';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  stepContainer: {
    padding: 0,
  },
  stepGrid: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  appBar: {
    position: 'relative',
    marginBottom: theme.spacing(2),
  },
  title: {
    flex: 1,
  },
}));

const Buttons = (props) => {
  const { classes, isLoading } = props;

  const { watch } = useFormContext();

  const { payments = [] } = dot.object({ ...watch() });

  return (
    <Grid item xs={12}>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        type="submit"
        disabled={!payments.length || isLoading}
      >
        Salvar
      </Button>
    </Grid>
  );
};

export default (props) => {
  const {
    id, value, defaultValues: defaultValuesProps,
  } = props;

  const request = useRequest();
  const history = useHistory();

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { saleValue, buyer } = defaultValuesProps;

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleOpen = () => {
    if (!isLoading) {
      setOpen(!open);
    }
  };

  const getDefaultValues = () => {
    const { payments: defaultPayments = [] } = defaultValuesProps;

    const formattedPayments = defaultPayments.map((payment) => {
      const formattedPayment = { ...payment };

      formattedPayment.key = formattedPayment.id;

      delete formattedPayment.id;
      delete formattedPayment.author;
      delete formattedPayment.createdAt;
      delete formattedPayment.updatedAt;

      return formattedPayment;
    });

    return { payments: formattedPayments };
  };

  const onSubmit = async (formData) => {
    try {
      setIsLoading(true);

      const { payments: formPayments } = formData;

      const add = [];
      const edit = [];
      const remove = getDefaultValues().payments
        .filter((payment) => {
          const exist = formPayments.find((formPayment) => (
            formPayment.key * 1 === payment.key
          ));
          return Boolean(!exist);
        })
        .map((payment) => payment.key);

      formPayments.forEach((payment) => {
        const formattedPayment = { ...payment };

        formattedPayment.value = currencyToFloat(formattedPayment.value);

        formattedPayment.id = formattedPayment.key;
        delete formattedPayment.key;
        delete formattedPayment.vehicle;

        if (formattedPayment.type !== 'exchangeVehicle') {
          delete formattedPayment.exchangeVehicle;
        } else {
          formattedPayment.exchangeVehicle = formattedPayment.exchangeVehicle.id;
        }

        if (formattedPayment.id > 0) {
          edit.push(formattedPayment);
        } else {
          delete formattedPayment.id;
          add.push(formattedPayment);
        }
      });

      await request({
        method: 'patch',
        url: `vehicles/payments/${id}`,
        data: {
          add, edit, remove,
        },
      });

      enqueueSnackbar('Pagamentos salvos com sucesso', { variant: 'success' });
      history.push(`/operacional/veiculos/${id}`);
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar('Erro ao salvar pagamentos', { variant: 'error' });
    }
  };

  return (
    <>
      <ListItem
        button
        divider
        onClick={() => setOpen(true)}
      >
        <ListItemText>
          Valor Efetivo da Venda
        </ListItemText>
        <ListItemText style={{ textAlign: 'right' }}>
          {value}
        </ListItemText>
      </ListItem>
      <Dialog
        open={open}
        onClose={toggleOpen}
        fullScreen
      >
        <AppBar
          className={classes.appBar}
          color="secondary"
        >
          <Toolbar>
            <Typography
              variant="h6"
              className={classes.title}
            >
              Pagamentos
            </Typography>
            <IconButton
              color="inherit"
              disabled={isLoading}
              onClick={toggleOpen}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent id="paymentsContainer">
          <PaymentsForm
            defaultValues={getDefaultValues()}
            onSubmit={onSubmit}
            vehicleId={id}
            isLoading={isLoading}
            saleValue={saleValue}
            customer={buyer}
            containerId="paymentsContainer"
          >
            <Buttons
              isLoading={isLoading}
              classes={classes}
            />
          </PaymentsForm>
        </DialogContent>
      </Dialog>
    </>
  );
};
