import React from 'react';

import { onlyNumbers } from 'utils/masks';

import { useFormContext } from 'react-hook-form';

import { Grid } from '@material-ui/core';

import TextField from 'components/common/form/textField';

export default (props) => {
  const { isLoading } = props;

  const { watch } = useFormContext();

  const telephone = onlyNumbers(watch('intermediary.telephone'));

  const telephoneMask = (telephone.length <= 10)
    ? '(99)  9999-9999'
    : '(99) 9 9999-9999';

  return (
    <>
      <Grid item md={6} xs={12}>
        <TextField
          name="intermediary.name"
          label="Nome Completo"
          disabled={isLoading}
          required
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          name="intermediary.telephone"
          label="Telefone"
          type="tel"
          mask={telephoneMask}
          required
          disabled={isLoading}
        />
      </Grid>
    </>
  );
};
