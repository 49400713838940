import React from 'react';

import { Grid } from '@material-ui/core';

import TextField from 'components/common/form/textField';
import DatePicker from 'components/common/form/datePicker';
import TextArea from 'components/common/form/textArea';

import If from 'components/common/if';

export default (props) => {
  const {
    isLoading, sale, purchase, warranty,
    returned,
  } = props;

  const all = !purchase && !sale && !warranty && !returned;

  return (
    <>
      <If test={purchase || all}>
        <Grid item xs={12}>
          <DatePicker
            name="additionalInformation.entryDate"
            label="Data de Entrada"
            type="tel"
            disabled={isLoading}
          />
        </Grid>
        <If test={returned}>
          <Grid item xs={12}>
            <DatePicker
              name="additionalInformation.returnedDate"
              label="Data de Retorno"
              type="tel"
              disabled={isLoading}
            />
          </Grid>
        </If>
        <Grid item xs={12}>
          <TextArea
            name="additionalInformation.purchaseObservation"
            label="Observação da Compra"
            rows={2}
            rowsMax={5}
            disabled={isLoading}
          />
        </Grid>
      </If>
      <If test={warranty || all}>
        <Grid item xs={12}>
          <DatePicker
            name="additionalInformation.dateOfSale"
            label="Data da Venda"
            type="tel"
            required
            disabled={isLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            name="additionalInformation.deliveryDate"
            label="Data da Entrega"
            type="tel"
            required
            disabled={isLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="additionalInformation.deliveryKm"
            label="Km de Entrega"
            type="tel"
            mask="99999999999999"
            required
            disabled={isLoading}
          />
        </Grid>
      </If>
      <If test={sale || all}>
        <Grid item xs={12}>
          <TextArea
            name="additionalInformation.saleObservation"
            label="Observação da Venda"
            rows={2}
            rowsMax={5}
            disabled={isLoading}
          />
        </Grid>
      </If>
    </>
  );
};
