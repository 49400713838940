import { useState, useRef } from 'react';

import CONFIG from 'config/config';

import useRequest from 'utils/http';

import { useSnackbar } from 'notistack';

export default (props) => {
  const {
    files,
    isLoading: isLoadingProps,
    setIsLoading: setIsLoadingProps,
    afterUpload, afterRemove,
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const [openNameDialog, setOpenNameDialog] = useState(false);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);

  const [removeId, setRemoveId] = useState(null);

  const fileRef = useRef(null);

  const request = useRequest();

  const { enqueueSnackbar } = useSnackbar();

  const addFile = () => {
    fileRef.current.click();
  };

  const changeFile = async () => {
    const [file] = fileRef.current.files;

    if (file) {
      setOpenNameDialog(true);
    }
  };

  const closeNameDialog = () => {
    fileRef.current.value = '';
    setOpenNameDialog(false);
  };

  const sendFile = async ({ name }) => {
    try {
      const [file] = fileRef.current.files;

      const formData = new FormData();
      formData.append('file', file);

      closeNameDialog();

      setIsLoading(true);

      if (typeof setIsLoadingProps === 'function') {
        setIsLoadingProps(true);
      }

      const { data: responseData } = await request({
        url: `${CONFIG.BUCKET_SERVICE_URL}private`,
        method: 'post',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (typeof afterUpload === 'function') {
        afterUpload(responseData.url, name);
      }

      fileRef.current.value = '';
    } catch (e) {
      enqueueSnackbar('Não foi possível salvar o anexo', { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const confirmRemoveFile = (id) => {
    setRemoveId(id);
    setOpenRemoveDialog(true);
  };

  const closeRemoveDialog = () => {
    setRemoveId(null);
    setOpenRemoveDialog(false);
  };

  const removeFile = () => {
    closeRemoveDialog();
    setIsLoading(true);
    if (typeof afterRemove === 'function') {
      afterRemove(removeId);
    }
    setIsLoading(false);
  };

  return {
    files,
    fileRef,
    openNameDialog,
    closeNameDialog,
    openRemoveDialog,
    closeRemoveDialog,
    addFile,
    removeFile,
    changeFile,
    sendFile,
    confirmRemoveFile,
    isLoading: isLoading || isLoadingProps,
  };
};
