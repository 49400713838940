import { useEffect, useState } from 'react';
import * as DarkReader from 'darkreader';

const { localStorage } = window;

export default () => {
  const [isDark, setIsDark] = useState(localStorage.getItem('isDark') === 'true');

  useEffect(() => {
    if (isDark) {
      DarkReader.enable();
    } else {
      DarkReader.disable();
    }

    localStorage.setItem('isDark', isDark.toString());
  }, [isDark]);

  return {
    isDark,
    setIsDark,
  };
};
