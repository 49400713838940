import Yup from 'utils/yup';

export default Yup.object().shape({
  user: Yup.string()
    .label('Usuário ou Email')
    .required(),
  password: Yup.string()
    .label('Senha')
    .required(),
});
