import React from 'react';

import { currency } from 'utils/masks';

import {
  Grid, Typography, Divider, Button,
} from '@material-ui/core';

import Link from 'components/common/link';

export default (props) => {
  const {
    data, vehicle, disabled,
    setActiveStep, sendData,
  } = props;

  const { total = 0 } = data;

  const discount = total - vehicle.saleValue;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container justify="space-between" spacing={1}>
          <Grid item md="auto" xs={12}>
            <Typography variant="body1">Placa</Typography>
          </Grid>
          <Grid item md="auto" xs={12}>
            <Typography variant="body2">{vehicle.plate}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="space-between" spacing={1}>
          <Grid item md="auto" xs={12}>
            <Typography variant="body1">Modelo</Typography>
          </Grid>
          <Grid item md="auto" xs={12}>
            <Typography variant="body2">{vehicle.model}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="space-between" spacing={1}>
          <Grid item md="auto" xs={12}>
            <Typography variant="body1">Marca</Typography>
          </Grid>
          <Grid item md="auto" xs={12}>
            <Typography variant="body2">{vehicle.brand}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="space-between" spacing={1}>
          <Grid item md="auto" xs={12}>
            <Typography variant="body1">Comprador</Typography>
          </Grid>
          <Grid item md="auto" xs={12}>
            <Typography variant="body2">{data.buyer}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="space-between" spacing={1}>
          <Grid item md="auto" xs={12}>
            <Typography variant="body1">Total</Typography>
          </Grid>
          <Grid item md="auto" xs={12}>
            <Typography variant="body2">{currency(total)}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="space-between" spacing={1}>
          <Grid item md="auto" xs={12}>
            <Typography variant="body1">Valor de Venda</Typography>
          </Grid>
          <Grid item md="auto" xs={12}>
            <Typography variant="body2">{currency(vehicle.saleValue)}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="space-between" spacing={1}>
          <Grid item md="auto" xs={12}>
            <Typography variant="body1">Desconto</Typography>
          </Grid>
          <Grid item md="auto" xs={12}>
            <Typography variant="body2">{currency(discount)}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={disabled}
          onClick={sendData}
        >
          Finalizar Venda
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          disabled={disabled}
          onClick={() => setActiveStep(2)}
        >
          Voltar
        </Button>
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'right' }}>
        <Link to={`/operacional/veiculos/${vehicle.id}`}>
          Mais informações
        </Link>
      </Grid>
    </Grid>
  );
};
