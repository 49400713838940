import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

import {
  withStyles, Box, Grid, Divider, Breadcrumbs as MaterialBreadcrumbs,
  Link as MaterialLink, Typography,
} from '@material-ui/core';

import { containRoute } from 'utils/validations';

import routes from 'config/routes';

const styles = (theme) => ({
  breadcrumbBox: {
    marginBottom: theme.spacing(2),
  },
});

const renderLink = (route) => (
  <MaterialLink
    component={Link}
    key={route.path}
    color="inherit"
    to={route.path}
  >
    {route.title}
  </MaterialLink>
);

const renderSpan = (route) => (
  <Typography
    key={route.path}
    color="textPrimary"
  >
    {route.title}
  </Typography>
);

class Breadcrumbs extends Component {
  constructor(props) {
    super(props);
    this.mountPaths = this.mountPaths.bind(this);
    this.state = {
      paths: [],
    };
  }

  componentDidMount() {
    this.mountPaths();
  }

  mountPaths() {
    const { location } = this.props;
    const paths = [];
    const pathnames = location.pathname.split('/');
    pathnames.forEach((pathname, i) => {
      const splitPaths = location.pathname.split('/', i + 1);
      paths.push(splitPaths.join('/'));
    });
    paths[0] = '/';
    if (paths[1] === '/') {
      delete paths[1];
    }
    this.setState({ paths });
  }

  render() {
    const { classes, location } = this.props;
    const { paths } = this.state;
    return (
      <Box className={classes.breadcrumbBox}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MaterialBreadcrumbs>
              {paths.map((path) => {
                const foundRoute = routes.find((route) => containRoute(path, route.path, true));
                return (containRoute(location.pathname, foundRoute.path, true))
                  ? renderSpan(foundRoute) : renderLink(foundRoute);
              })}
            </MaterialBreadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default withRouter(withStyles(styles)(Breadcrumbs));
