import React, { useRef, useEffect, memo } from 'react';

import { get } from 'lodash';

import { useFormContext } from 'react-hook-form';

import {
  makeStyles, FormControl, InputLabel,
  FormHelperText, MenuItem, Select,
} from '@material-ui/core';

import If from 'components/common/if';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  disabled: theme.disabledField,
}));

const SelectBox = (props) => {
  const {
    label, name, options,
    required, disabled,
    onChange: onChangeProps,
    defaultValue = '',
  } = props;

  const classes = useStyles();

  const {
    errors, register,
    setValue, watch,
    formState,
  } = useFormContext();

  const LabelRef = useRef(null);
  const selectRef = useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  React.useEffect(() => {
    setLabelWidth(LabelRef.current.offsetWidth);
    register({ name });
  }, [register, name]);

  const error = get(errors, name);
  const hasError = Boolean(error);
  const errorMessage = (hasError) ? error.message : '';

  const focusOnError = () => {
    if (hasError) {
      const names = name.split('.');
      delete names[names.length - 1];
      const findName = names.join('.').slice(0, -1);
      const filteredError = (findName) ? get(errors, findName || '') : errors;
      const errorsKeys = Object.keys(filteredError);
      if (
        formState.isSubmitting
        && (`${findName}.${errorsKeys[0]}` === name
        || errorsKeys[0] === name)
      ) {
        selectRef.current.focus();
      }
    }
  };

  useEffect(focusOnError, [formState.isSubmitting]);

  const beforeMount = () => {
    setValue(name, watch(name) || defaultValue);
  };

  useEffect(beforeMount, []);

  const labelFormatted = (required)
    ? `${label} *`
    : label;

  const onChange = (event) => {
    const { value } = event.target;
    if (onChangeProps && typeof onChangeProps === 'function') {
      onChangeProps(value);
    }
    setValue(name, value, true);
  };

  return (
    <FormControl
      fullWidth
      variant="outlined"
      className={classes.formControl}
      error={hasError}
      disabled={disabled}
    >
      <InputLabel ref={LabelRef} id={name}>
        {labelFormatted}
      </InputLabel>
      <Select
        name={name}
        labelWidth={labelWidth}
        classes={{ disabled: classes.disabled }}
        value={watch(name) || defaultValue}
        onChange={onChange}
        inputProps={{
          onBlur: (e) => setValue(name, e.target.value, true),
          ref: selectRef,
        }}
      >
        {Object.keys(options || {}).map((key) => (
          <MenuItem key={key} value={key}>{options[key]}</MenuItem>
        ))}
      </Select>
      <If test={hasError}>
        <FormHelperText>{errorMessage}</FormHelperText>
      </If>
    </FormControl>
  );
};

export default memo(SelectBox);
