import { scroller, Element as ElementLib } from 'react-scroll';

import theme from 'main/theme';

export const scrollTo = (props) => {
  const {
    className, containerId,
    timeout = 0, offset: additionalOffset = 0,
  } = props;

  const defaultOffset = (containerId)
    ? 0
    : theme.mixins.toolbar.minHeight;

  setTimeout(() => {
    scroller.scrollTo(className, {
      smooth: true,
      delay: 0,
      duration: 200,
      offset: -defaultOffset - additionalOffset,
      containerId,
    });
  }, timeout);
};

export const Element = ElementLib;
