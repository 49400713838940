import React from 'react';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import moment from 'moment';

import 'moment/locale/pt-br';

import MomentUtils from '@date-io/moment';

export default (props) => {
  const { children } = props;
  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale('pt-br')}>
      {children}
    </MuiPickersUtilsProvider>
  );
};
