import Yup from 'utils/yup';

import salesmanValidation from './salesman';

export default Yup.object().shape({
  customer: Yup.object().shape({
    rg: Yup.string()
      .label('RG')
      .required()
      .min(3),
    email: Yup.string()
      .label('Email')
      .email(),
    birth: Yup.string()
      .label('Nascimento')
      .required()
      .date(),
  }),
})
  .concat(salesmanValidation);
