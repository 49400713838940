import React, { useState, useEffect } from 'react';

import { useRouteMatch } from 'react-router-dom';

import useRequest from 'utils/http';

import { useSnackbar } from 'notistack';

import FilesUpload from 'components/common/form/filesUpload';

export default () => {
  const request = useRequest();
  const match = useRouteMatch();
  const { enqueueSnackbar } = useSnackbar();

  const { id } = match.params;

  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [updated, setUpdated] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: responseData } = await request({
          method: 'get',
          url: 'customers/files',
          params: {
            customer: id,
          },
        });

        setFiles(responseData.data);
      } catch (e) {
        enqueueSnackbar('Não foi possível carregar os anexos', { variant: 'warning' });
      } finally {
        setIsLoading(false);
      }
    };

    if (updated) {
      getData();
      setUpdated(false);
    }
  }, [updated, enqueueSnackbar, id, request]);

  const afterUpload = async (url, name) => {
    try {
      setIsLoading(true);

      await request({
        method: 'post',
        url: 'customers/files',
        data: {
          url,
          name,
          customer: id,
        },
      });

      setUpdated(true);
      enqueueSnackbar('Anexo salvo com sucesso', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Não foi possível salvar o anexo', { variant: 'error' });
    }
  };

  const afterRemove = async (fileId) => {
    try {
      setIsLoading(true);

      await request({
        method: 'delete',
        url: `customers/files/${fileId}`,
      });

      setUpdated(true);
      enqueueSnackbar('Anexo removido com sucesso', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Não foi possível remover o anexo', { variant: 'error' });
    }
  };

  return (
    <FilesUpload
      files={files}
      isLoading={isLoading}
      afterUpload={afterUpload}
      afterRemove={afterRemove}
    />
  );
};
