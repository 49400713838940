import React from 'react';

import {
  makeStyles, useTheme, Typography, Link,
} from '@material-ui/core';

import If from 'components/common/if';

const useStyles = makeStyles((theme) => ({
  moreInfoLinkDisabled: {
    color: theme.palette.disabledLink.main,
  },
}));

export default (props) => {
  const {
    children, disabled,
    variant = 'body2',
    color,
  } = props;

  const classes = useStyles();
  const theme = useTheme();

  const colorButton = (color)
    ? theme.palette[color].main
    : undefined;

  return (
    <>
      <If test={!disabled}>
        <Link
          component="button"
          type="button"
          variant={variant}
          style={{ color: colorButton }}
          {...props}
          color={undefined}
        >
          {children}
        </Link>
      </If>
      <If test={disabled}>
        <Typography
          component="span"
          variant={variant}
          className={classes.moreInfoLinkDisabled}
          {...props}
          onClick={undefined}
        >
          {children}
        </Typography>
      </If>
    </>
  );
};
