import { isEqual } from 'lodash';

const { localStorage } = window;

const INITIAL_STATE = {
  id: null,
  username: null,
  name: null,
  email: null,
  image: '',
  isAdmin: null,
  notifications: [],
  token: localStorage.getItem('token'),
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, ...action.payload };
    case 'SET_USER':
      return { ...state, ...action.payload };
    case 'LOGOUT':
      return INITIAL_STATE;
    case 'CHANGE_NOTIFICATIONS':
      if (isEqual(state.notifications, action.payload)) {
        return state;
      }
      return { ...state, notifications: action.payload };
    case 'DELETE_NOTIFICATIONS':
      return {
        ...state,
        notifications: state.notifications.filter((notification) => (
          notification.id * 1 !== action.payload * 1
        )),
      };
    default:
      return state;
  }
}
