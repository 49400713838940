import React, { useEffect, useState } from 'react';

import useRequest from 'utils/http';

import { useSnackbar } from 'notistack';

import { Grid } from '@material-ui/core';

import VehicleSituationCard from 'components/common/vehicleSituation/card';

const INITIAL_STATE = {
  inPreparation: 0,
  inStock: 0,
};

export default () => {
  const request = useRequest();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsloading] = useState(true);
  const [situations, setSituations] = useState(INITIAL_STATE);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: responseData } = await request({
          url: 'vehicles/situations',
          method: 'get',
        });
        setSituations(responseData);
      } catch (e) {
        enqueueSnackbar('Erro ao calcular situações', { variant: 'warning' });
      } finally {
        setIsloading(false);
      }
    };

    getData();
  }, [request, enqueueSnackbar]);

  return (
    <Grid container spacing={2}>
      <Grid item md={3} xs={12}>
        <VehicleSituationCard
          isLoading={isLoading}
          value={situations.inPreparation}
          situation="inPreparation"
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <VehicleSituationCard
          isLoading={isLoading}
          value={situations.inStock}
          situation="inStock"
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <VehicleSituationCard
          isLoading={isLoading}
          value={situations.pendingWarranty}
          situation="pendingWarranty"
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <VehicleSituationCard
          isLoading={isLoading}
          value={situations.returned}
          situation="returned"
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <VehicleSituationCard
          isLoading={isLoading}
          value={situations.sold}
          situation="sold"
        />
      </Grid>
    </Grid>
  );
};
