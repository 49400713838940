import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import useRequest from 'utils/http';

import { scrollTo, Element } from 'components/common/scroll';

import { useSnackbar } from 'notistack';

import {
  makeStyles, Grid, Typography, LinearProgress,
  Stepper, Step as MaterialStep, StepLabel, StepContent,
  AppBar, Toolbar, IconButton,
  Dialog, DialogContent,
} from '@material-ui/core';

import {
  Close as CloseIcon,
} from '@material-ui/icons';

import If from 'components/common/if';

import Checklist from './steps/checklist';
import AdditionalInformation from './steps/additionalInformation';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  stepContainer: {
    padding: 0,
  },
  stepGrid: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  appBar: {
    position: 'relative',
    marginBottom: theme.spacing(2),
  },
  title: {
    flex: 1,
  },
}));

const steps = [
  { key: 'checklist', label: 'Checklist' },
  { key: 'additionalInformation', label: 'Informações Adicionais' },
];

export default (props) => {
  const {
    id, open, setOpen,
  } = props;

  const request = useRequest();
  const history = useHistory();

  const [activeStep, setActiveStep] = useState(0);
  const [oldActiveStep, setOldActiveStep] = useState(0);
  const [data, setData] = useState({});

  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (open === false) {
      setData({});
      setActiveStep(0);
    }
  }, [open]);

  const confirmSetOpen = () => {
    if (activeStep <= steps.length - 1) {
      setOpen(false);
    }
  };

  const sendData = async () => {
    try {
      await request({
        method: 'post',
        url: `warranties/${id}`,
        data: {
          ...data.checklist,
          ...data.additionalInformation,
        },
      });

      enqueueSnackbar('Garantia gerada com sucesso', { variant: 'success' });
      history.push(`/operacional/veiculos/${id}`);
    } catch (e) {
      enqueueSnackbar('Erro ao gerar garantia', { variant: 'error' });
      setActiveStep(1);
    }
  };

  if (activeStep > steps.length - 1 && activeStep !== oldActiveStep) {
    sendData();
  }

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Checklist
            data={data}
            setData={setData}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            classes={classes}
          />
        );
      case 1:
        return (
          <AdditionalInformation
            data={data}
            setData={setData}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            classes={classes}
          />
        );
      default:
        return false;
    }
  };

  if (activeStep !== oldActiveStep) {
    setOldActiveStep(activeStep);
    scrollTo({
      className: `generateWarranty[${activeStep}]`,
      timeout: 500,
      offset: 20,
      containerId: 'generatyWarrantyContainer',
    });
  }

  return (
    <Dialog
      open={open}
      onClose={confirmSetOpen}
      fullScreen
    >
      <AppBar
        className={classes.appBar}
        color="secondary"
      >
        <Toolbar>
          <Typography
            variant="h6"
            className={classes.title}
          >
            Gerar Garantia
          </Typography>
          <IconButton
            color="inherit"
            onClick={confirmSetOpen}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent id="generatyWarrantyContainer">
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.root}>
              <Stepper activeStep={activeStep} orientation="vertical" className={classes.stepContainer}>
                {steps.map(({ label }, index) => (
                  <MaterialStep key={label}>
                    <StepLabel>
                      <Element className={`generateWarranty[${index}]`}>
                        {label}
                      </Element>
                    </StepLabel>
                    <StepContent>
                      <Grid container spacing={2} className={classes.stepGrid}>
                        <Grid item xs={12}>
                          {renderStepContent()}
                        </Grid>
                      </Grid>
                    </StepContent>
                  </MaterialStep>
                ))}
              </Stepper>
              <If test={activeStep >= steps.length}>
                <Grid container spacing={1} className={classes.stepGrid}>
                  <Grid item xs={12}>
                    <Element className="generateWarranty[2]">
                      <Typography variant="body2" component="div">
                        Enviando Informações...
                      </Typography>
                    </Element>
                  </Grid>
                  <Grid item xs={12}>
                    <LinearProgress />
                  </Grid>
                </Grid>
              </If>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
