import React from 'react';

import { onlyNumbers } from 'utils/masks';

import {
  Dialog, DialogContent, AppBar, Toolbar,
  Typography, IconButton, ListItem,
} from '@material-ui/core';

import { Close as CloseIcon } from '@material-ui/icons';

import Table from 'components/common/table';

import { useFormContext } from 'react-hook-form';

import TextField from 'components/common/form/textField';
import Checkbox from 'components/common/form/checkBox';

import TextMask from 'components/common/textMask';

import useStyles from './styles';

const beforeSubmit = (formData) => {
  const formattedData = {
    ...formData,
    cpfCnpj: onlyNumbers(formData.cpfCnpj),
  };

  return formattedData;
};

const defaultValues = {
  isBuyer: true,
  isSalesman: true,
};

const SearchForm = () => {
  const { watch } = useFormContext();

  const cpfCnpjMask = ((watch('cpfCnpj') || '').length <= 14)
    ? '999.999.999-999'
    : '99.999.999/9999-99';

  return (
    <>
      <ListItem>
        <TextField
          name="cpfCnpj"
          label="CPF/CNPJ"
          mask={cpfCnpjMask}
        />
      </ListItem>
      <ListItem>
        <Typography variant="subtitle1" component="div">
          Tipo
        </Typography>
      </ListItem>
      <ListItem>
        <Checkbox
          name="isBuyer"
          label="Comprador"
        />
      </ListItem>
      <ListItem>
        <Checkbox
          name="isSalesman"
          label="Vendedor"
        />
      </ListItem>
    </>
  );
};

export default (props) => {
  const {
    open,
    onRowClick: onRowClickProps,
    onClose: onCloseProps,
  } = props;

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      fullScreen
      id="customers"
      onClose={onCloseProps}
    >
      <AppBar
        className={classes.appBar}
        color="secondary"
      >
        <Toolbar>
          <Typography
            variant="h6"
            className={classes.title}
          >
            Clientes
          </Typography>
          <IconButton
            color="inherit"
            onClick={onCloseProps}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Table
          columns={[
            {
              title: 'CPF/CNPJ',
              field: 'cpfCnpj',
              render: (rowData) => {
                const mask = (rowData.cpfCnpj.toString().length === 11)
                  ? '999.999.999-99'
                  : '99.999.999/9999-99';
                return (
                  <TextMask mask={mask}>{rowData.cpfCnpj}</TextMask>
                );
              },
            },
            { title: 'Nome', field: 'name', defaultSort: 'asc' },
          ]}
          url="customers"
          inputName="name"
          inputPlaceholder="Nome"
          beforeSubmit={beforeSubmit}
          containerId="customers"
          defaultValues={defaultValues}
          onRowClick={onRowClickProps}
        >
          <SearchForm />
        </Table>
      </DialogContent>
    </Dialog>
  );
};
