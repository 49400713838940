import React, { useState, useEffect } from 'react';

import useRequest from 'utils/http';
import axios from 'axios';

import {
  onlyNumbers,
  telephone as telephoneMask,
  cep as cepMask,
  currencyToFloat,
} from 'utils/masks';

import {
  convertDate, getNow,
  diffYears, validate,
} from 'utils/date';

import { useFormContext, useFieldArray } from 'react-hook-form';

import { useSnackbar } from 'notistack';

import {
  Grid, InputAdornment, IconButton,
  Button, Typography,
} from '@material-ui/core';

import {
  Search as SearchIcon,
  Room as RoomIcon,
} from '@material-ui/icons';

import TextField from 'components/common/form/textField';
import CurrencyTextField from 'components/common/form/currencyTextField';
import SelectBox from 'components/common/form/selectBox';
import ChipTextField from 'components/common/form/chipTextField';
import DatePicker from 'components/common/form/datePicker';
import ImageUpload from 'components/common/form/imageUpload';

import If from 'components/common/if';

import uf from 'utils/selectOptions/uf';

import useStyles from './styles';

const CustomerForm = (props) => {
  const {
    isLoading, setIsLoading,
    buyer, submitButton, classes,
  } = props;

  const request = useRequest();
  const { enqueueSnackbar } = useSnackbar();

  const [age, setAge] = useState(null);

  const {
    watch, setValue, control,
  } = useFormContext();

  const {
    fields: fieldsTelephone,
    append: appendTelephone,
    remove: removeTelephone,
  } = useFieldArray({
    control,
    name: 'customer.telephones',
  });

  const {
    fields: fieldsEmail,
    append: appendEmail,
    remove: removeEmail,
  } = useFieldArray({
    control,
    name: 'customer.emails',
  });

  const cpfCnpj = onlyNumbers(watch('customer.cpfCnpj'));
  const telephone = onlyNumbers(watch('customer.telephone'));
  const cep = onlyNumbers(watch('customer.cep'));

  const handleFindCpfCnpj = async () => {
    try {
      if (cpfCnpj.length < 11) {
        throw new Error('invalid format');
      }
      setIsLoading(true);
      const { data: responseData } = await request({
        method: 'get',
        url: 'customers',
        params: { cpfCnpj },
      });
      if (!responseData.data.length) {
        throw new Error('not found');
      }
      const customer = responseData.data[0];

      setValue('customer.name', customer.name, true);
      setValue('customer.cep', cepMask(customer.cep), true);
      setValue('customer.uf', customer.uf, true);
      setValue('customer.city', customer.city, true);
      setValue('customer.address', customer.address, true);
      setValue('customer.street', customer.street, true);
      setValue('customer.number', customer.number, true);
      setValue('customer.naturalness', customer.naturalness, true);
      setValue('customer.nationality', customer.nationality, true);
      setValue('customer.mothersName', customer.mothersName, true);
      setValue('customer.profession', customer.profession, true);
      setValue('customer.income', customer.income ? currencyToFloat(customer.income) : '', true);
      setValue('customer.workplace', customer.workplace, true);
      setValue('customer.companyTime', customer.companyTime, true);
      setValue('customer.personalReference', customer.personalReference, true);

      removeTelephone();

      customer.telephones.forEach((tel) => {
        appendTelephone({
          value: telephoneMask(`${tel.prefix}${tel.number}`),
        });
      });

      if (buyer) {
        if (customer.birth) {
          customer.birth = convertDate(customer.birth, 'YYYY-MM-DD', 'YYYY-MM-DD');
          setValue('customer.birth', customer.birth, true);
        }
        setValue('customer.rg', customer.rg, true);
        setValue('customer.email', customer.email, true);

        removeEmail();

        customer.emails.forEach((email) => {
          appendEmail({
            value: email.email,
          });
        });
      }
    } catch (e) {
      enqueueSnackbar('Erro ao buscar CPF/CNPJ', { variant: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleFindCep = async () => {
    try {
      if (cep.length < 8) {
        throw new Error('invalid format');
      }
      setIsLoading(true);
      const url = `https://viacep.com.br/ws/${cep}/json/`;
      const { data: responseData } = await axios.get(url);

      if (responseData.erro || !responseData.cep) {
        throw new Error('not found');
      }

      setValue('customer.uf', responseData.uf, true);
      setValue('customer.city', responseData.localidade, true);
      setValue('customer.address', responseData.bairro, true);
      setValue('customer.street', responseData.logradouro, true);
    } catch (e) {
      enqueueSnackbar('Erro ao buscar CEP', { variant: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const getAge = () => {
    const birth = watch('customer.birth');

    if (!validate(birth, 'YYYY-MM-DD')) {
      setAge(null);
      return;
    }

    const now = getNow('YYYY-MM-DD');

    const newAge = diffYears(now, birth, 'YYYY-MM-DD');

    if (newAge < 1) {
      setAge(null);
      return;
    }

    setAge(newAge);
  };

  useEffect(getAge, [watch('customer.birth')]);

  const cpfCnpjMaskField = (cpfCnpj.length <= 11)
    ? '999.999.999-999'
    : '99.999.999/9999-99';

  const telephoneMaskField = (telephone.length <= 10)
    ? '(99)  9999-9999'
    : '(99) 9 9999-9999';

  const getMapLink = () => {
    let link = 'http://maps.google.com.br/maps?q=';

    if (watch('customer.street')) {
      link += `${watch('customer.street')}, `;
    }

    if (watch('customer.number')) {
      link += `${watch('customer.number')} `;
    }

    if (watch('customer.address')) {
      link += `${watch('customer.address')} - `;
    }

    if (watch('customer.city')) {
      link += `${watch('customer.city')}/`;
    }

    if (watch('customer.uf')) {
      link += `${watch('customer.uf')}, `;
    }

    if (watch('customer.cep')) {
      link += `${watch('customer.cep').replace('-', '')}`;
    }

    return link;
  };

  const getMapEnable = () => (
    Boolean(watch('customer.street')
      && watch('customer.number')
      && watch('customer.address')
      && watch('customer.city')
      && watch('customer.uf')
      && watch('customer.cep'))
  );

  return (
    <>
      <Grid item md={4} xs={12}>
        <TextField
          name="customer.cpfCnpj"
          label="CPF/CNPJ"
          type="tel"
          mask={cpfCnpjMaskField}
          disabled={isLoading}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleFindCpfCnpj}
                  disabled={isLoading}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          name="customer.name"
          label="Nome Completo"
          disabled={isLoading}
          required
        />
      </Grid>
      <If test={buyer}>
        <Grid item md={4} xs={12}>
          <DatePicker
            name="customer.birth"
            label="Nascimento"
            disabled={isLoading}
            required
            dontStartToday
          />
          <If test={age}>
            <Typography align="right" className={classes.ageText}>
              {`${age} ${(age > 1) ? 'anos' : 'ano'}`}
            </Typography>
          </If>
        </Grid>
      </If>
      <If test={buyer}>
        <Grid item md={4} xs={12}>
          <TextField
            name="customer.rg"
            label="RG"
            type="tel"
            mask="9999999999999999"
            disabled={isLoading}
          />
        </Grid>
      </If>
      <Grid item md={4} xs={12}>
        <ChipTextField
          label="Telefone"
          name="customer.telephone"
          chipName="customer.telephones"
          type="tel"
          mask={telephoneMaskField}
          required
          disabled={isLoading}
          fields={fieldsTelephone}
          append={appendTelephone}
          remove={removeTelephone}
        />
      </Grid>
      <If test={buyer}>
        <Grid item md={4} xs={12}>
          <ChipTextField
            label="Email"
            name="customer.email"
            chipName="customer.emails"
            type="email"
            disabled={isLoading}
            fields={fieldsEmail}
            append={appendEmail}
            remove={removeEmail}
          />
        </Grid>
      </If>
      <Grid item md={4} xs={12}>
        <TextField
          name="customer.cep"
          label="CEP"
          type="tel"
          mask="99999-999"
          disabled={isLoading}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleFindCep}
                  disabled={isLoading}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <SelectBox
          name="customer.uf"
          label="UF"
          disabled={isLoading}
          required
          options={uf}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          name="customer.city"
          label="Cidade"
          disabled={isLoading}
          required
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          name="customer.address"
          label="Bairro"
          disabled={isLoading}
          required
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          name="customer.street"
          label="Rua"
          disabled={isLoading}
          required
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          name="customer.number"
          label="Número"
          disabled={isLoading}
          required
          mask="******"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          name="customer.complement"
          label="Complemento"
          disabled={isLoading}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          name="customer.naturalness"
          label="Naturalidade"
          disabled={isLoading}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          name="customer.nationality"
          label="Nacionalidade"
          disabled={isLoading}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          name="customer.mothersName"
          label="Nome da mãe"
          disabled={isLoading}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          name="customer.profession"
          label="Profissão"
          disabled={isLoading}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <CurrencyTextField
          name="customer.income"
          label="Renda"
          disabled={isLoading}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          name="customer.workplace"
          label="Local de trabalho"
          disabled={isLoading}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          name="customer.companyTime"
          label="Tempo de empresa"
          disabled={isLoading}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          name="customer.personalReference"
          label="Referência pessoal"
          disabled={isLoading}
        />
      </Grid>
      <Grid item md={4} xs={12} className={classes.gridMap}>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={12}>
            <Button
              component="a"
              href={getMapLink()}
              disabled={!getMapEnable()}
              target="_blank"
              fullWidth
            >
              <RoomIcon />
              Ver no mapa
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <If test={submitButton}>
        <Grid item xs={12}>
          <Button
            disabled={isLoading}
            variant="contained"
            color="primary"
            type="submit"
          >
            Salvar
          </Button>
        </Grid>
      </If>
    </>
  );
};

export default (props) => {
  const {
    id, isLoading, setIsLoading, inputImage,
  } = props;

  const request = useRequest();
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const { watch } = useFormContext();

  const handleChangeImage = async (url) => {
    try {
      if (url === false) {
        throw Error('DONT UPLOAD IMAGE');
      }

      if (id) {
        await request({
          method: 'patch',
          url: `customers/${id}`,
          data: {
            image: watch('customer.image'),
          },
        });

        enqueueSnackbar('Imagem salva com sucesso', { variant: 'success' });
      }
    } catch (e) {
      enqueueSnackbar('Não foi possível salvar a imagem', { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <If test={inputImage}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item md={3} xs={12}>
              <ImageUpload
                name="customer.image"
                beforeUpload={() => setIsLoading(true)}
                afterUpload={handleChangeImage}
                afterRemove={handleChangeImage}
                disabled={isLoading}
                avatar
                privateUrl
              />
            </Grid>
            <Grid item md={9} xs={12}>
              <Grid container spacing={2}>
                <CustomerForm {...props} classes={classes} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </If>
      <If test={!inputImage}>
        <CustomerForm {...props} classes={classes} />
      </If>
    </>
  );
};
