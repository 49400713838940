import React from 'react';

import { useHistory } from 'react-router-dom';

import { onlyNumbers } from 'utils/masks';

import { useFormContext } from 'react-hook-form';

import { ListItem, Typography } from '@material-ui/core';

import Table from 'components/common/table';

import TextField from 'components/common/form/textField';
import Checkbox from 'components/common/form/checkBox';

import TextMask from 'components/common/textMask';

const defaultValues = {
  isBuyer: false,
  isSalesman: false,
};

const beforeSubmit = (formData) => {
  const formattedData = {
    ...formData,
    cpfCnpj: onlyNumbers(formData.cpfCnpj),
  };

  return formattedData;
};

const SearchForm = () => {
  const { watch } = useFormContext();

  const cpfCnpjMask = ((watch('cpfCnpj') || '').length <= 14)
    ? '999.999.999-999'
    : '99.999.999/9999-99';

  return (
    <>
      <ListItem>
        <TextField
          name="cpfCnpj"
          label="CPF/CNPJ"
          mask={cpfCnpjMask}
        />
      </ListItem>
      <ListItem>
        <Typography variant="subtitle1" component="div">
          Tipo
        </Typography>
      </ListItem>
      <ListItem>
        <Checkbox
          name="isBuyer"
          label="Comprador"
        />
      </ListItem>
      <ListItem>
        <Checkbox
          name="isSalesman"
          label="Vendedor"
        />
      </ListItem>
    </>
  );
};

export default () => {
  const history = useHistory();

  return (
    <Table
      columns={[
        {
          title: 'CPF/CNPJ',
          field: 'cpfCnpj',
          render: (rowData) => {
            const mask = (rowData.cpfCnpj.toString().length === 11)
              ? '999.999.999-99'
              : '99.999.999/9999-99';
            return (
              <TextMask mask={mask}>{rowData.cpfCnpj}</TextMask>
            );
          },
        },
        { title: 'Nome', field: 'name', defaultSort: 'asc' },
      ]}
      url="customers"
      onRowClick={(event, rowData) => history.push(`/operacional/clientes/${rowData.id}`)}
      inputName="name"
      inputPlaceholder="Nome"
      beforeSubmit={beforeSubmit}
      defaultValues={defaultValues}
      addTo="/operacional/clientes/adicionar"
    >
      <SearchForm />
    </Table>
  );
};
