import Yup from 'utils/yup';

export default Yup.object().shape({
  additionalInformation: Yup.object().shape({
    entryDate: Yup.string()
      .label('Data de Entrada')
      .required()
      .date(),
  }),
});
