import React from 'react';

import {
  Dialog, DialogTitle,
  DialogContent, DialogActions,
  Button, Typography, List, ListItem,
} from '@material-ui/core';

import If from 'components/common/if';

export default (props) => {
  const {
    onConfirm, isLoading, open, handleClose,
    type,
  } = props;

  const all = Boolean(!type);

  return (
    <Dialog
      open={open}
      disableBackdropClick={isLoading}
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
      scroll="body"
    >
      <DialogTitle>
        Caso confirme esta operação serão removidas as seguintes informações:
      </DialogTitle>
      <DialogContent>
        <Typography component="div" variant="body2">
          <List>
            <If test={type === 'sale' || all}>
              <ListItem divider>
                Comprador
              </ListItem>
              <ListItem divider>
                Pagamentos
              </ListItem>
              <ListItem divider>
                Valor Efetivo da Venda
              </ListItem>
              <ListItem divider>
                Observação da Venda
              </ListItem>
            </If>
            <If test={type === 'warranty' || all}>
              <ListItem divider>
                Checklist da Garantia
              </ListItem>
              <ListItem divider>
                Data da Venda
              </ListItem>
              <ListItem divider>
                Data da Entrega
              </ListItem>
              <ListItem divider>
                Km de Entrega
              </ListItem>
            </If>
          </List>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          disabled={isLoading}
        >
          Cancelar
        </Button>
        <Button
          onClick={onConfirm}
          color="primary"
          disabled={isLoading}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
