import React, { memo } from 'react';

import { get } from 'lodash';

import { useFormContext } from 'react-hook-form';

import { makeStyles, TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  disabled: theme.disabledField,
}));

const TextArea = (props) => {
  const {
    name, label, rows, rowsMax,
    disabled, required,
  } = props;

  const classes = useStyles();

  const {
    register, watch, errors,
  } = useFormContext();

  const error = get(errors, name);

  const hasError = Boolean(error);
  const errorMessage = (hasError) ? error.message : '';

  const inputPropsFormatted = {
    classes: { disabled: classes.disabled },
  };

  const labelFormatted = (required)
    ? `${label} *`
    : label;

  return (
    <TextField
      name={name}
      inputRef={register}
      value={watch(name)}
      error={hasError}
      helperText={errorMessage}
      disabled={disabled}
      label={labelFormatted}
      fullWidth
      variant="outlined"
      multiline
      InputProps={inputPropsFormatted}
      rows={rows}
      rowsMax={rowsMax}
    />
  );
};

export default memo(TextArea);
