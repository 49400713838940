import React from 'react';
import CONFIG from 'config/config';
import useRequest from 'utils/http';
import fuel from 'utils/selectOptions/fuel';
import { useFormContext } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import {
  Grid, InputAdornment, IconButton,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import TextField from 'components/common/form/textField';
import SelectBox from 'components/common/form/selectBox';

export default (props) => {
  const { isLoading, setIsLoading } = props;
  const request = useRequest();
  const { watch, setValue } = useFormContext();
  const { enqueueSnackbar } = useSnackbar();
  const plate = watch('vehicle.plate');

  const handleFindPlateInternal = async () => {
    let returnData = null;
    try {
      if (plate.length < 7) {
        throw new Error('invalid format');
      }
      const { data: responseData } = await request({
        method: 'get',
        url: 'vehicles',
        params: { plate },
      });
      if (!responseData.data.length) {
        throw new Error('not found');
      }
      returnData = responseData.data;
      const vehicle = responseData.data[0];
      setValue('vehicle.brand', vehicle.brand, true);
      setValue('vehicle.model', vehicle.model, true);
      setValue('vehicle.manufactureYear', vehicle.manufactureYear, true);
      setValue('vehicle.releaseYear', vehicle.releaseYear, true);
      setValue('vehicle.chassi', vehicle.chassi, true);
      setValue('vehicle.color', vehicle.color, true);
      setValue('vehicle.renavam', vehicle.renavam, true);
      setValue('vehicle.fuel', vehicle.fuel, true);
      setValue('vehicle.optionals', vehicle.optionals, true);
    } catch (e) {
      enqueueSnackbar('Erro ao buscar veículo internamente', { variant: 'warning' });
    }
    return returnData;
  };

  const handleFindPlateSinesp = async () => {
    try {
      enqueueSnackbar('Buscando na base de dados externa', { variant: 'info' });
      if (plate.length < 7) {
        throw new Error('invalid format');
      }
      const { data: responseData } = await request({
        method: 'get',
        url: `${CONFIG.PLATE_SERVICE_URL}${plate}/json`,
        params: { plate },
      });

      if (responseData.codigoRetorno * 1 !== 0) {
        throw new Error('vehicle not found');
      }

      enqueueSnackbar('Veículo encontrado na base de dados externa', { variant: 'success' });
      setValue('vehicle.brand', responseData.marca, true);
      setValue('vehicle.model', responseData.modelo, true);
      setValue('vehicle.manufactureYear', responseData.ano, true);
      setValue('vehicle.releaseYear', responseData.anoModelo, true);
      setValue('vehicle.chassi', responseData.chassi, true);
      setValue('vehicle.color', responseData.cor, true);
    } catch (e) {
      enqueueSnackbar('Erro ao buscar veículo na base de dados externa', { variant: 'warning' });
    }
  };

  const handleFindPlate = async () => {
    setIsLoading(true);
    const internalData = await handleFindPlateInternal();
    if (!internalData) {
      await handleFindPlateSinesp();
    }
    setIsLoading(false);
  };

  return (
    <>
      <Grid item md={4} xs={12}>
        <TextField
          name="vehicle.plate"
          label="Placa"
          disabled={isLoading}
          required
          mask="*******"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleFindPlate}
                  disabled={isLoading}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          name="vehicle.brand"
          label="Marca"
          disabled={isLoading}
          required
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          name="vehicle.model"
          label="Modelo"
          disabled={isLoading}
          required
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          name="vehicle.manufactureYear"
          label="Ano de Fabricação"
          mask="9999"
          type="tel"
          disabled={isLoading}
          required
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          name="vehicle.releaseYear"
          label="Ano do Modelo"
          mask="9999"
          type="tel"
          disabled={isLoading}
          required
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          name="vehicle.chassi"
          label="Chassi"
          disabled={isLoading}
          mask="*****************"
          required
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          name="vehicle.color"
          label="Cor"
          disabled={isLoading}
          required
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          name="vehicle.renavam"
          label="Renavam"
          mask="99999999999"
          type="tel"
          disabled={isLoading}
          required
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <SelectBox
          name="vehicle.fuel"
          label="Combustível"
          disabled={isLoading}
          required
          options={fuel}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          name="vehicle.optionals"
          label="Opcionais"
          disabled={isLoading}
        />
      </Grid>
    </>
  );
};
