import Yup from 'utils/yup';

export default Yup.object().shape({
  intermediary: Yup.object().shape({
    name: Yup.string()
      .test('', 'Nome completo deve conter no mínimo 5 caracteres', (value) => (
        value.length === 0 || value.length > 5
      ))
      .min(5)
      .max(50),
    telephone: Yup.string()
      .label('Telefone')
      .telephone(),
  }),
});
