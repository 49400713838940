import { conformToMask } from 'react-text-mask';

export function currency(value = 0) {
  const format = { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' };
  return value.toLocaleString('pt-BR', format);
}

export function currencyToFloat(value) {
  if (typeof value === 'number') {
    return value;
  }
  return (value || '').toString()
    .replace(/ /g, '')
    .replace('R$', '')
    .replace(/\./g, '')
    .replace(',', '.') * 1;
}

export function formatMask(value) {
  if (!value) {
    return [];
  }

  const chars = value.split('');
  return chars.map((char) => {
    switch (char) {
      case '*':
        return /[A-Za-z0-9]/;
      case '9':
        return /[0-9]/;
      case 'a':
        return /[A-Za-z]/;
      default:
        return char;
    }
  });
}

export function compileMask(value, mask) {
  return conformToMask(value.toString(), formatMask(mask), {
    guide: false,
  }).conformedValue;
}

export function onlyNumbers(value = '') {
  return value
    .toString()
    .replace(/\D/g, '');
}

export function onlyAlphanumeric(value = '') {
  return value
    .toString()
    .replace(/\W/g, '');
}

export function cpfCnpj(value = '') {
  const onlyNumbersValue = onlyNumbers(value);
  const mask = (onlyNumbersValue.length === 11)
    ? '999.999.999-99'
    : '99.999.999/9999-99';
  return compileMask(onlyNumbersValue, mask);
}

export function cep(value = '') {
  const mask = '99999-999';
  return compileMask(value, mask);
}

export function telephone(value = '') {
  const onlyNumbersValue = onlyNumbers(value);
  const mask = (onlyNumbersValue.length <= 10)
    ? '(99)  9999-9999'
    : '(99) 9 9999-9999';
  return compileMask(onlyNumbersValue, mask);
}

export function telephoneToTelephoneAndPrefix(value = '') {
  const onlyNumbersValue = onlyNumbers(value);
  const telephonePrefix = onlyNumbersValue
    .toString()
    .slice(0, 2);

  const telephoneWithoutPrefix = onlyNumbersValue
    .toString()
    .slice(2);

  return { telephonePrefix, telephone: telephoneWithoutPrefix };
}

export function translateType(type) {
  switch (type) {
    case 'entry':
      return 'Entrada';
    case 'financing':
      return 'Financiamento';
    case 'accountReceivable':
      return 'Conta a Receber';
    case 'exchangeVehicle':
      return 'Veículo de Troca';
    default:
      return 'Entrada';
  }
}
