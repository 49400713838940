import React from 'react';

import { ListItem } from '@material-ui/core';

import Table from 'components/common/table';

import TextField from 'components/common/form/textField';

import VehicleSituationChip from 'components/common/vehicleSituation/chip';

const defaultValues = {
  inPreparation: true,
  inStock: true,
  pendingWarranty: true,
  sold: false,
};

export default (props) => {
  const {
    index, setIndex, setExchangeVehicle,
    payments, vehicleId,
  } = props;

  const rowClick = (event, rowData) => {
    setExchangeVehicle({
      id: rowData.id,
      model: rowData.model,
      index,
    });
    setIndex(-1);
  };

  const notId = payments
    .filter((payment) => (
      payment.type === 'exchangeVehicle'
      && payment.exchangeVehicle.id
    ))
    .map((payment) => payment.exchangeVehicle.id);

  notId.push(vehicleId);

  const beforeSubmit = (formData) => {
    const situation = ['inPreparation', 'inStock'];

    return {
      ...formData,
      situation,
      notId,
    };
  };

  return (
    <Table
      columns={[
        {
          title: 'Situação',
          field: 'situation',
          render: (rowData) => <VehicleSituationChip situation={rowData.situation} />,
        },
        { title: 'Placa', field: 'plate', defaultSort: 'desc' },
        { title: 'Modelo', field: 'model' },
        { title: 'Ano do Modelo', field: 'releaseYear', type: 'numeric' },
      ]}
      url="vehicles"
      onRowClick={rowClick}
      inputName="model"
      inputPlaceholder="Modelo"
      defaultValues={defaultValues}
      beforeSubmit={beforeSubmit}
      containerId="echangeVehicleContainer"
    >
      <ListItem>
        <TextField
          name="plate"
          label="Placa"
        />
      </ListItem>
      <ListItem>
        <TextField
          name="releaseYear"
          label="Ano do Modelo"
          mask="9999"
        />
      </ListItem>
    </Table>
  );
};
