import React, { useState, useEffect } from 'react';

import useRequest from 'utils/http';

import { scrollTo, Element } from 'components/common/scroll';

import { useForm, FormContext } from 'react-hook-form';

import { useSnackbar } from 'notistack';

import {
  Grid, Typography, LinearProgress,
  ListItem,
} from '@material-ui/core';

import { Pagination } from '@material-ui/lab';

import TextField from 'components/common/form/textField';
import SelectBox from 'components/common/form/selectBox';
import SearchForm from 'components/common/form/search';

import If from 'components/common/if';

import VehicleCard from './card';

const mockValues = {
  sortOptions: {
    model_asc: 'Modelo Ascendente',
    model_desc: 'Modelo Decrescente',
  },
};

export default () => {
  const { sortOptions } = mockValues;

  const methods = useForm({
    validateCriteriaMode: 'firstErrorDetected',
    mode: 'onBlur',
    defaultValues: {
      sort: 'model_asc',
    },
  });

  const { handleSubmit } = methods;

  const { enqueueSnackbar } = useSnackbar();

  const [params, setParams] = useState({
    orderBy: 'model',
    orderDirection: 'asc',
    page: 1,
    query: {},
    pageSize: 12,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [vehicles, setVehicles] = useState([]);

  const request = useRequest();

  useEffect(() => {
    scrollTo({
      className: 'posList',
      offset: 20,
      timeOut: 100,
    });

    const getData = async () => {
      try {
        setIsLoading(true);
        const { data: responseData } = await request({
          metod: 'get',
          url: 'vehicles',
          params: {
            ...params.query,
            situation: ['inStock'],
            sort: params.orderBy,
            order: params.orderDirection,
            skip: (params.page - 1) * params.pageSize,
            limit: params.pageSize,
          },
        });

        setVehicles(responseData.data);
        setTotal(responseData.total);
      } catch (e) {
        enqueueSnackbar('Erro ao buscar informações', { variant: 'error' });
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, [enqueueSnackbar, params, request]);

  const onSubmit = (formData) => {
    const formattedQuery = { ...formData };

    const filteredQuery = {};
    const fields = Object.keys(formattedQuery);

    fields.forEach((field) => {
      const fieldValue = (formattedQuery[field] && typeof formattedQuery[field] !== 'object')
        ? formattedQuery[field].toString()
        : formattedQuery[field];

      if (fieldValue) {
        filteredQuery[field] = fieldValue;
      }
    });

    setParams({ ...params, page: 1, query: filteredQuery });
  };

  const onChangeSort = (newValue) => {
    const [newOrderBy, newOrderDirection] = newValue.split('_');
    setParams({
      ...params,
      orderBy: newOrderBy,
      orderDirection: newOrderDirection,
    });
  };

  const onChangePage = (event, newPage) => {
    setParams({ ...params, page: newPage });
  };

  const totalFormatted = (total > 1)
    ? `${total} encontrados`
    : `${total} encontrado`;

  const pageCount = Math.ceil(total / params.pageSize);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Element className="posList">
          <SearchForm
            onSubmit={onSubmit}
            inputName="model"
            inputPlaceholder="Modelo"
          >
            <ListItem>
              <TextField
                name="plate"
                label="Placa"
              />
            </ListItem>
            <ListItem>
              <TextField
                name="releaseYear"
                label="Ano do Modelo"
                mask="9999"
              />
            </ListItem>
          </SearchForm>
        </Element>
      </Grid>
      <Grid item xs={12}>
        <FormContext {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2} justify="space-between" alignItems="center">
                  <Grid item md={4} xs={12}>
                    <SelectBox
                      name="sort"
                      label="Ordenar"
                      options={sortOptions}
                      onChange={onChangeSort}
                    />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <Typography variant="subtitle2" align="right" component="div">
                      {
                        (total < 1)
                          ? 'Nenhhum encontrado'
                          : totalFormatted
                      }
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <If test={!isLoading}>
                <Grid item xs={12}>
                  <VehicleCard vehicles={vehicles} />
                </Grid>
              </If>
            </Grid>
          </form>
        </FormContext>
      </Grid>
      <If test={isLoading}>
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      </If>
      <If test={!isLoading}>
        <Grid item xs={12}>
          <Grid container alignItems="center" justify="center">
            <Grid item>
              <Pagination
                count={pageCount}
                page={params.page || 1}
                color="primary"
                showFirstButton
                showLastButton
                onChange={onChangePage}
                disabled={pageCount < 2}
              />
            </Grid>
          </Grid>
        </Grid>
      </If>
    </Grid>
  );
};
