import React, { useState, useEffect } from 'react';

import useRequest from 'utils/http';

import { useHistory } from 'react-router-dom';

import { useSnackbar } from 'notistack';

import { Grid, LinearProgress } from '@material-ui/core';

import If from 'components/common/if';

import Form from './form';

export default () => {
  const history = useHistory();

  const request = useRequest();

  const { enqueueSnackbar } = useSnackbar();

  const [defaultValues, setDefaultValues] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: requestData } = await request({
          method: 'get',
          url: 'profile',
        });

        setDefaultValues({
          id: requestData.id,
          username: requestData.username,
          name: requestData.name,
          email: requestData.email,
          image: requestData.image,
          isActive: requestData.isActive,
          isAdmin: requestData.isAdmin,
          author: requestData.author,
        });
      } catch (e) {
        enqueueSnackbar('Perfil não encontrado', { variant: 'warning' });
        history.push('/');
      }
    };

    getData();
  }, [history, enqueueSnackbar, request]);

  return (
    <Grid container spacing={2}>
      <If test={!defaultValues}>
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      </If>
      <If test={defaultValues}>
        <Form
          defaultValues={defaultValues}
        />
      </If>
    </Grid>
  );
};
