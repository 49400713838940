import React from 'react';

import { useHistory } from 'react-router-dom';

import { ListItem } from '@material-ui/core';

import Table from 'components/common/table';

import TextField from 'components/common/form/textField';

const beforeSubmit = (formData) => {
  const situation = ['inPreparation', 'inStock'];

  const formattedData = {
    model: formData.model,
    plate: formData.plate,
    releaseYear: formData.releaseYear,
    situation,
  };

  return formattedData;
};

export default () => {
  const history = useHistory();

  return (
    <Table
      columns={[
        { title: 'Placa', field: 'plate', defaultSort: 'desc' },
        { title: 'Modelo', field: 'model' },
        { title: 'Ano do Modelo', field: 'releaseYear', type: 'numeric' },
      ]}
      url="vehicles"
      onRowClick={(event, rowData) => history.push(`/marketing/veiculos/${rowData.id}`)}
      inputName="model"
      inputPlaceholder="Modelo"
      beforeSubmit={beforeSubmit}
    >
      <ListItem>
        <TextField
          name="plate"
          label="Placa"
        />
      </ListItem>
      <ListItem>
        <TextField
          name="releaseYear"
          label="Ano do Modelo"
          mask="9999"
        />
      </ListItem>
    </Table>
  );
};
