import Yup from 'utils/yup';

import additionalInformationValidation from 'components/common/form/additionalInformation/validationSchema/purchase';
import vehicleValidation from 'components/common/form/vehicle/validationSchema';
import intermediaryValidation from 'components/common/form/intermediary/validationSchema';

const validationSchema = Yup.object().shape({
  salesman: Yup.object()
    .label('Vendedor')
    .required(),
  buyer: Yup.object()
    .test('', 'Comprador é um campo obrigatório', function validation(value) {
      return value
      || this.parent.defaultSituation === 'inPreparation'
      || this.parent.defaultSituation === 'inStock'
      || this.parent.defaultSituation === 'returned';
    }),
  situation: Yup.string()
    .label('Situação')
    .required()
    .test('', 'Não é possível alterar diretamente a situação para vendido', function validation(value) {
      return this.parent.defaultSituation === 'sold' || value !== 'sold';
    })
    .test('', 'Não é possível alterar diretamente a situação para garantia pendente', function validation(value) {
      return this.parent.defaultSituation === 'pendingWarranty'
        || this.parent.defaultSituation === 'sold'
        || (value !== 'sold' && value !== 'pendingWarranty');
    })
    .test('', 'Não é possível alterar a situação para em estoque sem um valor de venda', function validation(value) {
      return this.parent.saleValue > 0 || value !== 'inStock';
    }),
})
  .concat(vehicleValidation)
  .concat(intermediaryValidation)
  .concat(additionalInformationValidation);

export default validationSchema;
