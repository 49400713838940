import React from 'react';

import { FormContext } from 'react-hook-form';

import {
  Grid, Typography, Divider, LinearProgress,
  Button,
} from '@material-ui/core';

import If from 'components/common/if';

import TextArea from 'components/common/form/textArea';

import VehicleInfoDialog from 'components/common/vehicleInfoDialog';

import useHooks from './hooks';

import Images from './images';

export default () => {
  const { data, methods } = useHooks();

  const { handleSubmit } = methods.formMethods;

  return (
    <FormContext {...methods.formMethods}>
      <form onSubmit={handleSubmit(methods.onSubmit)}>
        <Grid container spacing={2} justify="space-between">
          <If test={!data.vehicle.id}>
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          </If>
          <If test={data.vehicle.id}>
            <Grid item md="auto" xs={12}>
              <Typography variant="subtitle2" component="h3">
                Placa
              </Typography>
              <Typography variant="subtitle1" component="p">
                {data.vehicle.plate}
              </Typography>
            </Grid>
            <Grid item md="auto" xs={12}>
              <Typography variant="subtitle2" component="h3">
                Modelo
              </Typography>
              <Typography variant="subtitle1" component="p">
                {data.vehicle.model}
              </Typography>
            </Grid>
            <Grid item md="auto" xs={12}>
              <Typography variant="subtitle2" component="h3">
                Ano do Modelo
              </Typography>
              <Typography variant="subtitle1" component="p">
                {data.vehicle.releaseYear}
              </Typography>
            </Grid>
          </If>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Images
              images={data.images}
              defaultImages={data.defaultImages}
              setImages={methods.setImages}
              setDefaultImages={methods.setDefaultImages}
              isLoading={data.isLoading}
            />
          </Grid>
          <If test={!data.vehicle.id}>
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          </If>
          <If test={data.vehicle.id}>
            <Grid item xs={12}>
              <TextArea
                name="description"
                label="Descrição"
                rows={4}
                rowsMax={6}
                disabled={data.isLoading}
              />
            </Grid>
          </If>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  disabled={data.isLoading}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Salvar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={data.isLoading}
                  type="button"
                  onClick={() => methods.setOpenPreview(true)}
                >
                  Pré-visualizar
                </Button>
              </Grid>
              {/* <Grid item>
                <Button
                  disabled={isLoading}
                  variant="contained"
                  color="secondary"
                  type="button"
                >
                  Publicar
                </Button>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
        <VehicleInfoDialog
          vehicle={data.vehiclePreview}
          open={data.openPreview}
          handleClose={() => methods.setOpenPreview(false)}
        />
      </form>
    </FormContext>
  );
};
