import React, { useState, useEffect } from 'react';

import { scrollTo, Element } from 'components/common/scroll';

import useRequest from 'utils/http';

import { currencyToFloat, onlyNumbers, telephoneToTelephoneAndPrefix } from 'utils/masks';

import { useSnackbar } from 'notistack';

import {
  makeStyles, Grid, Stepper, Step as MaterialStep,
  StepLabel, StepContent, LinearProgress, Typography,
} from '@material-ui/core';

import If from 'components/common/if';

import Salesman from './steps/salesman';
import Vehicle from './steps/vehicle';
import Intermediary from './steps/intermediary';
import Checklist from './steps/checklist';
import AdditionalInformation from './steps/additionalInformation';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  stepContainer: {
    padding: 0,
  },
  stepGrid: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(1),
  },
}));

const steps = [
  { key: 'salesman', label: 'Vendedor' },
  { key: 'intermediary', label: 'Intermediário' },
  { key: 'vehicle', label: 'Veículo' },
  { key: 'checklist', label: 'Checklist' },
  { key: 'additionalInformation', label: 'Informações Adicionais' },
];

export default (props) => {
  const {
    beforeSave, afterSave,
    defaultValues = {}, containerId,
  } = props;

  const request = useRequest();
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [sended, setSended] = useState(false);
  const [data, setData] = useState(defaultValues);

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Salesman
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            data={data}
            setData={setData}
            setActiveStep={setActiveStep}
            classes={classes}
          />
        );
      case 1:
        return (
          <Intermediary
            data={data}
            setData={setData}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            classes={classes}
          />
        );
      case 2:
        return (
          <Vehicle
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            data={data}
            setData={setData}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            classes={classes}
          />
        );
      case 3:
        return (
          <Checklist
            data={data}
            setData={setData}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            classes={classes}
          />
        );
      case 4:
        return (
          <AdditionalInformation
            data={data}
            setData={setData}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            classes={classes}
          />
        );
      default:
        return false;
    }
  };

  useEffect(() => {
    const sendData = async () => {
      setSended(true);

      let formattedData = { ...data };

      if (typeof beforeSave === 'function') {
        beforeSave(formattedData);
      }

      const telephones = (formattedData.customer.telephones || []).map((telephone) => {
        const {
          telephonePrefix: prefix,
          telephone: number,
        } = telephoneToTelephoneAndPrefix(telephone.value);

        return { prefix, number };
      });

      formattedData.salesman = {
        ...formattedData.customer,
        cpfCnpj: onlyNumbers(formattedData.customer.cpfCnpj),
        cep: onlyNumbers(formattedData.customer.cep),
        income: currencyToFloat(formattedData.customer.income),
        telephones,
      };

      const { intermediary } = formattedData;
      const intermediaryTelephone = telephoneToTelephoneAndPrefix(intermediary.telephone);

      formattedData.intermediary = {
        intermediaryName: (intermediary.name)
          ? intermediary.name : null,
        intermediaryTelephonePrefix: (intermediaryTelephone.telephonePrefix)
          ? intermediaryTelephone.telephonePrefix : null,
        intermediaryTelephone: (intermediaryTelephone.telephone)
          ? intermediaryTelephone.telephone : null,
      };

      formattedData.vehicle = {
        ...formattedData.vehicle,
        manufactureYear: formattedData.vehicle.manufactureYear * 1,
        releaseYear: formattedData.vehicle.releaseYear * 1,
        renavam: formattedData.vehicle.renavam,
      };

      formattedData = {
        salesman: formattedData.salesman,
        ...formattedData.vehicle,
        ...formattedData.checklist,
        ...formattedData.intermediary,
        ...formattedData.additionalInformation,
      };

      try {
        const { data: responseData } = await request({
          method: 'post',
          url: 'purchases',
          data: formattedData,
        });

        enqueueSnackbar('Compra salva com sucesso', { variant: 'success' });

        if (typeof afterSave === 'function') {
          afterSave(responseData);
        }
      } catch (e) {
        setSended(false);
        enqueueSnackbar('Não foi possível salvar a compra', { variant: 'error' });
        setActiveStep(steps.length - 1);
      }
    };

    if (activeStep >= steps.length && !sended) {
      sendData();
    }

    if (!sended) {
      scrollTo({
        className: `purchase[${activeStep}]`,
        timeout: 500,
        offset: 20,
        containerId,
      });
    }
  }, [
    activeStep, sended, containerId, request,
    enqueueSnackbar, beforeSave, afterSave, data,
  ]);

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical" className={classes.stepContainer}>
        {steps.map(({ label }, index) => (
          <MaterialStep key={label}>
            <StepLabel>
              <Element className={`purchase[${index}]`}>
                {label}
              </Element>
            </StepLabel>
            <StepContent>
              <Grid container spacing={2} className={classes.stepGrid}>
                <Grid item xs={12}>
                  {renderStepContent()}
                </Grid>
              </Grid>
            </StepContent>
          </MaterialStep>
        ))}
      </Stepper>
      <If test={activeStep >= steps.length}>
        <Grid container spacing={1} className={classes.stepGrid}>
          <Grid item xs={12}>
            <Element className="purchase[5]">
              <Typography variant="body2" component="div">
                Enviando Informações...
              </Typography>
            </Element>
          </Grid>
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        </Grid>
      </If>
    </div>
  );
};
