import React, { useState, useRef } from 'react';

import useRequest from 'utils/http';

import { telephoneToTelephoneAndPrefix } from 'utils/masks';

import { useForm, FormContext } from 'react-hook-form';

import { useSnackbar } from 'notistack';

import {
  makeStyles, Grid, Typography,
  Button, RootRef,
} from '@material-ui/core';

import { Link } from 'react-router-dom';

import situations from 'utils/selectOptions/situations';

import SelectBox from 'components/common/form/selectBox';

import VehicleForm from 'components/common/form/vehicle';
import IntermediaryForm from 'components/common/form/intermediary';
import ChecklistForm from 'components/common/form/checklist';
import AdditionalInformationForm from 'components/common/form/additionalInformation';

import VehicleSituationChip from 'components/common/vehicleSituation/chip';
import PossessionDaysChip from 'components/common/possessionDaysChip';

import If from 'components/common/if';

import ConfirmDialog from './confirmDialog';
import ReturnedDateDialog from './returnedDateDialog';

import validationSchema from './validationSchema';

import Customers from './customers';
import CustomersDialog from './customers/dialog';

let confirm = false;
let confirmReturnedDate = false;

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

export default (props) => {
  const {
    id, refresh,
    isLoading, setIsLoading,
    defaultValues,
  } = props;

  const request = useRequest();
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const [showDialog, setShowDialog] = useState(false);
  const [dialogType, setDialogType] = useState(null);
  const [showReturnedDateDialog, setShowReturnedDateDialog] = useState(false);
  const [showCustomersDialog, setShowCustomersDialog] = useState(false);
  const [customerDialogType, setCustomerDialogType] = useState(null);
  const submitButtonRef = useRef();

  const methods = useForm({
    validationSchema,
    defaultValues: {
      ...defaultValues,
      defaultSituation: defaultValues.situation,
    },
    validateCriteriaMode: 'firstErrorDetected',
    mode: 'onBlur',
  });

  const { handleSubmit, register, setValue } = methods;

  const handleShowDialog = (actualSituation, newSituation) => {
    if (actualSituation === 'sold' || actualSituation === 'pendingWarranty') {
      if (actualSituation === 'sold' && newSituation === 'pendingWarranty') {
        setDialogType('warranty');
        setShowDialog(true);
        return true;
      }

      if (
        newSituation === 'inPreparation'
        || newSituation === 'inStock'
        || newSituation === 'returned'
      ) {
        if (actualSituation === 'pendingWarranty') {
          setDialogType('sale');
        }

        if (actualSituation === 'sold') {
          setDialogType(null);
        }

        setShowDialog(true);

        return true;
      }
    }
    return false;
  };

  const handleShowReturnedDateDialog = (actualSituation, newSituation) => {
    if (actualSituation !== 'returned' && newSituation === 'returned') {
      setShowReturnedDateDialog(true);
      return true;
    }
    return false;
  };

  const onSubmit = async (formData) => {
    if (confirm === false && handleShowDialog(
      defaultValues.situation, formData.situation,
    )) {
      return;
    }

    if (confirmReturnedDate === false && handleShowReturnedDateDialog(
      defaultValues.situation, formData.situation,
    )) {
      return;
    }

    confirm = false;
    confirmReturnedDate = false;
    setIsLoading(true);

    let formattedData = { ...formData };

    const { intermediary } = formattedData;
    const intermediaryTelephone = telephoneToTelephoneAndPrefix(intermediary.telephone);

    formattedData.intermediary = {
      intermediaryName: (intermediary.name)
        ? intermediary.name : null,
      intermediaryTelephonePrefix: (intermediaryTelephone.telephonePrefix)
        ? intermediaryTelephone.telephonePrefix : null,
      intermediaryTelephone: (intermediaryTelephone.telephone)
        ? intermediaryTelephone.telephone : null,
    };

    formattedData = {
      returnedDate: formattedData.returnedDate,
      ...formattedData.vehicle,
      ...formattedData.intermediary,
      ...formattedData.checklist,
      ...formattedData.additionalInformation,
      situation: formattedData.situation,
      salesman: formattedData.salesman.id,
    };

    if (formattedData.buyer) {
      formattedData.buyer = formattedData.buyer.id;
    }

    if (!formattedData.returnedDate) {
      delete formattedData.returnedDate;
    }

    try {
      await request({
        url: `vehicles/${id}`,
        method: 'patch',
        data: formattedData,
      });
      enqueueSnackbar('Veículo salvo com sucesso', { variant: 'success' });
      refresh();
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar('Erro ao salvar veículo', { variant: 'error' });
    }
  };

  const onConfirmReturnedDate = () => {
    confirmReturnedDate = true;
    setShowReturnedDateDialog(false);
    submitButtonRef.current.click();
  };

  const onConfirm = () => {
    confirm = true;
    setShowDialog(false);
    submitButtonRef.current.click();
  };

  const handleCloseReturnedDialog = () => {
    confirm = false;
    setShowReturnedDateDialog(false);
  };

  const handleFindCustomer = (type) => {
    setShowCustomersDialog(true);
    setCustomerDialogType(type);
  };

  const onCustomersRowClick = (event, rowData) => {
    if (customerDialogType === 'salesman') {
      setValue('salesman.id', rowData.id);
      setValue('salesman.name', rowData.name);
    } else {
      setValue('buyer.id', rowData.id);
      setValue('buyer.name', rowData.name);
    }
    setShowCustomersDialog(false);
    setCustomerDialogType(null);
  };

  return (
    <>
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container justify="space-between">
                <Grid item>
                  <Typography variant="h5" component="h3">Veículo</Typography>
                </Grid>
                <Grid item>
                  <PossessionDaysChip
                    className={classes.button}
                    months={defaultValues.additionalInformation.possessionDays}
                  />
                  <VehicleSituationChip situation={defaultValues.situation} />
                </Grid>
              </Grid>
            </Grid>
            <VehicleForm
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
            <Grid item xs={12}>
              <Typography variant="h5" component="h3">Clientes</Typography>
            </Grid>
            <Grid item xs={12}>
              <Customers
                isLoading={isLoading}
                handleFindCustomer={handleFindCustomer}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" component="h3">Intermediário</Typography>
            </Grid>
            <IntermediaryForm isLoading={isLoading} />
            <Grid item xs={12}>
              <Typography variant="h5" component="h3">Checklist</Typography>
            </Grid>
            <ChecklistForm
              isLoading={isLoading}
              purchase={defaultValues.situation !== 'sold'}
            />
            <Grid item xs={12}>
              <Typography variant="h5" component="h3">Informações Adicionais</Typography>
            </Grid>
            <AdditionalInformationForm
              isLoading={isLoading}
              purchase={defaultValues.situation !== 'sold'}
              sale={defaultValues.situation === 'pendingWarranty'}
              returned={defaultValues.situation === 'returned'}
            />
            <Grid item xs={12}>
              <SelectBox
                label="Situação"
                name="situation"
                disabled={isLoading}
                options={situations}
              />
              <input type="hidden" name="defaultSituation" ref={register} />
              <input type="hidden" name="saleValue" ref={register} />
              <input type="hidden" name="returnedDate" ref={register} />
            </Grid>
            <Grid item xs={12}>
              <RootRef rootRef={submitButtonRef}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isLoading}
                  className={classes.button}
                >
                  Salvar
                </Button>
              </RootRef>
              <If test={defaultValues.situation === 'inStock'}>
                <Button
                  color="secondary"
                  variant="contained"
                  component={Link}
                  to={`/comercial/ponto-de-venda/${id}`}
                  className={classes.button}
                  disabled={isLoading}
                >
                  Vender Veículo
                </Button>
              </If>
            </Grid>
          </Grid>
          <ConfirmDialog
            defaultValues={defaultValues}
            open={showDialog}
            handleClose={() => setShowDialog(false)}
            onConfirm={onConfirm}
            isLoading={isLoading}
            type={dialogType}
          />
          <ReturnedDateDialog
            open={showReturnedDateDialog}
            handleClose={handleCloseReturnedDialog}
            isLoading={isLoading}
            onConfirm={onConfirmReturnedDate}
          />
        </form>
      </FormContext>
      <CustomersDialog
        open={showCustomersDialog}
        onRowClick={onCustomersRowClick}
        onClose={() => setShowCustomersDialog(false)}
      />
    </>
  );
};
