import axios from 'axios';

import { useDispatch } from 'react-redux';

import CONFIG from 'config/config';

import { logout, getToken } from 'main/actions/user';

let dispatch = null;

const request = async (params) => {
  try {
    const url = (params.url.indexOf('http://') > -1 || params.url.indexOf('https://') > -1)
      ? params.url : CONFIG.API_URL + params.url;

    const response = await axios({
      ...params,
      url,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        ...params.headers,
      },
    });

    return response;
  } catch (e) {
    if (e.response && e.response.status === 401) {
      dispatch(logout());
    }
    throw e;
  }
};

export default () => {
  dispatch = useDispatch();
  return request;
};

export const privateFileUrl = (url) => `${url}?token=${getToken()}`;
