import { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { changeDrawer, changeDrawerNotifications } from 'main/actions/page';
import { logout as logoutAction } from 'main/actions/user';

export default () => {
  const [menuEl, setMenuEl] = useState(null);
  const menuOpen = Boolean(menuEl);

  const title = useSelector((state) => state.page.title);
  const user = useSelector((state) => state.user);
  const notifications = useSelector((state) => state.user.notifications);
  const dispatch = useDispatch();

  const handleOpenMenu = (event) => {
    setMenuEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuEl(null);
  };

  const logout = () => {
    handleCloseMenu();
    dispatch(logoutAction());
  };

  const openDrawer = () => {
    dispatch(changeDrawer(true));
  };

  const openDrawerNotifications = () => {
    dispatch(changeDrawerNotifications(true));
  };

  return {
    title,
    user,
    openDrawer,
    menuEl,
    menuOpen,
    notifications,
    openDrawerNotifications,
    handleOpenMenu,
    handleCloseMenu,
    logout,
  };
};
