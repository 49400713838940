export function changeDrawer(hasDrawerOpen) {
  return { type: 'CHANGE_DRAWER', payload: hasDrawerOpen };
}

export function changeDrawerItems(drawerItemsOpen) {
  return { type: 'CHANGE_DRAWER_ITEMS_OPEN', payload: drawerItemsOpen };
}

export function changeTitle(title) {
  return { type: 'CHANGE_TITLE', payload: title };
}

export function changeRedirectAfterLogin(path) {
  return { type: 'SET_REDIRECT_AFTER_LOGIN', payload: path };
}

export function changeDrawerNotifications(hasDrawerOpen) {
  return { type: 'CHANGE_DRAWER_NOTIFICATIONS', payload: hasDrawerOpen };
}
