import { useFormContext } from 'react-hook-form';

import dot from 'dot-object';

import { useTheme } from '@material-ui/core';

export default (props) => {
  const {
    isLoading: isLoadingParent,
    defaultValues,
    handleFindCustomer: handleFindCustomerProps,
  } = props;

  const theme = useTheme();

  const { getValues, register } = useFormContext();

  const { situation } = defaultValues;

  const { salesman, buyer } = dot.object({ ...getValues() });

  const hasDisabled = situation !== 'pendingWarranty' && situation !== 'sold';

  const data = {
    salesman,
    buyer: buyer || {},
    theme,
    isLoadingParent,
    hasDisabled,
  };

  const methods = {
    register,
    handleFindCustomer: (type, id) => {
      if (typeof handleFindCustomerProps === 'function') {
        handleFindCustomerProps(type, id);
      }
    },
  };

  return { data, methods };
};
