import React, { useState } from 'react';

import { useForm, FormContext } from 'react-hook-form';

import { Link as RouterLink } from 'react-router-dom';

import {
  makeStyles, Button,
  Grid, LinearProgress, Link,
} from '@material-ui/core';

import TextField from 'components/common/form/textField';

import If from 'components/common/if';

import HomeScreen from 'components/common/homeScreen';

const useStyles = makeStyles(() => ({
  gridButton: {
    height: '40px',
  },
}));

export default () => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm({
    defaultValues: {},
    validateCriteriaMode: 'firstErrorDetected',
    mode: 'onBlur',
  });

  const { handleSubmit } = methods;

  const onSubmit = async (formData) => {
    setIsLoading(true);
    console.log(formData);
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <HomeScreen title="Recuperar Senha">
          <Grid item xs={12}>
            <TextField
              label="Email"
              name="email"
              disabled={isLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              alignItems="center"
              className={classes.gridButton}
            >
              <If test={!isLoading}>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={isLoading}
                  >
                    Recuperar Senha
                  </Button>
                </Grid>
              </If>
              <If test={isLoading}>
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              </If>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Link to="/login" component={RouterLink} variant="body2">
              Login
            </Link>
          </Grid>
        </HomeScreen>
      </form>
    </FormContext>
  );
};
