import React from 'react';

import { privateFileUrl } from 'utils/http';

import { useHistory } from 'react-router-dom';

import {
  ListItem, Typography,
} from '@material-ui/core';

import Table from 'components/common/table';

import Checkbox from 'components/common/form/checkBox';

import Avatar from 'components/common/avatar';

const defaultValues = {
  isActive: true,
};

const beforeSubmit = (formData) => {
  const isActive = [];

  if (formData.isActive) {
    isActive.push(true);
  }

  if (formData.isInactive) {
    isActive.push(false);
  }

  if (!formData.isActive && !formData.isInactive) {
    isActive.push(true);
    isActive.push(false);
  }

  return {
    name: formData.name,
    isActive,
  };
};

const SearchForm = () => (
  <>
    <ListItem>
      <Typography variant="subtitle1" component="div">
        Estado
      </Typography>
    </ListItem>
    <ListItem>
      <Checkbox
        name="isActive"
        label="Ativo"
      />
    </ListItem>
    <ListItem>
      <Checkbox
        name="isInactive"
        label="Inativo"
      />
    </ListItem>
  </>
);

export default () => {
  const history = useHistory();

  return (
    <Table
      columns={[
        {
          title: '',
          field: 'image',
          sorting: false,
          render: (rowData) => {
            const color = (rowData.isActive)
              ? 'success'
              : undefined;

            return (
              <Avatar
                src={privateFileUrl(rowData.image)}
                color={color}
              />
            );
          },
        },
        {
          title: 'Nome',
          field: 'name',
          defaultSort: 'asc',
        },
      ]}
      url="users"
      onRowClick={(event, rowData) => history.push(`/administrativo/usuarios/${rowData.id}`)}
      inputName="name"
      beforeSubmit={beforeSubmit}
      inputPlaceholder="Nome"
      defaultValues={defaultValues}
      addTo="/administrativo/usuarios/adicionar"
    >
      <SearchForm />
    </Table>
  );
};
