import { useState, useRef } from 'react';

import CONFIG from 'config/config';

import useRequest from 'utils/http';

import { useSnackbar } from 'notistack';

export default (props) => {
  const {
    images,
    isLoading: isLoadingProps,
    setIsLoading: setIsLoadingProps,
    afterUpload,
    remove: removeProps,
    up: upProps,
    down: downProps,
    privateUrl,
  } = props;

  const request = useRequest();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogUrl, setDialogUrl] = useState('');

  const fileRef = useRef(null);

  const data = {
    isLoading: isLoading || isLoadingProps,
    openDialog,
    dialogUrl,
    images,
    isLoadingProps,
    fileRef,
  };

  const methods = {
    add: () => {
      fileRef.current.click();
    },
    send: async () => {
      try {
        const [file] = fileRef.current.files;

        const formData = new FormData();
        formData.append('file', file);

        setIsLoading(true);

        if (typeof setIsLoadingProps === 'function') {
          setIsLoadingProps(true);
        }

        const url = (privateUrl)
          ? `${CONFIG.BUCKET_SERVICE_URL}private`
          : CONFIG.BUCKET_SERVICE_URL;

        const { data: responseData } = await request({
          url,
          method: 'post',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (typeof afterUpload === 'function') {
          afterUpload(responseData.url);
        }

        fileRef.current.value = '';
      } catch (e) {
        enqueueSnackbar('Não foi possível salvar a imagem', { variant: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
    up: (image, index) => {
      if (typeof upProps === 'function') {
        upProps(image, index);
      }
    },
    down: (image, index) => {
      if (typeof downProps === 'function') {
        downProps(image, index);
      }
    },
    remove: (image, index) => {
      if (typeof removeProps === 'function') {
        removeProps(image, index);
      }
    },
    handleOpenDialog: (url) => {
      setOpenDialog(true);
      setDialogUrl(url);
    },
    handleCloseDialog: () => {
      setOpenDialog(false);
      setDialogUrl('');
    },
  };

  return { data, methods };
};
