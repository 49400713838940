import Login from 'components/pages/login';
import RecoverPassword from 'components/pages/recoverPassword';
import Profile from 'components/pages/profile';

export default [
  {
    title: 'Login',
    path: '/login',
    type: 'public',
    component: Login,
    exact: true,
  },
  {
    title: 'Recuperar Senha',
    path: '/recuperar-senha',
    type: 'public',
    component: RecoverPassword,
    exact: true,
  },
  {
    title: 'Início',
    path: '/',
    type: 'private',
    redirect: '/comercial/ponto-de-venda',
    exact: true,
  },
  {
    title: 'Perfil',
    path: '/perfil',
    type: 'private',
    component: Profile,
    exact: true,
  },
];
