export function containRoute(actual, compare, exact = false) {
  const paramPosition = compare.indexOf(':');

  if (exact && paramPosition !== -1) {
    const compareWithoutParam = compare.slice(0, paramPosition);
    return actual.includes(compareWithoutParam) && actual.indexOf('/', paramPosition) === -1;
  }

  if (exact) {
    return actual === compare;
  }

  const actualString = actual.toString();
  const compareString = compare.toString();
  return actualString.includes(compareString);
}

export function cpf(value = '') {
  const onlyCpf = value.replace(/[^\d]+/g, '');
  if (!onlyCpf) {
    return false;
  }

  if (onlyCpf.length !== 11
    || onlyCpf === '00000000000'
    || onlyCpf === '11111111111'
    || onlyCpf === '22222222222'
    || onlyCpf === '33333333333'
    || onlyCpf === '44444444444'
    || onlyCpf === '55555555555'
    || onlyCpf === '66666666666'
    || onlyCpf === '77777777777'
    || onlyCpf === '88888888888'
    || onlyCpf === '99999999999') {
    return false;
  }

  let add = 0;

  for (let i = 0; i < 9; i += 1) {
    add += parseInt(onlyCpf.charAt(i), 10) * (10 - i);
  }

  let rev = 11 - (add % 11);

  if (rev === 10 || rev === 11) {
    rev = 0;
  }

  if (rev !== parseInt(onlyCpf.charAt(9), 10)) {
    return false;
  }
  add = 0;
  for (let i = 0; i < 10; i += 1) {
    add += parseInt(onlyCpf.charAt(i), 10) * (11 - i);
  }

  rev = 11 - (add % 11);

  if (rev === 10 || rev === 11) {
    rev = 0;
  }

  if (rev !== parseInt(onlyCpf.charAt(10), 10)) {
    return false;
  }

  return true;
}

export function cnpj(value = '') {
  const onlyCnpj = value.replace(/[^\d]+/g, '');

  if (onlyCnpj.length !== 14) {
    return false;
  }

  if (/^(\d)\1+$/.test(onlyCnpj)) {
    return false;
  }

  const t = onlyCnpj.length - 2;
  const d = onlyCnpj.substring(t);
  const d1 = parseInt(d.charAt(0), 10);
  const d2 = parseInt(d.charAt(1), 10);

  const calc = (x) => {
    const n = onlyCnpj.substring(0, x);
    let y = x - 7;
    let s = 0;
    let r = 0;

    for (let i = x; i >= 1; i -= 1) {
      s += n.charAt(x - i) * y;
      y -= 1;
      if (y < 2) {
        y = 9;
      }
    }

    r = 11 - (s % 11);
    return r > 9 ? 0 : r;
  };

  return calc(t) === d1 && calc(t + 1) === d2;
}
