import React, { useRef, useEffect, memo } from 'react';

import { get } from 'lodash';

import { useFormContext, Controller } from 'react-hook-form';

import {
  makeStyles, TextField, InputAdornment,
} from '@material-ui/core';

import CurrencyFormat from './currencyFormat';

const useStyles = makeStyles((theme) => ({
  disabled: theme.disabledField,
}));

const CurrencyTextField = (props) => {
  const {
    name, label, InputProps,
    disabled, required,
    maxLength, autoFocus,
    defaultValue,
  } = props;

  const classes = useStyles();

  const textFieldRef = useRef();

  const {
    errors, control, formState,
  } = useFormContext();

  const error = get(errors, name);
  const hasError = Boolean(error);
  const errorMessage = (hasError) ? error.message : '';

  useEffect(() => {
    if (hasError) {
      const names = name.split('.');
      delete names[names.length - 1];
      const findName = names.join('.').slice(0, -1);
      const filteredError = (findName) ? get(errors, findName || '') : errors;
      const errorsKeys = Object.keys(filteredError);
      if (
        `${findName}.${errorsKeys[0]}` === name
        || errorsKeys[0] === name
      ) {
        textFieldRef.current.focus();
      }
    }
  }, [formState.isSubmitting, hasError, name, errors]);

  const inputPropsFormatted = {
    classes: { disabled: classes.disabled },
    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
    ...InputProps,
  };

  const labelFormatted = (required)
    ? `${label} *`
    : label;

  const onFocus = (event) => {
    const { target } = event;
    if (event.target.value === '0,00') {
      target.selectionStart = 1;
      target.selectionEnd = 1;
    } else {
      target.selectionStart = 0;
      target.selectionEnd = 0;
    }
  };

  return (
    <Controller
      as={(
        <CurrencyFormat
          customInput={TextField}
          inputRef={textFieldRef}
          onFocus={onFocus}
          decimalSeparator=","
          thousandSeparator="."
          fixedDecimalScale
          decimalScale={2}
          allowNegative={false}
          variant="outlined"
          type="tel"
          autoFocus={autoFocus}
          fullWidth
          label={labelFormatted}
          error={hasError}
          helperText={errorMessage}
          disabled={disabled}
          InputProps={inputPropsFormatted}
          inputProps={{
            maxLength,
            style: { textAlign: 'right' },
          }}
        />
      )}
      defaultValue={defaultValue}
      name={name}
      control={control}
    />
  );
};

export default memo(CurrencyTextField);
