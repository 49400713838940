import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    marginBottom: theme.spacing(2),
  },
  title: {
    flex: 1,
  },
}));
