import React from 'react';

import { useFormContext } from 'react-hook-form';

import { FormControlLabel, Checkbox } from '@material-ui/core';

export default (props) => {
  const {
    disabled, label, name, required,
  } = props;

  const {
    register, watch, setValue,
  } = useFormContext();

  const labelFormatted = (required)
    ? `${label} *`
    : label;

  return (
    <FormControlLabel
      control={(
        <Checkbox
          inputRef={register}
          name={name}
          checked={watch(name) || false}
          color="primary"
          onChange={() => setValue(name, watch(name), true)}
          disabled={disabled}
        />
      )}
      label={labelFormatted}
    />
  );
};
