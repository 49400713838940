import React, { useState } from 'react';

import CONFIG from 'config/config';

import useRequest from 'utils/http';

import banks from 'utils/selectOptions/banks';

import fuels from 'utils/selectOptions/fuel';

import { useSnackbar } from 'notistack';

import {
  Button, Grid, Menu, MenuItem,
} from '@material-ui/core';

import {
  Print as PrintIcon,
  PlaylistAddCheck as PlaylistAddCheckIcon,
} from '@material-ui/icons';

import If from 'components/common/if';

import GenerateWarranty from './generateWarranty/index';

const MenuButton = (props) => {
  const {
    isLoading, setIsLoading, docName,
    generateDoc, children,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        onClick={openMenu}
        startIcon={<PrintIcon />}
        fullWidth
        disabled={isLoading}
      >
        {children}
      </Button>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            closeMenu();
            generateDoc(docName, setIsLoading, { only: 'front' });
          }}
        >
          Frente
        </MenuItem>
        <MenuItem
          onClick={() => {
            closeMenu();
            generateDoc(docName, setIsLoading, { only: 'back' });
          }}
        >
          Verso
        </MenuItem>
        <MenuItem
          onClick={() => {
            closeMenu();
            generateDoc(docName, setIsLoading);
          }}
        >
          Ambos
        </MenuItem>
      </Menu>
    </>
  );
};

export default (props) => {
  const {
    defaultValues, id,
  } = props;

  const request = useRequest();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoadingResume, setIsLoadingResume] = useState(false);
  const [isLoadingSalesAgreement, setIsLoadingSalesAgreement] = useState(false);
  const [isLoadingWarranty, setIsLoadingWarranty] = useState(false);

  const [openWarrantyDialog, setOpenWarrantyDialog] = useState(false);

  const generateDoc = async (doc, setIsLoading, aditionalInput) => {
    try {
      setIsLoading(true);

      const formattedData = {
        salesman: { ...defaultValues.salesman },
        intermediary: { ...defaultValues.intermediary },
        vehicle: { ...defaultValues.vehicle },
        buyer: { ...defaultValues.buyer },
        checklist: { ...defaultValues.checklist },
        payments: [...defaultValues.payments],
        ...defaultValues.additionalInformation,
        saleValue: defaultValues.saleValue,
        ...aditionalInput,
      };

      if (doc === 'resume') {
        formattedData.observation = defaultValues.additionalInformation.purchaseObservation;
        if (formattedData.deliveryDate) {
          formattedData.exitDate = formattedData.deliveryDate;
        }
      }

      if (doc === 'sales-agreement') {
        formattedData.observation = defaultValues.additionalInformation.saleObservation;

        formattedData.payments = formattedData.payments.map((payment) => {
          const formattedPayment = { ...payment };
          if (payment.type === 'financing') {
            formattedPayment.bank = banks[formattedPayment.bank];
          }

          if (payment.type === 'exchangeVehicle') {
            formattedPayment.exchangeVehicle = {
              ...formattedPayment.exchangeVehicle,
              fuel: fuels[formattedPayment.exchangeVehicle.fuel],
            };
          }

          return formattedPayment;
        });
      }

      if (formattedData.salesman.telephones) {
        formattedData.salesman.telephone = formattedData.salesman.telephones.map((telephone) => (
          telephone.value
        )).join(', ');
      }

      if (formattedData.salesman.emails) {
        formattedData.salesman.email = formattedData.salesman.emails.map((email) => (
          email.value
        )).join(', ');
      }

      if (formattedData.buyer.telephones) {
        formattedData.buyer.telephone = formattedData.buyer.telephones.map((telephone) => (
          telephone.value
        )).join(', ');
      }

      if (formattedData.buyer.emails) {
        formattedData.buyer.email = formattedData.buyer.emails.map((email) => (
          email.value
        )).join(', ');
      }

      formattedData.vehicle.fuel = fuels[defaultValues.vehicle.fuel];

      delete formattedData.purchaseObservation;
      delete formattedData.saleObservation;

      const { data: responseData } = await request({
        method: 'post',
        url: `${CONFIG.DOCS_SERVICE_URL}${doc}`,
        data: formattedData,
        responseType: 'blob',
      });
      const file = new Blob(
        [responseData],
        { type: 'application/pdf' },
      );
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank');
    } catch (e) {
      enqueueSnackbar('Error ao gerar documento', { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const hasExchangeVehicle = Boolean((defaultValues.payments || []).find((payment) => (
    payment.type === 'exchangeVehicle'
  )));

  return (
    <Grid container spacing={1}>
      <Grid item md="auto" xs={12}>
        <Button
          startIcon={<PrintIcon />}
          fullWidth
          disabled={isLoadingResume}
          onClick={() => generateDoc('resume', setIsLoadingResume)}
        >
          Resumo
        </Button>
      </Grid>
      <If
        test={
          defaultValues.situation === 'pendingWarranty'
          || defaultValues.situation === 'sold'
        }
      >
        <Grid item md="auto" xs={12}>
          <If test={!hasExchangeVehicle}>
            <Button
              startIcon={<PrintIcon />}
              fullWidth
              disabled={isLoadingSalesAgreement}
              onClick={() => generateDoc('sales-agreement', setIsLoadingSalesAgreement)}
            >
              Acordo de Venda
            </Button>
          </If>
          <If test={hasExchangeVehicle}>
            <MenuButton
              docName="sales-agreement"
              isLoading={isLoadingSalesAgreement}
              setIsLoading={setIsLoadingSalesAgreement}
              generateDoc={generateDoc}
            >
              Acordo de Venda
            </MenuButton>
          </If>
        </Grid>
      </If>
      <If test={defaultValues.situation === 'pendingWarranty'}>
        <Grid item md="auto" xs={12}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => setOpenWarrantyDialog(true)}
            startIcon={<PlaylistAddCheckIcon />}
          >
            Gerar Garantia
          </Button>
        </Grid>
        <GenerateWarranty
          id={id}
          open={openWarrantyDialog}
          setOpen={setOpenWarrantyDialog}
        />
      </If>
      <If test={defaultValues.situation === 'sold'}>
        <Grid item md="auto" xs={12}>
          <MenuButton
            docName="warranty"
            isLoading={isLoadingWarranty}
            setIsLoading={setIsLoadingWarranty}
            generateDoc={generateDoc}
          >
            Garantia
          </MenuButton>
        </Grid>
      </If>
    </Grid>
  );
};
