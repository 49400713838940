import React, { useState, useEffect } from 'react';

import { useHistory, useRouteMatch } from 'react-router-dom';

import useRequest from 'utils/http';

import {
  telephone as telephoneMask,
  cep as cepMask,
  cpfCnpj as cpfCnpjMask,
} from 'utils/masks';

import { convertDate } from 'utils/date';

import { useSnackbar } from 'notistack';

import {
  Grid, Typography,
  LinearProgress,
} from '@material-ui/core';

import If from 'components/common/if';

import Form from './form';
import PrintButtons from './printButtons';
import Extract from './extract';
import Exchanges from './exchanges';
import Files from './files';
// import Remove from './remove';

export default () => {
  const request = useRequest();
  const history = useHistory();
  const match = useRouteMatch();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(true);
  const [defaultValues, setDefaultValues] = useState({});

  const { id } = match.params;

  useEffect(() => {
    const getVehicleData = async () => {
      try {
        setIsLoading(true);
        const { data: vehicle } = await request({
          url: `vehicles/${id}`,
          method: 'get',
        });

        const { data: salesman } = await request({
          url: `customers/${vehicle.salesman}`,
          method: 'get',
        });

        salesman.cpfCnpj = cpfCnpjMask(salesman.cpfCnpj);
        salesman.cep = cepMask(salesman.cep);

        salesman.birth = convertDate(salesman.birth, 'YYYY-MM-DD', 'YYYY-MM-DD');

        salesman.telephones = salesman.telephones.map((telephone) => ({
          value: telephoneMask(`${telephone.prefix}${telephone.number}`),
        }));

        salesman.emails = salesman.emails.map((email) => ({
          value: email.email,
        }));

        let buyer = null;

        if (vehicle.buyer) {
          const { data: dataBuyer } = await request({
            url: `customers/${vehicle.buyer}`,
            method: 'get',
          });

          buyer = dataBuyer;

          buyer.cpfCnpj = cpfCnpjMask(buyer.cpfCnpj);
          buyer.cep = cepMask(buyer.cep);

          buyer.telephones = buyer.telephones.map((telephone) => ({
            value: telephoneMask(`${telephone.prefix}${telephone.number}`),
          }));

          buyer.birth = convertDate(buyer.birth, 'YYYY-MM-DD', 'YYYY-MM-DD');

          buyer.emails = buyer.emails.map((email) => ({
            value: email.email,
          }));
        }

        const intermediaryTelepone = `${vehicle.intermediaryTelephonePrefix}${vehicle.intermediaryTelephone}`;

        const entryDate = convertDate(vehicle.entryDate, 'YYYY-MM-DD', 'YYYY-MM-DD');

        const returnedDate = convertDate(vehicle.returnedDate, 'YYYY-MM-DD', 'YYYY-MM-DD');

        const dateOfSale = convertDate(vehicle.dateOfSale, 'YYYY-MM-DD', 'YYYY-MM-DD');

        const deliveryDate = convertDate(vehicle.deliveryDate, 'YYYY-MM-DD', 'YYYY-MM-DD');

        setDefaultValues({
          vehicle: {
            plate: vehicle.plate,
            brand: vehicle.brand,
            model: vehicle.model,
            manufactureYear: vehicle.manufactureYear,
            releaseYear: vehicle.releaseYear,
            chassi: vehicle.chassi,
            renavam: vehicle.renavam,
            fuel: vehicle.fuel,
            color: vehicle.color,
            optionals: vehicle.optionals,
          },
          intermediary: {
            name: vehicle.intermediaryName || '',
            telephone: telephoneMask(intermediaryTelepone) || '',
          },
          checklist: {
            requiredDut: vehicle.requiredDut,
            transferDut: vehicle.transferDut,
            ipva: vehicle.ipva,
            licensing: vehicle.licensing,
            copyKey: vehicle.copyKey,
            manual: vehicle.manual,
            entryNf: vehicle.entryNf,
            fireExtinguisher: vehicle.fireExtinguisher,
            monkey: vehicle.monkey,
            tireIron: vehicle.tireIron,
            triangle: vehicle.triangle,
            tires: vehicle.tires,
            reserveTire: vehicle.reserveTire,
            airConditioning: vehicle.airConditioning,
            electricalCommands: vehicle.electricalCommands,
          },
          additionalInformation: {
            entryDate,
            returnedDate,
            purchaseObservation: vehicle.purchaseObservation,
            saleObservation: vehicle.saleObservation,
            possessionDays: vehicle.possessionDays,
            dateOfSale,
            deliveryDate,
            deliveryKm: vehicle.deliveryKm,
          },
          salesman,
          buyer,
          payments: vehicle.payments,
          purchaseValue: vehicle.purchaseValue,
          saleValue: vehicle.saleValue,
          situation: vehicle.situation,
        });

        setIsLoading(false);
      } catch (e) {
        enqueueSnackbar('Veículo não encontrado', { variant: 'warning' });
        history.push('/operacional/veiculos');
      }
    };

    getVehicleData();
  }, [enqueueSnackbar, history, id, request]);

  const refresh = () => {
    history.push(match.url);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <If test={!Object.keys(defaultValues).length}>
          <LinearProgress />
        </If>
        <If test={Object.keys(defaultValues).length}>
          <Form
            id={id}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            defaultValues={defaultValues}
            refresh={refresh}
          />
        </If>
      </Grid>
      <If test={defaultValues.situation !== 'returned'}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h3">Documentos</Typography>
        </Grid>
        <Grid item md="auto" xs={12}>
          <If test={!Object.keys(defaultValues).length}>
            <LinearProgress />
          </If>
          <If test={Object.keys(defaultValues).length}>
            <PrintButtons
              id={id}
              defaultValues={defaultValues}
            />
          </If>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" component="h3">Extrato</Typography>
        </Grid>
        <Grid item xs={12}>
          <Extract
            id={id}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            defaultValues={defaultValues}
            refresh={refresh}
          />
        </Grid>
      </If>
      <Grid item xs={12}>
        <Typography variant="h5" component="h3">Trocas</Typography>
      </Grid>
      <Grid item xs={12}>
        <Exchanges />
      </Grid>
      <Grid item xs={12}>
        <Files />
      </Grid>
      {/* <Grid item xs={12}>
        <Remove />
      </Grid> */}
    </Grid>
  );
};
