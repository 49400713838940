import React from 'react';

import {
  Grid, InputAdornment, IconButton,
} from '@material-ui/core';

import { Search as SearchIcon } from '@material-ui/icons';

import Link from 'components/common/link';

import TextField from 'components/common/form/textField';

import useHooks from './hooks';

export default (props) => {
  const { data, methods } = useHooks(props);

  return (
    <Grid container spacing={2} alignContent="flex-end">
      <Grid item md={6} xs={12}>
        <TextField
          label="Vendedor"
          name="salesman.name"
          disabled
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => methods.handleFindCustomer('salesman', data.salesman)}
                  disabled={data.isLoading}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <input type="hidden" name="salesman.id" ref={methods.register()} />
        <Grid container justify="flex-end">
          <Link
            to={`/operacional/clientes/${data.salesman.id}`}
            style={{ marginTop: data.theme.spacing(1) }}
          >
            Mais informações
          </Link>
        </Grid>
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          label="Comprador"
          name="buyer.name"
          disabled
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => methods.handleFindCustomer('buyer', data.buyer)}
                  disabled={data.isLoading || !data.buyer.id}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <input type="hidden" name="buyer.id" ref={methods.register()} />
        <Grid container justify="flex-end">
          <Link
            to={`/operacional/clientes/${data.buyer.id}`}
            disabled={data.isLoadingParent || data.hasDisabled}
            style={{ marginTop: data.theme.spacing(1) }}
          >
            Mais informações
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};
