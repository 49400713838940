import { useState, useEffect } from 'react';

import arrayMove from 'array-move';

import { useRouteMatch } from 'react-router-dom';

import useRequest from 'utils/http';

import { useSnackbar } from 'notistack';

export default (props) => {
  const {
    images,
    defaultImages,
    setImages,
    setDefaultImages,
    isLoading: isLoadingProps,
  } = props;

  const request = useRequest();
  const match = useRouteMatch();
  const { enqueueSnackbar } = useSnackbar();

  const { id } = match.params;

  const [isLoading, setIsLoading] = useState(true);
  const [updated, setUpdated] = useState(true);

  const data = {
    isLoading: isLoading || isLoadingProps,
    updated,
    images,
  };

  const methods = {
    afterUpload: async (url) => {
      try {
        setIsLoading(true);

        const { data: responseData } = await request({
          method: 'post',
          url: 'vehicles/images',
          data: {
            url,
            vehicle: id,
          },
        });

        setImages([...images, responseData]);
        setDefaultImages([...defaultImages, responseData]);
        setIsLoading(false);
        enqueueSnackbar('Imagem salva com sucesso', { variant: 'success' });
      } catch (e) {
        enqueueSnackbar('Não foi possível salvar a imagem', { variant: 'error' });
      }
    },
    up: (image, index) => {
      if (index > 0) {
        const newImages = arrayMove(images, index, index - 1)
          .map((newImage, newIndex) => ({
            ...newImage,
            index: newIndex,
          }));
        setImages(newImages);
      }
    },
    down: (image, index) => {
      if (index < images.length - 1) {
        const newImages = arrayMove(images, index, index + 1)
          .map((newImage, newIndex) => ({
            ...newImage,
            index: newIndex,
          }));
        setImages(newImages);
      }
    },
    remove: async ({ id: imageId }) => {
      setImages(images.filter((image) => image.id !== imageId));
    },
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: responseData } = await request({
          method: 'get',
          url: 'vehicles/images',
          params: {
            vehicle: id,
          },
        });

        setImages(responseData.data);
        setDefaultImages(responseData.data);
      } catch (e) {
        enqueueSnackbar('Não foi possível carregar as imagens', { variant: 'warning' });
      } finally {
        setIsLoading(false);
      }
    };

    if (updated) {
      getData();
      setUpdated(false);
    }
  }, [updated, enqueueSnackbar, id, request, setImages, setDefaultImages]);

  return { data, methods };
};
