import React from 'react';

import { privateFileUrl } from 'utils/http';

import {
  Grid, Typography, Fab,
  List, ListItem,
  ListItemSecondaryAction, ListItemAvatar,
  IconButton, CircularProgress,
  Dialog, DialogContent, Card,
} from '@material-ui/core';

import {
  AddAPhoto as AddAPhotoIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';

import Image from 'material-ui-image';

import If from 'components/common/if';

import useHooks from './hooks';
import useStyles from './styles';

export default (props) => {
  const { data, methods } = useHooks(props);

  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2} justify="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h5" component="h3">
            Imagens
          </Typography>
        </Grid>
        <Grid item>
          <div className={classes.wrapper}>
            <Fab
              size="medium"
              color="primary"
              onClick={methods.add}
              disabled={data.isLoading}
            >
              <AddAPhotoIcon />
            </Fab>
            <If test={data.isLoading}>
              <CircularProgress className={classes.fabProgress} size={52} />
            </If>
          </div>
        </Grid>
        <Grid item xs={12}>
          <If test={!data.isLoading && data.images.length}>
            <List>
              {data.images.map((image, index) => {
                const { id, url } = image;

                return (
                  <ListItem
                    key={id}
                    button
                    onClick={() => methods.handleOpenDialog(url)}
                    divider
                    disabled={data.isLoading}
                    className={classes.listItem}
                  >
                    <ListItemAvatar className={classes.listItemImage}>
                      <Image src={(data.privateUrl) ? privateFileUrl(url) : url} />
                    </ListItemAvatar>
                    <ListItemSecondaryAction>
                      <IconButton
                        disabled={data.isLoading}
                        onClick={() => methods.up(image, index)}
                      >
                        <ExpandLessIcon />
                      </IconButton>
                      <IconButton
                        disabled={data.isLoading}
                        onClick={() => methods.down(image, index)}
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => methods.remove(image, index)}
                        disabled={data.isLoading}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </If>
          <If test={!data.isLoading && !data.images.length}>
            <Typography
              variant="body1"
              component="span"
            >
              Nenhuma imagem encontrada.
            </Typography>
          </If>
          <input
            id="uploadImage"
            accept="image/png,image/jpg,image/jpeg"
            style={{ display: 'none' }}
            type="file"
            ref={data.fileRef}
            onChange={methods.send}
          />
        </Grid>
        <Dialog
          open={data.openDialog}
          onClose={methods.handleCloseDialog}
          fullWidth
          maxWidth="sm"
          scroll="body"
        >
          <DialogContent>
            <Card>
              <Image src={(data.privateUrl) ? privateFileUrl(data.dialogUrl) : data.dialogUrl} />
            </Card>
          </DialogContent>
        </Dialog>
      </Grid>
    </>
  );
};
