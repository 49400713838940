import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  ageText: {
    color: theme.palette.disabledLink.main,
  },
  gridMap: {
    display: 'flex',
  },
}));
