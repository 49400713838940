import React, { useEffect } from 'react';
import CONFIG from 'config/config';
import { convertDate } from 'utils/date';
import { useHistory } from 'react-router-dom';
import useInterval from 'use-interval';
import { useDispatch, useSelector } from 'react-redux';
import { changeNotifications, removeNotification } from 'main/actions/user';
import { changeDrawerNotifications } from 'main/actions/page';
import { Howl } from 'howler';
import AlertSong from 'assets/songs/notification.ogg';
import {
  makeStyles, Drawer, List, ListItem, ListItemText,
  ListItemAvatar, Avatar, ListItemSecondaryAction,
  ListItemIcon, IconButton,
} from '@material-ui/core';
import {
  Delete as DeleteIcon,
  NotificationsOff as NotificationsOffIcon,
} from '@material-ui/icons';
import If from 'components/common/if';

const useStyles = makeStyles((theme) => ({
  drawer: {
    minWidth: theme.drawerWidth,
    width: '50%',
  },
  notFoundIcon: {
    minWidth: 35,
  },
}));

const audio = new Howl({ src: [AlertSong] });
const interval = (CONFIG.SECONDS_UPDATE_NOTIFICATIONS > 20)
  ? CONFIG.SECONDS_UPDATE_NOTIFICATIONS * 1000
  : 20;

export default () => {
  const notifications = useSelector((state) => state.user.notifications);
  const open = useSelector((state) => state.page.hasDrawerNotificationsOpen);
  const userId = useSelector((state) => state.user.id);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const getNotifications = () => {
    if (userId) {
      setTimeout(() => {
        dispatch(changeNotifications());
      }, 1000);
    }
  };

  useEffect(getNotifications, [userId]);

  useEffect(() => {
    if (notifications.length) {
      audio.play();
    }
  }, [notifications]);

  useInterval(
    getNotifications,
    interval,
  );

  const onClickItem = (notification) => {
    if (!notification.type) {
      return;
    }

    switch (notification.type) {
      case 'sms':
        history.push(`/marketing/sms/${notification.ref}`);
        break;
      case 'customerBirthday':
        history.push(`/operacional/clientes/${notification.ref}`);
        break;
      default:
        break;
    }

    dispatch(changeDrawerNotifications(false));
  };

  const onClickRemoveItem = (notification) => {
    dispatch(removeNotification(notification.id));
    audio.play();
  };

  return (
    <Drawer
      open={open}
      anchor="right"
      transitionDuration={150}
      variant="temporary"
      classes={{ paper: classes.drawer }}
      onClose={() => dispatch(changeDrawerNotifications(false))}
    >
      <List>
        <If test={!notifications.length}>
          <ListItem>
            <ListItemIcon className={classes.notFoundIcon}>
              <NotificationsOffIcon />
            </ListItemIcon>
            <ListItemText
              primary="Nenhuma notificação encontrada."
            />
          </ListItem>
        </If>
        <If test={notifications.length}>
          {notifications.map((notification, index) => (
            <ListItem
              button={Boolean(notification.type)}
              onClick={() => onClickItem(notification)}
              divider={index !== notifications.length - 1}
              key={notification.id}
            >
              <ListItemAvatar>
                <Avatar src={notification.image} />
              </ListItemAvatar>
              <ListItemText
                primary={notification.content}
                secondary={convertDate(notification.createdAt, null, 'DD/MM/YYYY HH:mm:ss')}
              />
              <ListItemSecondaryAction>
                <IconButton onClick={() => onClickRemoveItem(notification)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </If>
      </List>
    </Drawer>
  );
};
