import React from 'react';

import { SnackbarProvider } from 'notistack';
import { Howl } from 'howler';
import AlertSong from 'assets/songs/notification.ogg';

import { makeStyles, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

const audio = new Howl({ src: [AlertSong] });

const useStyles = makeStyles((theme) => ({
  close: {
    padding: theme.spacing(0.5),
  },
}));

const CloseButton = (props) => {
  const classes = useStyles();
  const { onClick } = props;
  return (
    <IconButton
      color="inherit"
      className={classes.close}
      onClick={onClick}
    >
      <CloseIcon />
    </IconButton>
  );
};

export default (props) => {
  const { children } = props;
  return (
    <SnackbarProvider
      maxSnack={5}
      ref={notistackRef}
      autoHideDuration={7000}
      disableWindowBlurListener
      preventDuplicate
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      action={(key) => <CloseButton onClick={onClickDismiss(key)}>Dismiss</CloseButton>}
      onEntering={() => audio.play()}
    >
      {children}
    </SnackbarProvider>
  );
};
