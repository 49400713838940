import { createMuiTheme } from '@material-ui/core';

const defaultTheme = createMuiTheme();

export default createMuiTheme({
  palette: {
    primary: {
      main: '#f68934',
    },
    secondary: {
      main: '#1b2430',
    },
    disabledLink: {
      main: '#aaa',
    },
  },
  disabledField: {
    backgroundColor: '#e0e0e0',
    opacity: '0.7 !important',
  },
  drawerWidth: 250,
  overrides: {
    MuiTableRow: {
      root: {
        width: `calc(100vw - ${defaultTheme.spacing(3) * 2}px)`,
        [defaultTheme.breakpoints.up('sm')]: {
          width: 'auto',
        },
      },
    },
  },
});
