import React, { useEffect, memo } from 'react';

import theme from 'main/theme';

import { get } from 'lodash';

import { formatMask } from 'utils/masks';

import { useFormContext } from 'react-hook-form';

import MaskedInput from 'react-text-mask';

import ChipInput from 'material-ui-chip-input';

import {
  makeStyles, Chip, InputAdornment, IconButton,
} from '@material-ui/core';

import { Add as AddIcon } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  disabled: theme.disabledField,
  inputRoot: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  input: {
    minWidth: 30,
  },
  iconButton: {
    marginTop: -13,
  },
  chipLabel: {
    whiteSpace: 'normal',
    wordBreak: 'break-all',
  },
}));

const InputMask = (props) => {
  const {
    inputRef, value, watch, name, ...other
  } = props;
  return (
    <MaskedInput
      {...other}
      guide={false}
      name={name}
      value={watch(name) || ''}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
    />
  );
};

const ChipTextField = (props) => {
  const {
    name, chipName, label, type, InputProps,
    mask, disabled, required,
    maxLength, fields, append, remove,
  } = props;

  const classes = useStyles();

  const {
    errors, register, watch,
    setValue, triggerValidation, clearError,
    formState, control,
  } = useFormContext();

  const values = (fields || []).map((field) => (
    field.value
  ));

  const error = get(errors, name);
  const chipError = get(errors, chipName);

  const hasError = Boolean(error || chipError);

  let errorMessage = '';

  if (hasError) {
    if (error) {
      errorMessage = error.message;
    }

    if (chipError) {
      errorMessage = chipError.message;
    }
  }

  const labelFormatted = (required)
    ? `${label} *`
    : label;

  const onBeforeAdd = async (newValue) => {
    const errorFields = Object.keys({ ...errors });

    if (errorFields.length > 0) {
      await clearError();
    }

    const valid = await triggerValidation(name);

    if (newValue && valid) {
      append({ value: newValue });
      setValue(name, '');
    }
  };

  const onDelete = (deletedValue, index) => {
    if (fields[index]) {
      remove(index);
    }
    triggerValidation(chipName);
  };

  const inputPropsFormatted = {
    name,
    type,
    inputProps: {
      mask: formatMask(mask),
      maxLength,
    },
    endAdornment: (
      <InputAdornment
        style={{ height: 48 }}
        position="end"
      >
        <IconButton
          onClick={() => onBeforeAdd(watch(name) || '')}
          disabled={disabled}
          classes={{ root: classes.iconButton }}
        >
          <AddIcon />
        </IconButton>
      </InputAdornment>
    ),
    ...InputProps,
  };

  if (mask) {
    inputPropsFormatted.inputComponent = InputMask;
    inputPropsFormatted.inputProps.watch = watch;
  } else {
    inputPropsFormatted.inputProps.value = watch(name) || '';
  }

  const onBlur = () => {
    triggerValidation(chipName);
  };

  const focusOnError = () => {
    if (hasError) {
      const names = chipName.split('.');
      delete names[names.length - 1];
      const findName = names.join('.').slice(0, -1);
      const filteredError = (findName) ? get(errors, findName || '') : errors;
      const errorsKeys = Object.keys(filteredError);
      if (
        formState.isSubmitting
        && (`${findName}.${errorsKeys[0]}` === chipName
          || errorsKeys[0] === chipName)
      ) {
        control.fieldsRef.current[name].ref.focus();
      }
    }
  };

  useEffect(focusOnError, [formState.isSubmitting]);

  return (
    <>
      <ChipInput
        label={labelFormatted}
        fullWidth
        inputRef={register}
        blurBehavior="ignore"
        error={hasError}
        chipRenderer={({
          text, isDisabled, handleClick, handleDelete, isFocused,
        }, key) => (
          <Chip
            key={key}
            label={text}
            disabled={isDisabled}
            onClick={handleClick}
            onDelete={handleDelete}
            style={{
              marginBottom: 8,
              marginRight: 5,
              minHeight: 32,
              height: 'auto',
              whiteSpace: 'normal',
              backgroundColor: (isFocused) ? theme.palette.primary.main : undefined,
            }}
            classes={{
              label: classes.chipLabel,
            }}
          />
        )}
        helperText={errorMessage}
        disabled={disabled}
        variant="outlined"
        value={values}
        onBeforeAdd={onBeforeAdd}
        onDelete={onDelete}
        onBlur={onBlur}
        InputProps={inputPropsFormatted}
        placeholder="Digite e pressione enter"
        FormHelperTextProps={{
          style: {
            marginBottom: 0,
            marginTop: 4,
          },
        }}
        classes={{
          inputRoot: classes.inputRoot,
          input: classes.input,
          disabled: classes.disabled,
        }}
      />
      {fields.map(({ id, value }, index) => (
        <input
          key={id}
          type="hidden"
          name={`${chipName}[${index}].value`}
          ref={register()}
          defaultValue={value}
        />
      ))}
    </>
  );
};

export default memo(ChipTextField);
