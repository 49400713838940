import theme from 'main/theme';

export default (days) => {
  if (days === 1) {
    return {
      label: `${days} dia`,
      style: null,
    };
  }

  if (days >= 60) {
    return {
      label: `${days} dias`,
      style: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
      },
    };
  }

  if (days >= 30) {
    return {
      label: `${days} dias`,
      style: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    };
  }

  return {
    label: `${days} dias`,
    style: null,
  };
};
