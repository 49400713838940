import React from 'react';

import 'typeface-roboto';
import './style.css';
import 'utils/yup/language';

import { createStore, applyMiddleware } from 'redux';

import { Provider } from 'react-redux';

import thunk from 'redux-thunk';

import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core';

import PickerProvider from 'components/common/skeleton/pickerProvider';

import { Container } from 'components/common/skeleton';

import ServiceWorkerWrapper from 'main/serviceWorkerWrapper';

import SnackbarProvider from 'components/common/skeleton/snackbarProvider';

import DrawerNotifications from 'components/common/skeleton/drawerNotifications';

import Routes from './routes';
import reducers from './reducers';
import theme from './theme';

const store = createStore(reducers, applyMiddleware(thunk));

export default () => (
  <>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <PickerProvider>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Container>
              <SnackbarProvider>
                <Routes />
                <DrawerNotifications />
              </SnackbarProvider>
            </Container>
          </BrowserRouter>
        </PickerProvider>
      </ThemeProvider>
    </Provider>
    <ServiceWorkerWrapper />
  </>
);
