import Yup from 'utils/yup';

export default Yup.object().shape({
  customer: Yup.object().shape({
    cpfCnpj: Yup.string()
      .label('CPF/CNPJ')
      .required()
      .cpfCnpj(),
    name: Yup.string()
      .label('Nome completo')
      .required()
      .min(5)
      .max(50),
    cep: Yup.string()
      .label('CEP')
      .required()
      .length(9, 'CEP inválido'),
    telephone: Yup.string()
      .label('Telefone')
      .telephone(),
    telephones: Yup.array()
      .label('Telefone')
      .required(),
    uf: Yup.string()
      .label('UF')
      .required()
      .length(2, 'UF inválida'),
    city: Yup.string()
      .label('Cidade')
      .required()
      .min(2)
      .max(40),
    address: Yup.string()
      .label('Bairro')
      .required()
      .min(2)
      .max(40),
    street: Yup.string()
      .label('Rua')
      .required()
      .min(2)
      .max(40),
    number: Yup.string()
      .label('Número')
      .required()
      .max(6),
    naturalness: Yup.string()
      .label('Naturalidade')
      .max(100),
    nationality: Yup.string()
      .label('Nacionalidade')
      .max(100),
    mothersName: Yup.string()
      .label('Nome da mãe')
      .max(150),
    profession: Yup.string()
      .label('Profissão')
      .max(150),
    income: Yup.string()
      .label('Renda')
      .max(150),
    workplace: Yup.string()
      .label('Local de trabalho')
      .max(250),
    companyTime: Yup.string()
      .label('Tempo de empresa')
      .max(50),
    personalReference: Yup.string()
      .label('Referência pessoal')
      .max(250),
  }),
});
