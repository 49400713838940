import Users from 'components/pages/admin/users';
import AddUsers from 'components/pages/admin/users/add';
import EditUsers from 'components/pages/admin/users/edit';

export default [
  {
    title: 'Administrativo',
    path: '/administrativo',
    type: 'private',
    redirect: '/administrativo/usuarios',
    admin: true,
    exact: true,
  },
  {
    title: 'Usuários',
    path: '/administrativo/usuarios',
    type: 'private',
    component: Users,
    admin: true,
    exact: true,
  },
  {
    title: 'Adicionar Usuário',
    path: '/administrativo/usuarios/adicionar',
    type: 'private',
    component: AddUsers,
    admin: true,
    exact: true,
  },
  {
    title: 'Editar Usuário',
    path: '/administrativo/usuarios/:id',
    type: 'private',
    component: EditUsers,
    admin: true,
    exact: true,
  },
];
