import React from 'react';

import { makeStyles } from '@material-ui/core';

import Toolbar from './toolbar';

const useStyles = makeStyles((theme) => ({
  content: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.drawerWidth,
      width: `calc(100% - ${theme.drawerWidth}px  - ${theme.spacing(3) * 2}px)`,
    },
    padding: theme.spacing(3),
  },
}));

export default (props) => {
  const { children } = props;
  const classes = useStyles();
  return (
    <main className={classes.content}>
      <Toolbar />
      {children}
    </main>
  );
};
