import Yup from 'utils/yup';

export default Yup.object().shape({
  vehicle: Yup.object().shape({
    plate: Yup.string()
      .label('Placa')
      .required()
      .plate(),
    brand: Yup.string()
      .label('Marca')
      .required()
      .max(40),
    model: Yup.string()
      .label('Modelo')
      .required(),
    manufactureYear: Yup.string()
      .label('Ano de Fabricação')
      .required(),
    releaseYear: Yup.string()
      .label('Ano do Modelo')
      .required(),
    chassi: Yup.string()
      .label('Chassi')
      .required()
      .chassi(),
    renavam: Yup.string()
      .label('Renavam')
      .required()
      .renavam(),
    fuel: Yup.string()
      .label('Combustível')
      .required(),
    color: Yup.string()
      .label('Cor')
      .required(),
  }),
});
