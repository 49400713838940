import moment from 'moment';

export function validate(date, format) {
  return moment(date, format).isValid();
}

export function convertDate(date, currentFormat, newFormat, defaultValue = '') {
  return (validate(date, currentFormat))
    ? moment(date, currentFormat).format(newFormat)
    : defaultValue;
}

export function getNow(format) {
  return moment().format(format);
}

export function diffYears(startDate, endDate, format) {
  return moment(startDate, format).diff(endDate, 'years');
}
