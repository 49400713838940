import PACKAGE from '../../package.json';

export default {
  API_URL: process.env.REACT_APP_API_URL,
  DOCS_SERVICE_URL: process.env.REACT_APP_DOCS_SERVICE_URL,
  PLATE_SERVICE_URL: process.env.REACT_APP_PLATE_SERVICE_URL,
  BUCKET_SERVICE_URL: process.env.REACT_APP_BUCKET_SERVICE_URL,
  SECONDS_UPDATE_NOTIFICATIONS: process.env.REACT_APP_SECONDS_UPDATE_NOTIFICATIONS,
  VERSION: PACKAGE.version,
};
