import React from 'react';

import { useForm, FormContext } from 'react-hook-form';

import { Grid, Button } from '@material-ui/core';

import CustomerForm from 'components/common/form/customer';

import validationSchema from 'components/common/form/customer/validationSchema/salesman';

export default (props) => {
  const {
    setActiveStep, data, setData,
    isLoading, setIsLoading,
    classes,
  } = props;

  const methods = useForm({
    validationSchema,
    defaultValues: data,
    validateCriteriaMode: 'firstErrorDetected',
    mode: 'onBlur',
  });

  const { handleSubmit } = methods;

  const onSubmit = (formData) => {
    setData({ ...data, customer: formData.customer });
    setActiveStep(1);
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <CustomerForm
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
          <Grid item xs={12}>
            <Button
              className={classes.button}
              disabled
            >
              Voltar
            </Button>
            <Button
              disabled={isLoading}
              variant="contained"
              color="primary"
              className={classes.button}
              type="submit"
            >
              Avançar
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormContext>
  );
};
