import React from 'react';

import { conformToMask } from 'react-text-mask';

import { formatMask } from 'utils/masks';

export default (props) => {
  const { children, mask } = props;
  const { conformedValue } = conformToMask(children.toString(), formatMask(mask));
  return (
    <>{ conformedValue }</>
  );
};
