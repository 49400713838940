import React from 'react';

import { makeStyles, Toolbar } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: 'center',
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default (props) => {
  const { bg, children } = props;

  const classes = (bg)
    ? useStyles()
    : {};

  return (
    <Toolbar className={classes.toolbar}>
      { children }
    </Toolbar>
  );
};
