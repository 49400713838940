export default {
  pagination: {
    labelRowsSelect: 'por página',
    labelDisplayedRows: '{from}-{to} de {count}',
    firstTooltip: 'Página inicial',
    previousTooltip: 'Página anterior',
    nextTooltip: 'Próxima página',
    lastTooltip: 'Última página',
  },
  body: {
    emptyDataSourceMessage: 'Nenhum registro encontrado.',
  },
};
