import React, { useState, useEffect } from 'react';

import { scrollTo, Element } from 'components/common/scroll';

import { useHistory, useRouteMatch } from 'react-router-dom';

import useRequest from 'utils/http';

import {
  onlyNumbers, telephoneToTelephoneAndPrefix,
  currencyToFloat,
} from 'utils/masks';

import { useSnackbar } from 'notistack';

import {
  makeStyles, Grid, Stepper, Step as MaterialStep,
  StepLabel, StepContent, LinearProgress, Typography,
} from '@material-ui/core';

import If from 'components/common/if';

import Resume from './resume';

import Buyer from './steps/buyer';
import Payments from './steps/payments';
import AdditionalInformation from './steps/additionalInformation';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  stepContainer: {
    padding: 0,
  },
  stepGrid: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  removeButton: {
    color: theme.palette.error.main,
  },
}));

const steps = [
  { key: 'buyer', label: 'Comprador' },
  { key: 'payments', label: 'Formas de Pagamento' },
  { key: 'additionalInformation', label: 'Informações Adicionais' },
];

export default () => {
  const history = useHistory();
  const match = useRouteMatch();

  const request = useRequest();

  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [vehicle, setVehicle] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [oldActiveStep, setOldActiveStep] = useState(0);
  const [data, setData] = useState({});
  const [resumeData, setResumeData] = useState({});

  const vehicleId = match.params.vehicle;

  useEffect(() => {
    const getVehicleData = async () => {
      try {
        const { data: responseData } = await request({
          url: `vehicles/${vehicleId}`,
          method: 'get',
        });

        if (responseData.situation !== 'inStock') {
          throw Error('Vehicle without in stock');
        }

        setVehicle(responseData);
        setIsLoading(false);
      } catch (e) {
        enqueueSnackbar('Veículo não encontrado', { variant: 'warning' });
        history.push('/comercial/ponto-de-venda');
      }
    };

    getVehicleData();
  }, [enqueueSnackbar, history, vehicleId, request]);

  const sendData = async () => {
    try {
      setActiveStep(activeStep + 1);

      let formattedData = { ...data };

      formattedData.buyer = {
        ...formattedData.customer,
        cpfCnpj: onlyNumbers(formattedData.customer.cpfCnpj),
        income: formattedData.customer.income ? currencyToFloat(formattedData.customer.income) : null,
        cep: onlyNumbers(formattedData.customer.cep),
      };

      formattedData.buyer.telephones = (formattedData.buyer.telephones || []).map((telephone) => {
        const {
          telephonePrefix: prefix,
          telephone: number,
        } = telephoneToTelephoneAndPrefix(telephone.value);

        return { prefix, number };
      });

      formattedData.buyer.emails = (formattedData.buyer.emails || []).map((email) => (
        { email: email.value }
      ));

      const payments = formattedData.payments.map((payment) => {
        const formattedPayment = { ...payment };

        if (formattedPayment.exchangeVehicle) {
          formattedPayment.exchangeVehicle = formattedPayment.exchangeVehicle.id;
        }

        formattedPayment.value = currencyToFloat(payment.value);

        return formattedPayment;
      });

      formattedData = {
        buyer: formattedData.buyer,
        payments,
        ...formattedData.additionalInformation,
      };

      const { data: responseData } = await request({
        method: 'post',
        url: `sales/${vehicleId}`,
        data: formattedData,
      });

      enqueueSnackbar('Venda salva com sucesso', { variant: 'success' });
      history.push(`/operacional/veiculos/${responseData.id}`);
    } catch (e) {
      setActiveStep(activeStep - 1);
      enqueueSnackbar('Não foi possível salvar a compra', { variant: 'error' });
    }
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Buyer
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            data={data}
            setData={setData}
            resumeData={resumeData}
            setResumeData={setResumeData}
            setActiveStep={setActiveStep}
            classes={classes}
          />
        );
      case 1:
        return (
          <Payments
            data={data}
            saleValue={vehicle.saleValue}
            setData={setData}
            resumeData={resumeData}
            setResumeData={setResumeData}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            classes={classes}
            vehicleId={vehicleId}
          />
        );
      case 2:
      case 3:
        return (
          <AdditionalInformation
            data={data}
            setData={setData}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            classes={classes}
          />
        );
      default:
        return false;
    }
  };

  if (activeStep !== oldActiveStep) {
    setOldActiveStep(activeStep);
    scrollTo({
      className: `sale[${activeStep}]`,
      timeout: 500,
      offset: 20,
    });
  }

  return (
    <Grid container spacing={3}>
      <Grid item md={8} xs={12}>
        <div className={classes.root}>
          <Stepper activeStep={activeStep} orientation="vertical" className={classes.stepContainer}>
            {steps.map(({ label }, index) => (
              <MaterialStep key={label}>
                <StepLabel>
                  <Element className={`sale[${index}]`}>
                    {label}
                  </Element>
                </StepLabel>
                <StepContent>
                  <Grid container spacing={2} className={classes.stepGrid}>
                    <Grid item xs={12}>
                      {renderStepContent()}
                    </Grid>
                  </Grid>
                </StepContent>
              </MaterialStep>
            ))}
          </Stepper>
          <If test={activeStep > steps.length}>
            <Element className="sale[4]">
              <Grid container spacing={1} className={classes.stepGrid}>
                <Grid item xs={12}>
                  <Typography variant="body2" component="div">
                    Enviando Informações...
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              </Grid>
            </Element>
          </If>
        </div>
      </Grid>
      <Grid item md={4} xs={12}>
        <Element className="sale[3]">
          <If test={isLoading}>
            <LinearProgress />
          </If>
          <If test={!isLoading}>
            <Resume
              disabled={activeStep !== steps.length}
              sendData={sendData}
              setActiveStep={setActiveStep}
              vehicle={vehicle}
              data={resumeData}
            />
          </If>
        </Element>
      </Grid>
    </Grid>
  );
};
