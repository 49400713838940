import React, { useState } from 'react';
import { privateFileUrl } from 'utils/http';
import {
  MobileStepper, Button, Card,
} from '@material-ui/core';
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@material-ui/icons';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Image from 'material-ui-image';
import If from 'components/common/if';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const defaultImages = [
  {
    id: 0,
    url: '404',
  },
];

const getTimeToNext = () => {
  const [max, min] = [2, 4];
  return (Math.random() * (max - min) + min) * 1000;
};

const ImageStepper = (props) => {
  const {
    images: imagesProps = [],
    nav = true, privateUrl,
  } = props;

  const [activeStep, setActiveStep] = useState(0);
  const [timeToNext] = useState(getTimeToNext());

  const images = imagesProps.length < 1
    ? defaultImages
    : imagesProps;

  const maxSteps = images.length;

  const handleNext = () => {
    if (activeStep === maxSteps - 1) {
      setActiveStep(() => 0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      setActiveStep(() => maxSteps - 1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <>
      <AutoPlaySwipeableViews
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={timeToNext}
      >
        {images.map((image) => (
          <Image
            key={image.id}
            src={(privateUrl) ? privateFileUrl(image.url) : image.url}
            imageStyle={{ overflow: 'hidden' }}
          />
        ))}
      </AutoPlaySwipeableViews>
      <If test={nav}>
        <MobileStepper
          steps={maxSteps}
          position="static"
          variant="dots"
          activeStep={activeStep}
          nextButton={(
            <Button
              size="small"
              onClick={handleNext}
            >
              <KeyboardArrowRightIcon />
            </Button>
          )}
          backButton={(
            <Button
              size="small"
              onClick={handleBack}
            >
              <KeyboardArrowLeftIcon />
            </Button>
          )}
        />
      </If>
    </>
  );
};

export default (props) => {
  const { borderRadius = true } = props;

  return (
    <>
      <If test={borderRadius}>
        <Card>
          <ImageStepper {...props} />
        </Card>
      </If>
      <If test={!borderRadius}>
        <>
          <ImageStepper {...props} />
        </>
      </If>
    </>
  );
};
