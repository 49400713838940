import React, { useState, useEffect } from 'react';
import { currency } from 'utils/masks';
import useRequest from 'utils/http';
import { Link, useRouteMatch } from 'react-router-dom';
import {
  List, ListItem, ListItemText,
  LinearProgress, Typography,
} from '@material-ui/core';
import If from 'components/common/if';

export default () => {
  const [isLoading, setIsLoading] = useState(true);
  const [vehicles, setVehicles] = useState([]);

  const request = useRequest();
  const match = useRouteMatch();
  const { id } = match.params;

  useEffect(() => {
    const getExchanges = async () => {
      try {
        const { data } = await request({
          method: 'get',
          url: `vehicles/${id}/exchanges`,
        });
        setVehicles(data.data);
      } finally {
        setIsLoading(false);
      }
    };
    getExchanges();
  }, [id, request]);

  return (
    <List disablePadding>
      <If test={isLoading}>
        <LinearProgress />
      </If>
      <If test={!isLoading && !vehicles.length}>
        <Typography
          variant="body1"
          component="span"
        >
          Nenhum veículo de troca.
        </Typography>
      </If>
      <If test={!isLoading && vehicles.length}>
        {vehicles.map((vehicle) => (
          <ListItem
            divider
            button
            key={vehicle.id}
            component={Link}
            to={`/operacional/veiculos/${vehicle.id}`}
          >
            <ListItemText>
              {vehicle.plate}
            </ListItemText>
            <ListItemText
              style={{ textAlign: 'right' }}
              secondary={currency(vehicle.value)}
            >
              {`${vehicle.model}`}
            </ListItemText>
          </ListItem>
        ))}
      </If>
    </List>
  );
};
