import React, { useState } from 'react';
import { currency } from 'utils/masks';
import {
  Grid, Card, CardActionArea, CardContent,
  CardMedia, Typography,
} from '@material-ui/core';
import {
  CalendarToday as CalendarTodayIcon,
  ColorLens as ColorLensIcon,
} from '@material-ui/icons';
import Image from 'material-ui-image';
import VehicleInfoDialog from 'components/common/vehicleInfoDialog';

export default (props) => {
  const { vehicles } = props;
  const [openDialogindex, setOpenDialogIndex] = useState(null);

  return (
    <Grid container spacing={2}>
      {vehicles.map((vehicle, index) => (
        <Grid item xl={2} lg={3} md={4} sm={6} xs={12} key={vehicle.id}>
          <Card>
            <CardActionArea
              component="button"
              onClick={() => setOpenDialogIndex(index)}
            >
              <CardMedia title={vehicle.model}>
                <Image
                  key={(vehicle.images[0] || {}).id || index}
                  src={(vehicle.images[0] || {}).url || '404'}
                  imageStyle={{ overflow: 'hidden' }}
                />
              </CardMedia>
              <CardContent>
                <Typography gutterBottom variant="subtitle1" component="h2">
                  {vehicle.model}
                </Typography>
                <Typography gutterBottom variant="subtitle1" color="primary" component="div">
                  {currency(vehicle.saleValue)}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary" component="div">
                  <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                          <CalendarTodayIcon />
                        </Grid>
                        <Grid item>
                          {vehicle.releaseYear}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                          <ColorLensIcon />
                        </Grid>
                        <Grid item>
                          {vehicle.color}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <VehicleInfoDialog
            vehicle={vehicle}
            open={openDialogindex === index}
            handleClose={() => setOpenDialogIndex(null)}
          />
        </Grid>
      ))}
    </Grid>
  );
};
