import React, { useState } from 'react';

import useRequest from 'utils/http';

import { useDispatch } from 'react-redux';

import { setUser } from 'main/actions/user';

import { useForm, FormContext } from 'react-hook-form';

import { useSnackbar } from 'notistack';

import { Grid, Button } from '@material-ui/core';

import TextField from 'components/common/form/textField';
import Checkbox from 'components/common/form/checkBox';
import ImageUpload from 'components/common/form/imageUpload';

import validationSchema from './validationSchema';

export default (props) => {
  const { defaultValues } = props;

  const dispatch = useDispatch();

  const request = useRequest();

  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm({
    validationSchema,
    defaultValues,
    validateCriteriaMode: 'firstErrorDetected',
    mode: 'onBlur',
  });

  const { handleSubmit, watch } = methods;

  const onSubmit = async (formData) => {
    try {
      setIsLoading(true);
      const formattedFormData = { ...formData };

      if (!formattedFormData.password) {
        delete formattedFormData.password;
        delete formattedFormData.passwordConfirmation;
      }

      const { data: responseData } = await request({
        method: 'patch',
        url: 'profile',
        data: formattedFormData,
      });

      setIsLoading(false);
      enqueueSnackbar('Perfil salvo com sucesso', { variant: 'success' });
      dispatch(setUser(responseData));
    } catch (e) {
      enqueueSnackbar('Não foi possível salvar o usuário', { variant: 'error' });
      setIsLoading(false);
    }
  };

  const onChangeImage = async (url) => {
    try {
      if (url === false) {
        throw Error('DONT UPLOAD IMAGE');
      }

      const { data: responseData } = await request({
        method: 'patch',
        url: 'profile',
        data: {
          image: watch('image'),
        },
      });

      setIsLoading(false);
      enqueueSnackbar('Imagem salva com sucesso', { variant: 'success' });
      dispatch(setUser(responseData));
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar('Não foi possível salvar a imagem', { variant: 'error' });
    }
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xl={2} lg={3} md={4} xs={12}>
            <ImageUpload
              src={defaultValues.image}
              name="image"
              beforeUpload={() => setIsLoading(true)}
              afterUpload={onChangeImage}
              afterRemove={onChangeImage}
              disabled={isLoading}
              avatar
              privateUrl
            />
          </Grid>
          <Grid item xl={10} lg={9} md={8} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <TextField
                  name="username"
                  label="Usuário"
                  required
                  disabled={isLoading}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  name="name"
                  label="Nome"
                  required
                  disabled={isLoading}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Email"
                  required
                  disabled={isLoading}
                  name="email"
                  type="email"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Senha"
                  required
                  disabled={isLoading}
                  name="password"
                  type="password"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Confirmação de Senha"
                  required
                  disabled={isLoading}
                  name="passwordConfirmation"
                  type="password"
                />
              </Grid>
              <Grid item xs={12}>
                <Checkbox
                  label="Ativo"
                  name="isActive"
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <Checkbox
                  label="Administrador"
                  name="isAdmin"
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  disabled={isLoading}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormContext>
  );
};
