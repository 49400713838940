import React from 'react';

import { Chip } from '@material-ui/core';

import styles from './styles';

export default (props) => {
  const { months, className } = props;

  const { label, style } = styles(months);

  return (
    <Chip
      label={label}
      style={style}
      className={className}
    />
  );
};
