import React from 'react';

import { currency } from 'utils/masks';

import {
  makeStyles, List, ListItem, ListItemText,
  LinearProgress,
} from '@material-ui/core';

import If from 'components/common/if';

import SaleValue from './saleValue';
import EffectiveSaleValue from './effectiveSaleValue';

const useStyles = makeStyles((theme) => ({
  negativeValue: {
    color: theme.palette.error.main,
  },
}));

export default (props) => {
  const {
    id, isLoading: isLoadingParent, defaultValues,
  } = props;

  const classes = useStyles();

  const renderColorValue = (value) => (
    (value < 0) ? classes.negativeValue : null
  );

  const sale = defaultValues.saleValue || 0;

  const { payments = [] } = defaultValues;

  const effectiveSale = payments.reduce((accumulator, payment) => (
    accumulator + payment.value
  ), 0);

  const discount = effectiveSale - sale;

  const showEffectiveValue = (
    defaultValues.situation === 'pendingWarranty'
    || defaultValues.situation === 'sold'
  );

  return (
    <>
      <If test={isLoadingParent}>
        <LinearProgress />
      </If>
      <If test={!isLoadingParent}>
        <List disablePadding>
          <SaleValue
            id={id}
            defaultValues={defaultValues}
            value={currency(sale)}
          />
          <If test={showEffectiveValue}>
            <EffectiveSaleValue
              classes={classes}
              value={currency(effectiveSale)}
              id={id}
              defaultValues={defaultValues}
            />
            <ListItem divider>
              <ListItemText>
                Desconto
              </ListItemText>
              <ListItemText
                className={renderColorValue(discount)}
                style={{ textAlign: 'right' }}
              >
                {currency(discount)}
              </ListItemText>
            </ListItem>
          </If>
        </List>
      </If>
    </>
  );
};
