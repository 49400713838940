import React from 'react';

import {
  Grid, Card, CardContent, Typography,
  LinearProgress,
} from '@material-ui/core';

import If from 'components/common/if';

import styles from './styles';

export default (props) => {
  const {
    isLoading, value, situation,
  } = props;

  const { style, label, Icon } = styles(situation);

  return (
    <Card style={style}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="button">
              {label}
            </Typography>
          </Grid>
          <Grid item>
            <Icon />
          </Grid>
          <Grid item xs={12}>
            <If test={isLoading}>
              <LinearProgress />
            </If>
            <If test={!isLoading}>
              <Typography variant="h6">
                {value}
              </Typography>
            </If>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
