import React, { useState, useEffect } from 'react';

import useRequest from 'utils/http';

import { useHistory, useRouteMatch } from 'react-router-dom';

import { useSnackbar } from 'notistack';

import { Grid, LinearProgress } from '@material-ui/core';

import If from 'components/common/if';

import Form from './form';

export default () => {
  const history = useHistory();
  const match = useRouteMatch();

  const request = useRequest();

  const { id } = match.params;

  const { enqueueSnackbar } = useSnackbar();

  const [defaultValues, setDefaultValues] = useState({});

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: requestData } = await request({
          method: 'get',
          url: `users/${id}`,
        });

        setDefaultValues({
          id: requestData.id,
          username: requestData.username,
          name: requestData.name,
          email: requestData.email,
          image: requestData.image,
          isActive: requestData.isActive,
          isAdmin: requestData.isAdmin,
          author: requestData.author,
        });
      } catch (e) {
        enqueueSnackbar('Usuário não encontrado', { variant: 'warning' });
        history.push('/administrativo/usuarios');
      }
    };

    getData();
  }, [id, history, enqueueSnackbar, request]);

  return (
    <Grid container>
      <If test={!defaultValues.id}>
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      </If>
      <If test={defaultValues.id}>
        <Grid item xs={12}>
          <Form
            id={id}
            defaultValues={defaultValues}
          />
        </Grid>
      </If>
    </Grid>
  );
};
