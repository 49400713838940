import React from 'react';

import { Switch, Redirect } from 'react-router-dom';
import routesConfig from 'config/routes';

import Public from 'components/routes/public';
import Private from 'components/routes/private';

function renderRouteType(type) {
  switch (type) {
    case 'private':
      return Private;
    default:
      return Public;
  }
}

function renderRoute(route) {
  const RouteType = renderRouteType(route.type);
  return (
    <RouteType
      exact={route.exact}
      path={route.path}
      key
      title={route.title}
      component={route.component}
      admin={route.admin}
    />
  );
}

function renderRedirect(route) {
  return (
    <Redirect
      exact={route.exact}
      path={route.path}
      key
      title={route.title}
      to={route.redirect}
    />
  );
}

export default () => {
  const routes = routesConfig.map((route) => (
    (route.redirect)
      ? renderRedirect(route)
      : renderRoute(route)
  ));

  return (
    <Switch>
      {routes}
      <Redirect
        path="*"
        to="/"
      />
    </Switch>
  );
};
