import React, { useState, useEffect } from 'react';

import { useHistory, useRouteMatch } from 'react-router-dom';

import { convertDate } from 'utils/date';

import useRequest from 'utils/http';

import { useForm, FormContext } from 'react-hook-form';

import {
  cpfCnpj as cpfCnpjMask,
  telephone as telephoneMask,
  cep as cepMask,
  telephoneToTelephoneAndPrefix as telephoneToTelephoneAndPrefixMask,
  onlyNumbers,
  currencyToFloat,
  currency,
} from 'utils/masks';

import { useSnackbar } from 'notistack';

import { Grid, LinearProgress } from '@material-ui/core';

import CustomerForm from 'components/common/form/customer';

import If from 'components/common/if';

import validationSchemaOptionalBuyer from 'components/common/form/customer/validationSchema/optionalBuyer';
import validationSchemaBuyer from 'components/common/form/customer/validationSchema/buyer';

import Historic from './historic';
import Files from './files';

const Form = (props) => {
  const {
    defaultValues, match,
    enqueueSnackbar,
  } = props;

  const request = useRequest();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = (defaultValues.customer.isBuyer)
    ? validationSchemaBuyer
    : validationSchemaOptionalBuyer;

  const methods = useForm({
    validationSchema,
    defaultValues,
    validateCriteriaMode: 'firstErrorDetected',
    mode: 'onBlur',
  });

  const { handleSubmit } = methods;

  const onSubmit = async (formData) => {
    try {
      let formattedData = { ...formData.customer };

      formattedData = {
        ...formattedData,
        cpfCnpj: onlyNumbers(formattedData.cpfCnpj),
        cep: onlyNumbers(formattedData.cep),
        income: currencyToFloat(formattedData.income),
      };

      formattedData.telephones = (formattedData.telephones || []).map((telephone) => {
        const {
          telephonePrefix: prefix,
          telephone: number,
        } = telephoneToTelephoneAndPrefixMask(telephone.value);

        return { prefix, number };
      });

      formattedData.emails = (formattedData.emails || []).map((email) => (
        { email: email.value }
      ));

      if (!formattedData.birth) {
        formattedData.birth = null;
      }

      setIsLoading(true);
      await request({
        method: 'patch',
        url: `customers/${match.params.id}`,
        data: formattedData,
      });
      enqueueSnackbar('Cliente salvo com sucesso', { variant: 'success' });
      history.push('/operacional/clientes');
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar('Não foi possível salvar o cliente', { variant: 'warning' });
    }
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <CustomerForm
            id={match.params.id}
            buyer
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            submitButton
            inputImage
          />
        </Grid>
      </form>
    </FormContext>
  );
};

export default () => {
  const request = useRequest();
  const history = useHistory();
  const match = useRouteMatch();

  const [defaultValues, setDefaultValues] = useState({});

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const getDefaultValues = async () => {
      try {
        const { data } = await request({
          method: 'get',
          url: `customers/${match.params.id}`,
        });

        const telephones = data.telephones.map((telephone) => ({
          value: telephoneMask(`${telephone.prefix}${telephone.number}`),
        }));

        const emails = data.emails.map((email) => ({
          value: email.email,
        }));

        const income = data.income ? currency(data.income) : '';

        const formattedData = {
          cpfCnpj: cpfCnpjMask(data.cpfCnpj),
          rg: data.rg,
          name: data.name,
          birth: data.birth,
          telephones,
          emails,
          cep: cepMask(data.cep),
          uf: data.uf,
          city: data.city,
          address: data.address,
          street: data.street,
          number: data.number,
          complement: data.complement,
          isBuyer: data.isBuyer,
          image: data.image,
          idetificationDocument: data.idetificationDocument,
          addressDocument: data.addressDocument,
          naturalness: data.naturalness,
          nationality: data.nationality,
          mothersName: data.mothersName,
          profession: data.profession,
          income,
          workplace: data.workplace,
          companyTime: data.companyTime,
          personalReference: data.personalReference,
        };

        formattedData.birth = convertDate(formattedData.birth, 'YYYY-MM-DD', 'YYYY-MM-DD');

        setDefaultValues(formattedData);
      } catch (e) {
        enqueueSnackbar('Cliente não encontrado', { variant: 'warning' });
        history.push('/operacional/clientes');
      }
    };
    getDefaultValues();
  }, [enqueueSnackbar, history, match.params.id, request]);

  return (
    <Grid container spacing={3} justify="space-between">
      <Grid item xs={12}>
        <If test={!Object.keys(defaultValues).length}>
          <LinearProgress />
        </If>
        <If test={Object.keys(defaultValues).length}>
          <Form
            defaultValues={{ customer: defaultValues }}
            match={match}
            enqueueSnackbar={enqueueSnackbar}
          />
        </If>
      </Grid>
      <Grid item xs={12}>
        <Historic
          id={match.params.id}
        />
      </Grid>
      <Grid item xs={12}>
        <Files
          id={match.params.id}
        />
      </Grid>
    </Grid>
  );
};
