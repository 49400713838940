import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

import useRequest from 'utils/http';

import { useForm, FormContext } from 'react-hook-form';

import {
  telephoneToTelephoneAndPrefix as telephoneToTelephoneAndPrefixMask,
  onlyNumbers,
  currencyToFloat,
} from 'utils/masks';

import { useSnackbar } from 'notistack';

import { Grid } from '@material-ui/core';

import CustomerForm from 'components/common/form/customer';

import validationSchemaOptionalBuyer from 'components/common/form/customer/validationSchema/optionalBuyer';

export default () => {
  const { enqueueSnackbar } = useSnackbar();
  const request = useRequest();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm({
    validationSchema: validationSchemaOptionalBuyer,
    validateCriteriaMode: 'firstErrorDetected',
    mode: 'onBlur',
  });

  const { handleSubmit } = methods;

  const onSubmit = async (formData) => {
    try {
      let formattedData = { ...formData.customer };

      formattedData = {
        ...formattedData,
        cpfCnpj: onlyNumbers(formattedData.cpfCnpj),
        cep: onlyNumbers(formattedData.cep),
        income: currencyToFloat(formattedData.income || ''),
      };

      formattedData.telephones = (formattedData.telephones || []).map((telephone) => {
        const {
          telephonePrefix: prefix,
          telephone: number,
        } = telephoneToTelephoneAndPrefixMask(telephone.value);

        return { prefix, number };
      });

      formattedData.emails = (formattedData.emails || []).map((email) => (
        { email: email.value }
      ));

      if (!formattedData.birth) {
        formattedData.birth = null;
      }

      setIsLoading(true);
      await request({
        method: 'post',
        url: 'customers',
        data: formattedData,
      });
      enqueueSnackbar('Cliente salvo com sucesso', { variant: 'success' });
      history.push('/operacional/clientes');
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar('Não foi possível salvar o cliente', { variant: 'warning' });
    }
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <CustomerForm
            buyer
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            submitButton
            inputImage
          />
        </Grid>
      </form>
    </FormContext>
  );
};
