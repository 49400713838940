import Yup from 'utils/yup';

export default Yup.object().shape({
  additionalInformation: Yup.object().shape({
    dateOfSale: Yup.string()
      .label('Data da Venda')
      .required()
      .date(),
    deliveryDate: Yup.string()
      .label('Data da Entrega')
      .required()
      .date(),
    deliveryKm: Yup.string()
      .label('Km de Entrega')
      .required(),
  }),
});
