import Yup from 'utils/yup';

export default Yup.object().shape({
  username: Yup.string()
    .label('Usuário')
    .min(4)
    .required(),
  name: Yup.string()
    .label('Nome')
    .min(4)
    .required(),
  email: Yup.string()
    .label('Email')
    .required()
    .email(),
  passwordConfirmation: Yup.string()
    .test('', 'Senhas diferentes', function validation(value) {
      return !this.parent.password || value === this.parent.password;
    }),
});
