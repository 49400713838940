import main from './main';
import admin from './admin';
import commercial from './commercial';
import operational from './operational';
import marketing from './marketing';

export default [
  ...main,
  ...admin,
  ...commercial,
  ...operational,
  ...marketing,
];
