import React, { memo } from 'react';

import banks from 'utils/selectOptions/banks';

import {
  Grid, InputAdornment, IconButton,
} from '@material-ui/core';

import { Search as SearchIcon } from '@material-ui/icons';

import TextField from 'components/common/form/textField';
import SelectBox from 'components/common/form/selectBox';
import DatePicker from 'components/common/form/datePicker';
import Link from 'components/common/link';

const FieldByType = (props) => {
  const {
    field, index, isLoading, values,
    setExchangeVehicleIndex, register,
  } = props;

  const { payments = [] } = values;

  switch (field.type) {
    case 'entry':
    case 'accountReceivable':
      return (
        <DatePicker
          label="Data"
          name={`payments[${index}].date`}
          type="tel"
          disabled={isLoading}
          required
        />
      );
    case 'financing':
      return (
        <SelectBox
          label="Banco"
          options={banks}
          defaultValue={field.bank}
          disabled={isLoading}
          name={`payments[${index}].bank`}
          required
        />
      );
    case 'exchangeVehicle':
      return (
        <Grid container spacing={1}>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <TextField
              label="Veículo de Troca"
              name={`payments[${index}].exchangeVehicle.model`}
              disabled
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setExchangeVehicleIndex(index)}
                      disabled={isLoading}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <input
              type="hidden"
              name={`payments[${index}].exchangeVehicle.id`}
              ref={register}
              value={values.payments[index].exchangeVehicle.id}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <Link
              to={`/operacional/veiculos/${payments[index].exchangeVehicle.id}`}
              disabled={!payments[index].exchangeVehicle.id}
            >
              Mais informações
            </Link>
          </Grid>
        </Grid>
      );
    default:
      return (
        <SelectBox
          label="Banco"
          disabled={isLoading}
          options={banks}
          defaultValue={field.bank}
          name={`payments[${index}].bank`}
          required
        />
      );
  }
};

export default memo(FieldByType);
