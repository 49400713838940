import React, { Component, Fragment } from 'react';

import CONFIG from 'config/config';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { changeDrawer, changeDrawerItems } from 'main/actions/page';

import { containRoute } from 'utils/validations';
import { Link, withRouter } from 'react-router-dom';

import Logo from 'assets/img/logo.png';

import {
  withTheme, withStyles, Divider, Drawer as MaterialDrawer, Hidden,
  List, ListItem, ListItemText, ListItemIcon, Collapse, Grid, Typography,
} from '@material-ui/core';

import Toolbar from 'components/common/skeleton/toolbar';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import If from 'components/common/if';
import drawerItems from 'config/menu';

const styles = (theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: theme.drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: theme.drawerWidth,
    background: theme.palette.primary.main,
    border: 'none',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  logo: {
    backgroundColor: 'black',
  },
  version: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    padding: theme.spacing(1),
  },
});

class Drawer extends Component {
  constructor(props) {
    super(props);
    this.handleMenuToggle = this.handleMenuToggle.bind(this);
  }

  handleMenuToggle(index) {
    const {
      drawerItemsOpen,
      changeDrawerItems: changeDrawerItemsAction,
    } = this.props;
    const newDrawerItemsOpen = [];
    newDrawerItemsOpen[index] = !drawerItemsOpen[index];
    changeDrawerItemsAction(newDrawerItemsOpen);
  }

  drawer() {
    const {
      classes, drawerItemsOpen, location, user,
    } = this.props;

    const isAdmin = Boolean(user.isAdmin);

    return (
      <>
        <Toolbar bg>
          <img src={Logo} alt="Logomarca Via Nova" height={40} />
        </Toolbar>
        <Divider />
        <Grid
          container
          alignContent="space-between"
          style={{ flexGrow: 1 }}
        >
          <Grid item xs={12}>
            <List>
              {drawerItems.map((item, index) => {
                if (item.admin && !isAdmin) {
                  return false;
                }

                const hasDisabled = containRoute(location.pathname, item.link, item.exact);
                const hasOpen = drawerItemsOpen[index] || hasDisabled;

                const items = item.items || [];

                const component = (!items.length)
                  ? Link
                  : null;

                const to = (!items.length)
                  ? item.link
                  : null;

                return (
                  <Fragment key={item.link}>
                    <ListItem
                      button
                      disabled={hasDisabled}
                      onClick={() => this.handleMenuToggle(index)}
                      component={component}
                      to={to}
                    >
                      <ListItemIcon><item.icon /></ListItemIcon>
                      <ListItemText primary={item.text} />
                      <If test={items.length}>
                        {hasOpen ? <ExpandLess /> : <ExpandMore />}
                      </If>
                    </ListItem>
                    <If test={items.length}>
                      <Collapse in={hasOpen} timeout={50} unmountOnExit>
                        <List disablePadding>
                          {items.map((subitem) => (
                            <ListItem
                              button
                              key={subitem.link}
                              className={classes.nested}
                              to={subitem.link}
                              component={Link}
                              disabled={containRoute(location.pathname, subitem.link)}
                            >
                              <ListItemText primary={subitem.text} />
                            </ListItem>
                          ))}
                        </List>
                      </Collapse>
                    </If>
                  </Fragment>
                );
              })}
            </List>
          </Grid>
          <Grid item xs={12}>
            <Divider />
            <Typography
              align="center"
              variant="body2"
              className={classes.version}
            >
              {`V${CONFIG.VERSION}`}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }

  render() {
    const {
      classes, container, theme,
      changeDrawer: changeDrawerAction,
      hasDrawerOpen,
    } = this.props;

    return (
      <nav className={classes.drawer}>
        <Hidden smUp implementation="js">
          <MaterialDrawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={hasDrawerOpen}
            onClose={() => changeDrawerAction(false)}
            classes={{ paper: classes.drawerPaper }}
            ModalProps={{ keepMounted: true }}
            transitionDuration={150}
          >
            {this.drawer()}
          </MaterialDrawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <MaterialDrawer
            variant="permanent"
            open
            classes={{ paper: classes.drawerPaper }}
          >
            {this.drawer()}
          </MaterialDrawer>
        </Hidden>
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  hasDrawerOpen: state.page.hasDrawerOpen,
  drawerItemsOpen: state.page.drawerItemsOpen,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  changeDrawer, changeDrawerItems,
}, dispatch);

const connectToStore = connect(mapStateToProps, mapDispatchToProps);

export default connectToStore(withRouter(withTheme(withStyles(styles)(Drawer))));
