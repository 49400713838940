import React, { useState } from 'react';

import {
  makeStyles, Typography,
  AppBar, Toolbar, IconButton,
  Dialog, DialogContent,
} from '@material-ui/core';

import {
  Close as CloseIcon,
  Add as AddIcon,
  ChevronLeft as ChevronLeftIcon,
} from '@material-ui/icons';

import If from 'components/common/if';

import List from './list';
import Add from './add';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  stepContainer: {
    padding: 0,
  },
  stepGrid: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  appBar: {
    position: 'relative',
    marginBottom: theme.spacing(2),
  },
  title: {
    flex: 1,
  },
}));

export default (props) => {
  const {
    index = 0, setIndex, setExchangeVehicle,
    values, customer, vehicleId,
  } = props;

  const { payments = [] } = values;

  const classes = useStyles();

  const [allowClose, setAllowClose] = useState(true);
  const [show, setShow] = useState('list');

  const closeDialog = () => {
    if (allowClose) {
      setIndex(-1);
    }
  };

  const toggleContent = () => {
    if (show === 'list') {
      setShow('add');
    } else {
      setShow('list');
    }
  };

  return (
    <Dialog
      open={Boolean(index + 1)}
      onClose={closeDialog}
      fullScreen
    >
      <AppBar
        className={classes.appBar}
        color="secondary"
      >
        <Toolbar>
          <If test={show === 'add'}>
            <IconButton
              color="inherit"
              onClick={toggleContent}
              className={classes.button}
              disabled={!allowClose}
            >
              <ChevronLeftIcon />
            </IconButton>
          </If>
          <Typography
            variant="h6"
            className={classes.title}
          >
            Veículo de Troca
          </Typography>
          <If test={show === 'list'}>
            <IconButton
              color="inherit"
              onClick={toggleContent}
              className={classes.button}
              disabled={!allowClose}
            >
              <AddIcon />
            </IconButton>
            <IconButton
              color="inherit"
              onClick={closeDialog}
              disabled={!allowClose}
            >
              <CloseIcon />
            </IconButton>
          </If>
        </Toolbar>
      </AppBar>
      <DialogContent id="echangeVehicleContainer">
        <If test={show === 'list'}>
          <List
            index={index}
            setIndex={setIndex}
            setExchangeVehicle={setExchangeVehicle}
            payments={payments}
            vehicleId={vehicleId}
          />
        </If>
        <If test={show === 'add'}>
          <Add
            index={index}
            setIndex={setIndex}
            toggleContent={toggleContent}
            setExchangeVehicle={setExchangeVehicle}
            customer={customer}
            setAllowClose={setAllowClose}
          />
        </If>
      </DialogContent>
    </Dialog>
  );
};
