import React from 'react';

import ImagesUpload from 'components/common/form/imagesUpload';

import useHooks from './hooks';

export default (props) => {
  const { data, methods } = useHooks(props);

  return (
    <ImagesUpload
      images={data.images}
      isLoading={data.isLoading}
      afterUpload={methods.afterUpload}
      remove={methods.remove}
      up={methods.up}
      down={methods.down}
    />
  );
};
