const INITIAL_STATE = {
  hasDrawerOpen: false,
  drawerItemsOpen: [],
  title: '',
  redirectAfterLogin: '/',
  hasDrawerNotificationsOpen: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CHANGE_DRAWER':
      return { ...state, hasDrawerOpen: action.payload };
    case 'CHANGE_TITLE':
      return { ...state, title: action.payload };
    case 'CHANGE_DRAWER_ITEMS_OPEN':
      return { ...state, drawerItemsOpen: action.payload };
    case 'SET_REDIRECT_AFTER_LOGIN':
      return { ...state, redirectAfterLogin: action.payload };
    case 'CHANGE_DRAWER_NOTIFICATIONS':
      return { ...state, hasDrawerNotificationsOpen: action.payload };
    default:
      return state;
  }
}
