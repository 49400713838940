import React from 'react';

import { useFormContext } from 'react-hook-form';

import dot from 'dot-object';

import { Grid, Button } from '@material-ui/core';

import PaymentsForm from 'components/common/form/payments';

const Buttons = (props) => {
  const { classes, handleBackActiveStep } = props;

  const { watch } = useFormContext();

  const { payments = [] } = dot.object({ ...watch() });

  return (
    <Grid item xs={12}>
      <Button
        onClick={() => handleBackActiveStep(payments)}
        className={classes.button}
      >
        Voltar
      </Button>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        type="submit"
        disabled={!payments.length}
      >
        Avançar
      </Button>
    </Grid>
  );
};

export default (props) => {
  const {
    vehicleId, data, setData,
    classes, setActiveStep,
    saleValue, isLoading,
    resumeData, setResumeData,
  } = props;

  const { customer = {} } = data;

  const setTotalOfResume = (total) => {
    setResumeData({ ...resumeData, total });
  };

  const onSubmit = (formData) => {
    setData({ ...data, ...formData });
    setActiveStep(2);
  };

  const handleBackActiveStep = (payments) => {
    setData({ ...data, payments });
    setActiveStep(0);
  };

  return (
    <PaymentsForm
      defaultValues={data}
      onSubmit={onSubmit}
      vehicleId={vehicleId}
      isLoading={isLoading}
      saleValue={saleValue}
      getTotal={setTotalOfResume}
      customer={customer}
    >
      <Buttons
        classes={classes}
        handleBackActiveStep={handleBackActiveStep}
      />
    </PaymentsForm>
  );
};
